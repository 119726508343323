import React from "react";
import "./Targets.css";
import Spinner from "../spinner/Spinner";
import Target from "./Target";
import {loading} from "../FirestoreContainer";

const TargetsList = ({ targets, title = "Targets", isEditor, archive }) => {
    return (
        <section className="consoleView">
            {title && <h3>{title}</h3>}
            {loading(targets) ? (
                <Spinner />
            ) : (
                targets.data.map((target, i) => {
                    return (
                        <Target
                            key={i}
                            target={target}
                            isEditor={isEditor}
                            archive={archive ? () => archive(target._id) : null}
                        />
                    );
                })
            )}
        </section>
    );
};

export default TargetsList;
