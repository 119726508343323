import React, {Component} from "react";
import {first, groupBy} from "lodash";

import "./OrderDetail.css"
import "./OrderList.css"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import OrderSummaryCompact from "./OrderSummaryCompact";
import DisplayIf from "../displayif/DisplayIf";
import moment from 'moment'
import {loading} from "../FirestoreContainer";

class OrderList extends Component {

    constructor(props) {
        super(props);

        const queryParams = new URLSearchParams(props.location.search)

        this.state = {
            filter: {
                active: queryParams.get("active") === 'true' || false,
                recurring: queryParams.get("recurring") === 'true' || false,
                recentShipments: queryParams.get("recentShipments") === 'true' || true,
                failedHanging: queryParams.get("failedHanging") === 'true' || false,
                running: queryParams.get("running") === 'true' || false,
            }
        };
    }

    filterChange(e) {
        const filter = e.target.name;
        const checked = e.target.checked;
        this.setState({filter: {...this.state.filter, [filter]: checked}}, () => this.props.history.push({
            search: "?" + new URLSearchParams({
                active: this.state.filter.active.toString(),
                recurring: this.state.filter.recurring.toString(),
                recentShipments: this.state.filter.recentShipments.toString(),
                failedHanging: this.state.filter.failedHanging.toString(),
                running: this.state.filter.running.toString()
            }).toString()
        }))
    }

    render() {

        const orders = this.props.orders;
        const title = this.props.title || "Existing Order";

        const filterChange = this.filterChange.bind(this);

        let clientElms = [];
        const filteredOrders = orders
            .filter(o => !this.state.filter.active || o.active !== false)
            .filter(o => !this.state.filter.recurring || (o.schedule && o.schedule.cronExpression))
            .filter(o => !this.state.filter.recentShipments || hasRecentShipment(first(o.shipments)))
            .filter(o => !this.state.filter.failedHanging || isFailedOrHanging(first(o.shipments)))
            .filter(o => !this.state.filter.running || isRunning(first(o.shipments)));

        filteredOrders.sort((a, b) => {
            return a.clientName.localeCompare(b.clientName)
        });
        const ordersByClient = groupBy(filteredOrders, (o) => o.clientName);
        Object.keys(ordersByClient)
            .forEach((clientName) => {
                let obc = ordersByClient[clientName];
                let orderElems = (obc
                    .sort((a, b) => {
                        return a.name.localeCompare(b.name)
                    })
                    .map(o => <OrderSummaryCompact key={o.id} order={o}/>));
                clientElms.push(<div key={clientName}>
                    <h2 className="clientName">{clientName}</h2>
                    <ul className="orderList">{orderElems}</ul>
                </div>);
            });

        return (
            <section className="orders">
                <h1>{title}</h1>
                <DisplayIf condition={!loading(orders)}>
                    <fieldset>
                        <ul className="optionslist">
                            <li>
                                <label className="switch">
                                    <FontAwesomeIcon icon="redo-alt"/> Client integrations only
                                    <input id="filterActive" type="checkbox"
                                           name="recurring" onChange={filterChange}
                                           checked={this.state.filter.recurring}/>
                                    <span className="slider round"/>
                                </label>
                            </li>
                            <li>
                                <label className="switch">
                                    <FontAwesomeIcon icon="exclamation-triangle"/> Hanging or Failed orders only
                                    <input id="filterFailedHanging" type="checkbox"
                                           name="failedHanging" onChange={filterChange}
                                           checked={this.state.filter.failedHanging}/>
                                    <span className="slider round"/>
                                </label>
                            </li>
                            <li>
                                <label className="switch">
                                    <FontAwesomeIcon icon="calendar-alt"/> Orders with shipments last 7 days
                                    <input id="filterRecent" type="checkbox"
                                           name="recentShipments" onChange={filterChange}
                                           checked={this.state.filter.recentShipments}/>
                                    <span className="slider round"/>
                                </label>
                            </li>
                            <li>
                                <label className="switch">
                                    <FontAwesomeIcon icon="sync-alt"/> Running orders only
                                    <input id="filterRunning" type="checkbox"
                                           name="running" onChange={filterChange}
                                           checked={this.state.filter.running}/>
                                    <span className="slider round"/>
                                </label>
                            </li>
                        </ul>
                    </fieldset>
                    {clientElms.length < 1 &&
                        <div>No orders (check filters)</div>}
                    <div className="orderLists">{clientElms}</div>
                </DisplayIf>
            </section>
        );
    }
}

function hasRecentShipment(lastShipment) {
    const sevenDays = moment().subtract({days: 7});
    let started = undefined;
    if (lastShipment) {
        started = lastShipment.started;
    }
    return (lastShipment && moment(started).isAfter(sevenDays));
}

function isFailedOrHanging(lastShipment) {
    return lastShipment && (lastShipment.hanging || lastShipment.status === "failed")
}

function isRunning(lastShipment) {
    return lastShipment && lastShipment.status !== "aborted" && lastShipment.status !== "failed" && lastShipment.status !== "complete"
}

export default OrderList;
