import React from "react";
import Firestore, {loading} from "../components/FirestoreContainer";
import OrderDesign from "../components/orderdesign/OrderDesign";
import OrderDesignSubmit from "../components/orderdesign/OrderDesignSubmit";
import ShipmentSpec from "../components/shipments/ShipmentSpec";
import Spinner from "../components/spinner/Spinner";
import DocumentTitle from 'react-document-title';
import {first} from "lodash";
import request from "superagent";
import firebase from "firebase/app";
import {NavLink, useHistory, useLocation, useParams} from "react-router-dom";

const OrderDesignReviewPage = ({claims}) => {

    const {id: orderDesignId} = useParams();
    const history = useHistory()
    const location = useLocation()

    const setWorking = working =>
        history.push({
            ...location,
            state: {...location.state, working},
        });
    return (
        <Firestore key={orderDesignId}
                   queryMapFn={db => {
                       return {
                           orderdesigns: db
                               .collection("orderdesign")
                               .doc(orderDesignId),
                       };
                   }}
                   mutators={db => {
                       return {
                           deleteOrderDesign: () => {
                               setWorking(true);
                               db
                                   .collection("orderdesign")
                                   .doc(orderDesignId)
                                   .delete()
                                   .then(() => history.push("/"))
                                   .catch(err => {
                                       console.log(err);
                                       setWorking(false);
                                   });
                           },
                       };
                   }}
        >
            <OrderWithLoader
                onSubmitToShipit={jobSpec => e => {
                    console.log("submitting");
                    setWorking(true);
                    e.preventDefault();
                    submitToShipit(orderDesignId, jobSpec)
                        .then(orderId => {
                            console.log("submitted");
                            history.push(`/orders/${orderId}`);
                        })
                        .catch(err => {
                            console.log("submit failed", err, err.response);
                            setWorking(false);
                        });
                }}
                isWorking={location.state ? location.state.working : false}
                isAuthorized={claims.admin}
                goToOrderView={orderId => history.push(`/orders/${orderId}`)}
            />
        </Firestore>
    );
};

const OrderWithLoader = ({
                             orderdesigns,
                             onSubmitToShipit,
                             deleteOrderDesign,
                             isWorking,
                             isAuthorized,
                         }) => {
    if (loading(orderdesigns)) {
        return <Spinner/>;
    }
    const orderdesign = first(orderdesigns.data);

    if (!orderdesign) {
        return <div>Orderdesign not found</div>;
    }

    if (orderdesign.created) {
        return (
            <div>
                <p>
                    This Orderdesign has been submitted and is no longer
                    relevant for review.
                </p>
                <NavLink to={`/orderdesigns/${orderdesign.orderRef.id}`}>
                    Go To Order
                </NavLink>
            </div>
        );
    }

    return (<DocumentTitle title={`Review - ${orderdesign.name}`}>
        <div key={orderdesign._id}>
            <h2>Order Design Review</h2>
            <OrderDesign orderdesign={orderdesign}/>
            <ShipmentSpec spec={orderdesign.jobSpec}/>
            {!orderdesign.created && (
                <OrderDesignSubmit
                    onSubmitToShipit={onSubmitToShipit(orderdesign.jobSpec)}
                    onDiscard={deleteOrderDesign}
                    working={isWorking}
                    submittable={isAuthorized && !!orderdesign.jobSpec}
                />
            )}
        </div>
    </DocumentTitle>);
};

const submitToShipit = (orderDesignId, jobSpec) =>
    firebase
        .auth()
        .currentUser.getIdToken()
        .then(token => {
            return request
                .post(
                    `${
                        process.env.REACT_APP_FIREBASE_FUNCTIONS_URL
                    }/orderDesignSubmitter/orderdesigns/${orderDesignId}/_submit`
                )
                .send({jobSpec})
                .timeout({response: 30000})
                .set("Authorization", "Bearer " + token)
                .then(res => {
                    console.log(res);
                    return res.body.orderId;
                });
        })
        .catch(err => {
            if (err.response) {
                return Promise.reject(err.response.body);
            }
            return Promise.reject(err);
        });

export default OrderDesignReviewPage;
