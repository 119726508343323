import React, {Component} from "react";
import "./Dataset.css";
import "./DatasetList.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {NavLink} from "react-router-dom";

class DatasetList extends Component {
    constructor(props) {
        super(props);
        let products = props.datasets.map(dataset => dataset.product);
        products = products
            .filter((item, pos) => {
                return products.indexOf(item) === pos;
            })
            .sort();
        products.push("All products");

        this.state = {
            products: products,
            showArchived: false,
            product: "All products",
        }
    }


    updateProductFilter(event) {
        let product = event.target.value
        this.setState({
            product: product,
        });
    }

    updateShowArchivedFilter() {
        this.setState({
            showArchived: !this.state.showArchived
        });
    }

    shouldBeHidden(dataset) {
        if (!this.state.showArchived && dataset.state === "archived") return true;
        return this.state.product !== "All products" && (this.state.product !== dataset.product);
    }

    render() {
        const {datasets} = this.props;

        return (
            <div>
                <div>
                    <div>
                        <label className="switch">
                            <FontAwesomeIcon icon="trash-alt"/> Show Archived
                            <input id="showArchived" type="checkbox"
                                   name="showArchived" onChange={this.updateShowArchivedFilter.bind(this)}
                                   checked={this.state.showArchived}/>
                            <span className="slider round"/>
                        </label>
                    </div>
                    <div>
                        <select id={"product"}
                                name={"product"}
                                value={this.state.product}
                                onChange={product => (this.updateProductFilter(product))}>
                            {this.state.products.map(p => {
                                return <option key={p} value={p}>{p}</option>
                            })}
                        </select>
                    </div>
                </div>
                <div className="consoleActions datasetList">
                    {datasets.map((dataset, i) => {
                        return this.shouldBeHidden(dataset) ? null : <NavLink
                            key={i}
                            target="_blank"
                            className={[
                                "panel",
                                (dataset.state === "archived" ? "archived" : "")
                            ].join(" ").trim()}
                            to={`/datasets/${dataset._id}`}
                        >
                            <h4>{dataset.name}</h4>
                            {dataset.product &&
                            <div className="datasetListDescription">Product: <b>{dataset.product}</b></div>}
                            {dataset.state === "archived" && <div className="archivedDetail">Archived</div>}
                        </NavLink>
                    })}
                </div>
            </div>
        );
    }
}

export default DatasetList;
