import React from "react";
import FirestoreContainer from "../FirestoreContainer";
import CreateDataset from "./CreateDataset";
import {useHistory} from "react-router-dom";

const CreateDatasetPage = () => {
    const history = useHistory()

    return (
        <FirestoreContainer

            queryMapFn={() => {
                return {};
            }}
            mutators={db => {
                return {
                    addDataset: dataset => {
                        db
                            .collection("datasets")
                            .add(dataset)
                            .then(() => {
                                history.push(`/datasets`);
                            })
                            .catch(err => {
                                console.log(err);
                                history.push(`/`);
                            });
                    },
                };
            }}
        >
            <CreateDatasetPageLayout/>
        </FirestoreContainer>
    );
};

const CreateDatasetPageLayout = ({addDataset}) => {
    return (
        <section>
            <h1>Create Dataset</h1>
            <p>Views defines how data is extracted for an order.</p>
            <CreateDataset addDataset={addDataset}/>
        </section>
    );
};

export default CreateDatasetPage;

