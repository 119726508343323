import React, {Component} from "react";

import { format } from 'sql-formatter';
import "./View.css";
import DocumentTitle from 'react-document-title';
import Spinner from "../spinner/Spinner";
import DisplayIf from "../displayif/DisplayIf";
import {NavLink} from "react-router-dom";

import firebase from "firebase/app";
import "firebase/firestore";

function fetchClient(client, that) {
    if (!!client) {
        return client.get()
            .then(fetchedClient => {
                that.setState({client: fetchedClient.data()})
            })
    }
}

function fetchDataset(dataset, that) {
    return dataset.get()
        .then(fetched => {
            that.setState({dataset: fetched.data()})
        })
}
class View extends Component {
    constructor(props) {
        super(props);
        const {view} = props
        this.db = firebase.firestore();
        this.state = {
            loading: true,
            client: !!view.client ? "" : {name: "All"},
        };
    }

    componentDidMount() {
        const {view} = this.props
        Promise.all(
            [
                fetchClient(view.client, this),
                fetchDataset(view.dataset, this),
            ]
        ).then(() => this.setState({loading: false}))
            .catch(() => this.setState({loading: false}))
    }

    render() {
        
        const {view} = this.props;

        if (this.state.loading) {
            return <Spinner/>
        }

        let query = view.query;

        return (<DocumentTitle title={`View - ${view.name}`}>
            <div className="view">
                {view.archived && <p>This view has been archived</p>}
                <dl>
                    <dt>Name</dt>
                    <dd>{view.name}</dd>
                    {/*<dt>Kind</dt>*/}
                    {/*<dd>{view.kind}</dd>*/}
                    <dt>Source</dt>
                    <dd>{view.extractFrom}</dd>
                    <dt>Used for client</dt>
                    <dd>{view.client ? <NavLink
                        to={`/clients/${view.client.id}`}>{this.state.client.name}</NavLink> : this.state.client.name} </dd>
                    <dt>Description</dt>
                    <dd>{view.description}</dd>

                    <dt>Create Device Report</dt>
                    <dd>{(view.disableReports !== true && view.createDeviceReport !== false) ? "Yes" : "No"}<br/>
                        <DisplayIf condition={view.disableReports !== true && view.createDeviceReport}>
                            <dl>
                                <dt>Identifier column</dt>
                                <dd>{view.identifierColumn}</dd>
                                <dt>Event time column</dt>
                                <dd>{view.eventTimeColumn}</dd>
                                <dt>Event time format</dt>
                                <dd>{view.eventTimeColumnFormat}</dd>
                                <dt>Ip Address column</dt>
                                <dd>{view.ipAddressColumn}</dd>
                                <dt>Accuracy column</dt>
                                <dd>{view.accuracyColumn}</dd>
                                <dt>Latitude column</dt>
                                <dd>{view.latitudeColumn}</dd>
                                <dt>Country column</dt>
                                <dd>{view.countryColumn}</dd>
                                <dt>Country short</dt>
                                <dd>{view.countryShort}</dd>
                            </dl>
                        </DisplayIf>
                    </dd>
                </dl>
                <DisplayIf condition={!!this.state.dataset.baseQuery || !!this.state.dataset.progressQuery}>
                    <h5>Dataset ({view.dataset ? <NavLink
                        to={`/datasets/${view.dataset.id}`}>{this.state.dataset.name}</NavLink> : this.state.dataset.name})
                        base query</h5>
                    <DisplayIf condition={this.state.dataset.baseQuery !== ""}>
                        <code className="sql">
                            {this.state.dataset.baseQuery && format(this.state.dataset.baseQuery)}
                        </code>
                    </DisplayIf>
                </DisplayIf>
                <h5>Query Template</h5>

                <code className="sql">
                    {query && format(query)}
                </code>
            </div>
        </DocumentTitle>);
    }
}

export default View;
