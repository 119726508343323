import { auth } from "./firebase";
import { pick } from "lodash";
import base64 from "base-64";
import utf8 from "utf8";

export const getClaims = (forceRefresh) => {
    return auth()
        .currentUser.getIdToken(forceRefresh)
        .then(idToken => {
            const bytes = base64.decode(idToken.split(".")[1]);
            const text = utf8.decode(bytes);
            const payload = JSON.parse(text);
            return pick(payload, ["admin", "unacastemployee"]);
        });
};
