import React, {Component} from "react";
import "./QueryWizard.css";
import firebase from "firebase/app";
import {DropdownList} from "react-widgets";
import DisplayIf from "../../components/displayif/DisplayIf";

class ManageQueryWizard extends Component {
    constructor(props) {
        super();

        let bqTables = props.bqTables.map(table => {
            if (!table.defaultFields) {
                let fields = table.defaultFields ? table.defaultFields : [];
                Object.keys(table.schema).map(key => {
                    let field = {"name": table.schema[key].name, "checked": true};
                    fields.push(field);
                    return fields;
                });
                table.defaultFields = fields;
            }
            table.visible = false;
            return table;
        });

        this.state = {
            bqTables: bqTables
        };
    }

    onChange(name, value) {
        this.setState({
            [name]: value,
        });
    }

    onFieldChange(index, i, value) {
        this.setState(state => {
            const bqTables = state.bqTables.map((table, j) => {
                    if (j === index) {
                        table.defaultFields[i].checked = value;
                    }
                    return table;
                }
            );

            return {
                bqTables,
            };
        });
    }

    setDefaultFields() {
        let table = this.state.bqTables.filter(table => table._id === this.state.chosenTable);
        firebase.firestore().collection("bq_table").doc(this.state.chosenTable).update("defaultFields", table[0].defaultFields).then(function () {
            alert("Default fields has been saved!");
        });
    }

    render() {
        const {bqTables} = this.state;
        const dropdownTableList = bqTables.map(table => table._id);
        let setDefaultFields = this.setDefaultFields.bind(this);

        return (
            <div className="queryWizard">
                <h2>Manage Query Wizard</h2>
                <span>
                    <label htmlFor="bqTable-input">Choose Big Query Table:</label>
                    <DropdownList
                        id="bqTable"
                        data={dropdownTableList}
                        placeholder={"Choose table"}
                        onChange={value => this.onChange("chosenTable", value)}
                        value={this.state.chosenTable}
                        required={false}
                    />
                </span>
                {bqTables.map((bqTable, index) => {
                    return bqTable._id === this.state.chosenTable &&
                        <div key={bqTable._id}>
                            <h4>Default Fields: </h4>
                            {bqTable.defaultFields.map((field, i) =>
                                <div key={field.name}>
                                    <span className="field">
                                        <fieldset className="subFields">
                                            <h5 className={field.checked ? "" : "unchecked"}><label
                                                className="dataFieldCheckbox" htmlFor={`checked-${field.name}`}>{field.name}: </label>
                                            <input className="dataFieldCheckbox" type="checkbox" name="checked" id={`checked-${field.name}`}
                                                   onChange={e => this.onFieldChange(index, i, e.target.checked)}
                                                   checked={field.checked}/></h5>
                                        </fieldset>
                                    </span>

                                </div>)}
                        </div>;
                })}
                <DisplayIf condition={this.state.chosenTable}>
                    <div className="buttons">
                        <input
                            className="callToAction"
                            type="submit"
                            value={"Save default fields"}
                            onClick={() => setDefaultFields()}
                        />
                    </div>
                </DisplayIf>
            </div>
        );
    }
}

export default ManageQueryWizard;
