import React, {Component} from 'react';

class FilenameFormPartial extends Component {
    render() {
        const {newOrder, onChange} = this.props
        return (<span>
            <span className="field">
                <label htmlFor="filename">Filename</label>
                <span>
                    <input readOnly={true} disabled={true} value={newOrder.filename}/>
                    <span className={'fieldComment'}/>
                </span>
            </span>
            <span className="field">
                <label htmlFor="filenameBase">Base filename</label>
                <div>
                    <span>
                        <input
                            type="text"
                            name="filenameBase"
                            placeholder="pure"
                            value={newOrder.filenameBase}
                            onChange={onChange}
                            required={true}
                        />
                    </span>
                    <span className="fieldComment">
                        You can insert datetime values in the filename by using variables on the form
                        <code>&#123;&#123;timefield}}</code> where timefield can be any of year, month, day, hour,
                        minute or second.<br/>
                        Ex: <code>dailyfile_&#123;&#123;year}}&#123;&#123;month}}&#123;&#123;day}}</code><br/>
                        We also support <code>&#123;&#123;month_literal}}</code> to get a e.g. <code>january</code>
                         and <code>&#123;&#123;prev_month_literal}}</code> to get the previous month
                    </span>
                </div>

            </span>
            </span>)
    }

}

export default FilenameFormPartial;
