import React from "react";
import Firestore, {loading} from "../components/FirestoreContainer";
import Targets from "../components/targets/Targets";
import { first } from "lodash";
import Spinner from "../components/spinner/Spinner";
import { NavLink } from "react-router-dom";

const TargetsPage = ({ match }) => {
    const { clientId } = match.params;
    return (
        <Firestore key={clientId}
            queryMapFn={db => {
                const clientRef = db.collection("clients").doc(clientId);
                return {
                    credentials: db.collection("credentials").doc("unacast-delivery"),
                    clients: clientRef,
                    targets: db
                        .collection("targets")
                        .where("clientId", "==", clientRef)
                        .where("archived","==",false),
                };
            }}
            mutators={db => {
                return {
                    addTarget: target => db.collection("targets").add(target)
                        .then(() => {
                            alert("Target was created!");
                            window.location.reload();
                        }),
                };
            }}
        >
            <TargetCreator />
        </Firestore>
    );
};

const TargetCreator = ({ credentials, clients, targets, addTarget }) => {
    return (
        <section>
            {loading(clients) ? (
                <Spinner />
            ) : (
                <div>
                    <h3>
                        Targets for{" "}
                        <NavLink to={`/clients/${first(clients.data)._id}`}>
                            {first(clients.data).name}
                        </NavLink>
                    </h3>
                    <p>
                        A Target describes an integration between Unacast and a
                        Client. The Target definition describes allowed
                        configurations for delivery schedule and content-types.
                    </p>
                    <hr />
                    <Targets
                        client={first(clients.data)}
                        credentials={first(credentials.data)}
                        targets={targets}
                        addTarget={target => {
                            console.log(target);
                            addTarget(target);
                        }}
                    />
                </div>
            )}
        </section>
    );
};

export default TargetsPage;
