import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';
import DisplayIf from "../displayif/DisplayIf";
import cronstrue from 'cronstrue';
import urlJoin from 'proper-url-join';
import {curlyDateFmt} from "./order-methods";

class OrderDesignSummary extends Component {
    storagePath() {
        const order = this.props.order;
        const target = order.target;
        let prefix = ""
        let firstPart = ""
        switch (target.targetType) {
            case "S3":
                prefix = "s3:/";
                firstPart = target.bucket;
                break;
            case "SFTP":
                prefix = "sftp:/";
                firstPart = target.host + (target.port ? ":" + target.port : "");
                break;
            case "Azure":
                prefix = "https:/";
                firstPart = `${target.containerName}.blob.core.windows.net`;
                break;
            case "GCS":
                prefix = "gs:/";
                firstPart = `${target.gcsBucket}`;
                break;
            default:
                break;
        }
        return prefix + urlJoin(firstPart, target.folder, curlyDateFmt(order.folderPath, false, new Date()), {trailingSlash: true});
    }

    successFileFormat() {
        const order = this.props.order;
        return `${order.successfileName}${order.successfilePostfix ? "-" + curlyDateFmt("{{year}}_{{month}}_{{day}}_{{hour}}{{minute}}{{second}}", false, new Date()) : ""}`;
    }

    filename() {
        const filename = this.props.order.filename;
        return curlyDateFmt(filename, false, new Date())
    }

    render() {
        const order = this.props.order;
        const target = order.target;
        if (!target || !order.view) {
            return null;
        }
        const storagePath = this.storagePath();
        const successFileFormat = this.successFileFormat();

        const filename = this.filename();
        let filePlacement = storagePath + filename;
        let wildcardMatch = filename.match("(.*)[*](.*)");
        if (wildcardMatch) {
            filePlacement = (<span>
            {`${storagePath}${wildcardMatch[1]}00000${wildcardMatch[2]}`}<br/>
                {`${storagePath}${wildcardMatch[1]}00001${wildcardMatch[2]}`}<br/>
                {`${storagePath}${wildcardMatch[1]}00002${wildcardMatch[2]}`}<br/>
            ...
            </span>);
        }
        return <div className="panel">
            <h3>Order Summary</h3>
            <p>
                Transfer data described in <NavLink target="_blank"
                                                    to={`/views/${order.view._id}`}>{order.view.name}</NavLink><br/>
                to <NavLink target="_blank" to={`/target/${order.target._id}`}> {order.target.name}</NavLink> <br/>
                {order.schedule && cronstrue.toString(order.schedule, {throwExceptionOnParseError: false})}
            </p>
            <h4>Files transferred</h4>
            <DisplayIf condition={!order.filename}>Please set a filename.</DisplayIf>
            <dl>
                <DisplayIf condition={!!order.filename}>
                    <dt>File placement</dt>
                    <dd>{filePlacement}</dd>
                </DisplayIf>
                <DisplayIf condition={order.createSuccessFile}>
                    <dt>Success file placement</dt>
                    <dd>{storagePath + successFileFormat}</dd>
                </DisplayIf>
            </dl>
        </div>
    }

}

export default OrderDesignSummary;



