import React from "react";
import FirestoreContainer, {loading} from "../components/FirestoreContainer";
import CreateView from "../components/views/CreateView";
import Spinner from "../components/spinner/Spinner";
import {first} from 'lodash'
import {useHistory, useLocation} from "react-router-dom";

const qs = require('querystring');

const CreateViewPage = () => {
    const history = useHistory()
    const location = useLocation()

    const cloneViewId = qs.parse(location.search)["?cloneView"];

    return (
        <FirestoreContainer key={cloneViewId}
            queryMapFn={db => {
                const queries = {
                    datasets: db.collection("datasets"),
                    clients: db.collection("clients"),
                };
                if(!!cloneViewId){
                    queries.viewToClone = db.collection("views").doc(cloneViewId)
                }
                return queries;
            }}
            mutators={db => {
                return {
                    addView: view => {
                        db
                            .collection("views")
                            .add(view)
                            .then(doc => {
                                history.push(`/views/${doc.id}`);
                            })
                            .catch(err => {
                                console.log(err);
                                history.push(`/`);
                            });
                    },
                };
            }}
        >
            <CreateViewPageLayout />
        </FirestoreContainer>
    );
};

const CreateViewPageLayout = ({ datasets, clients, addView, history, viewToClone }) => {
    if (loading(datasets) || loading(clients) || (!!viewToClone && loading(viewToClone)))
    return (
        <div className="createView">
            <Spinner/>
        </div>
    );
    return (
        <section>
            <h1>Create View</h1>
            <p>Views defines how data is extracted for an order.</p>
            <CreateView datasets={datasets} addView={addView} clients={clients} viewToClone={!!viewToClone && first(viewToClone.data)} history={history} />
        </section>
    );
};

export default CreateViewPage;
