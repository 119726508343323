import React, {Component} from "react";

import {DropdownList} from "react-widgets";
import Spinner from "../spinner/Spinner";
import "./CreateDataset.css";
import QueryValidator from "../views/QueryValidator";
import Dropdown from "../orderdesign/partials/Dropdown";
import DisplayIf from "../displayif/DisplayIf";
import {getSubscriberPermissions} from "./dataset-methods";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {isEqual} from "lodash";

const filtersRegex = /([a-zA-Z0-9]{2,})=([a-zA-Z0-9,]{2,})[ ]?/g;

class CreateDataset extends Component {
    constructor(props) {
        super(props);
        this.state = {
            working: false,
            valid: false,
            displayInitialMaxLoadTimestamp: false,
            pubSubProjectID: "uc-data-lift",
            pubSubTopicID: "",
            pubSubFilters: "",
            topicValidationRunning: false,
            topicValidated: false,
            validatedTopic: undefined,
            newDataset: {
                name: "",
                baseQuery: "",
                state: "active",
                progressQuery: "",
                progressType: "timestamp",
                product: "",
                initialMaxLoadTimestamp: "1", //In number of days before current date (used for the first shipment of a new order)
                triggerType: "schedule",
                pubSubTopic: {},
                lastUpdatedOn: undefined,
            }
        }
    }

    componentDidMount() {
        if (this.props.editMode && this.props.dataset) {
            this.setState({
                pubSubFilters: this.props.dataset.pubSubTopic ? objectToKeyValue(this.props.dataset.pubSubTopic.filters) : "",
                pubSubTopicID: this.props.dataset.pubSubTopic ? this.props.dataset.pubSubTopic.topic.split("/").slice(-1)[0] : "",
                newDataset: {...this.props.dataset}
            })
        }
    }

    onFormChange(name, value) {
        let changes = {pubSubTopic: this.state.newDataset.pubSubTopic ? this.state.newDataset.pubSubTopic : {}};
        if (name === "pubSubProjectID") {
            changes["pubSubTopic"].topic = "projects/" + value + "/topics/" + this.state.pubSubTopicID;
            this.setState({pubSubProjectID: value, topicValidationError: null})
        } else if (name === "pubSubTopicID") {
            changes["pubSubTopic"].topic = "projects/" + this.state.pubSubProjectID + "/topics/" + value;
            this.setState({pubSubTopicID: value, topicValidationError: null})
        } else if (name === "pubSubFilters") {
            changes["pubSubTopic"].filters = keyValueToObject(value)
            this.setState({pubSubFilters: value, topicValidationError: null})
        } else {
            changes[name] = value;
        }
        
        //set to empty if changing from pubsub type to schedule
        if (name === "triggerType" && value === "schedule")
            changes["pubSubTopic"] = {};
    
        this.onFormChanges(changes)
    }

    onFormChanges(changes) {
        this.setState({
            valid: this.state.valid && !Object.keys(changes).includes("baseQuery"),
            newDataset: {
                ...this.state.newDataset,
                ...changes,
            },
        });
    }

    currentTopicValidated() {
        const validatedTopic = this.state.validatedTopic;

        return this.state.newDataset.pubSubTopic?.topic && isEqual(this.state.newDataset.pubSubTopic?.topic, validatedTopic);
    }

    validateTopic() {
        const topicName = this.state.newDataset.pubSubTopic?.topic;
        const topicPayload = {
            ID: topicName,
        };
        this.setState({topicValidationRunning: true});

        getSubscriberPermissions(topicPayload).then(res => {
            let hasPermission = res.text.includes("pubsub.topics.attachSubscription");

            this.setState({
                topicValidated: hasPermission,
                topicValidationRunning: false,
                topicValidationError: hasPermission ? null : "Data Lift doesn't have the needed permission for this topic/project",
                validatedTopic: topicName
            })
        }).catch(() => {
            this.setState({
                topicValidated: false,
                topicValidationRunning: false,
                topicValidationError: "Something went wrong, make sure the topic actually exist",
            });
        })
    }

    validateBeforeSubmitting() {
        const newDataset = this.state.newDataset;
        if (!newDataset.product) {
            alert("Please choose a product before saving");
            return false;
        }
        if (!newDataset.baseQuery) {
            alert("Please write a baseQuery before saving");
            return false;
        }
        if (newDataset.triggerType === "pubsub") {
            let re = /projects\/[A-Za-z0-9\-_]+\/topics\/[A-Za-z0-9\-_]+/
            if (!re.test(newDataset.pubSubTopic.topic)) {
                alert("The PubSubTopic does not have a proper format:" + newDataset.pubSubTopic?.topic);
                return false;
            }
            if (!this.state.topicValidated) {
                alert("The PubSubTopic hasn't been validated, please validate it first");
                return false;
            }

        }
        if (!newDataset.initialMaxLoadTimestamp.match(/^[1-9]$|^[1-9]*[0-9][0]*?$/g)) {
            alert(`Please specify an initialMaxLoadTimestamp between 1 and 100`)
            return false;
        }
        return true;
    }

    query() {
        let query = this.state.newDataset.baseQuery;
        if (this.state.newDataset.progressQuery) {
            query = query + "\n" + this.state.newDataset.progressQuery;
        }
        return query
    }

    onSubmit(e) {
        e.preventDefault();

        if (!this.validateBeforeSubmitting()) {
            return;
        }

        this.setState({
            working: true,
        });

        let toSave = {...this.state.newDataset, lastUpdatedOn: new Date()};

        // Make sure empty pubSubTopic object gets converted to null
        if (Object.keys(toSave.pubSubTopic).length === 0) {
            toSave.pubSubTopic = null;
        }

        if (this.props.editMode)
            this.props.updateDataset(toSave)
        else
            this.props.addDataset(toSave);
    }

    baseQuery() {
        let query = this.state.newDataset.baseQuery;
        let re = /with[^a-z]+([a-zA-Z0-9_]+) as/;
        let queryName = query.toLowerCase().match(re, "$1");
        if (queryName && query.trim().endsWith(")")) {
            return query + " SELECT * from " + queryName[1];
        }
        return query;
    }


    render() {
        const {newDataset, working} = this.state;
        const {editMode} = this.props;
        const onChange = e => this.onFormChange(e.target.name, e.target.value);

        if (working) {
            return <div className="createDataset"><Spinner/></div>;
        }

        return (
            <div className="createDataset">
                <form onSubmit={e => this.onSubmit(e)}>

                    <span className="field">
                        <label htmlFor={"name"}>Name</label>
                        <div>
                            <input
                                name="name"
                                onChange={onChange}
                                value={newDataset.name}
                                required={true}
                                disabled={editMode}
                            />
                        </div>
                    </span>
                    <span className="field">
                        <label htmlFor={"product"}>Product</label>
                        <div>
                               <DropdownList
                                   name="product"
                                   data={["pure", "visits", "activities", "home_work", "optout", "uncontextualized_clusters", "none"]}
                                   value={newDataset.product}
                                   inputProps={{required: true}}
                                   onChange={product =>
                                       this.onFormChange("product", product)
                                   }
                               />
                        </div>
                    </span>

                    <span className="field">
                        <label>BaseQuery</label>
                        <div>
                            <textarea
                                cols={60}
                                rows={10}
                                name={"baseQuery"}
                                placeholder={"Bigquery Standard-SQL"}
                                onChange={onChange}
                                value={newDataset.baseQuery}
                                required={true}
                                className="sql"
                            />
                            <p>
                                BigQuery Standard-SQL Query to be executed upon
                                order execution. To use Legacy-SQL instead, add <strong>#legacySQL</strong> at the start.
                            </p>
                        </div>
                    </span>

                    <QueryValidator
                        query={this.baseQuery.bind(this)()}
                        onSuccess={() => {
                            this.setState({
                                valid: true,
                            });
                        }}
                        key="baseQueryValidator"/>

                    <span className="field">
                        <label htmlFor="progressType">ProgressType</label>
                        <div>
                            <DropdownList
                                name="progressType"
                                data={[{text: "table [DEPRECATED]", value: "table"},
                                    {text: "timestamp", value: "timestamp"},
                                    {text: "none", value: "none"}]}
                                value={newDataset.progressType}
                                textField='text'
                                valueField='value'
                                inputProps={{required: true}}
                                onChange={progressType =>
                                    this.onFormChange("progressType", progressType.value)
                                }
                            />
                        </div>
                    </span>

                    <span className="field">
                        <label>ProgressQuery</label>
                        <div>
                            <textarea
                                cols={60}
                                rows={10}
                                name={"progressQuery"}
                                placeholder={"Bigquery Standard-SQL"}
                                onChange={onChange}
                                value={newDataset.progressQuery}
                                required={true}
                                className="sql"
                            />
                            <p>
                                BigQuery Standard-SQL Query to be executed upon
                                order execution. To use Legacy-SQL instead, add <strong>#legacySQL</strong> at the start.
                            </p>
                        </div>
                    </span>

                    <QueryValidator
                        query={this.state.newDataset.progressQuery}
                        onSuccess={() => {
                            this.setState({
                                valid: true,
                            });
                        }}
                        progressType={this.state.newDataset.progressType}
                        key="progressQueryValidator"/>

                    {this.state.newDataset.progressType === "timestamp" && <span className="field">
                        <label htmlFor="maxloadtimestamp">Initial MaxLoadTimestamp: </label>
                             <div>
                                 <input
                                     id="initialMaxLoadTimestamp"
                                     name="initialMaxLoadTimestamp"
                                     onChange={onChange}
                                     value={newDataset.initialMaxLoadTimestamp}
                                     required={true}/>
                                 <span className="fieldComment">Choose how many days back in time the initial timestamp should be.</span>
                                 <span className="fieldComment">The default is 1 day, <strong>and please be careful to not set this too high!</strong></span>
                                 <table className={"table table-condensed"}>
                                     <caption>Min. days back in time for some products</caption>
                                     <thead>
                                         <tr>
                                             <th>Product</th>
                                             <th>Days</th>
                                         </tr>
                                     </thead>
                                     <tbody>
                                         <tr>
                                             <td>Pure</td>
                                             <td>1</td>
                                         </tr>
                                         <tr>
                                             <td>Migration pattern Populatin trend</td>
                                             <td>8</td>
                                         </tr>
                                         <tr>
                                             <td>Migration pattern OD flux</td>
                                             <td>15</td>
                                         </tr>
                                         <tr>
                                             <td>Other Puzzle products</td>
                                             <td>5</td>
                                         </tr>
                                     </tbody>
                                 </table>
                             </div>
                    </span>}

                    <span className="field">
                        <label htmlFor="triggerType">TriggerType</label>
                        <div>
                        <Dropdown
                            name="triggerType"
                            data={["schedule", "pubsub"]}
                            textField="name" valueField="value"
                            onChange={triggerType =>
                                this.onFormChange("triggerType", triggerType)}
                            value={newDataset.triggerType}
                            placeholder="Choose triggerType"
                        />
                        </div>
                     </span>
                    <DisplayIf condition={!!newDataset.triggerType && newDataset.triggerType === "pubsub"}>
                         <span className="field">
                            <label htmlFor="pubSubTopic">Pubsub topic</label>
                            <div>
                                <label htmlFor="pubSubProjectID">Project</label>
                                <div>
                                    <input name="pubSubProjectID" id="pubSubProjectID" onChange={onChange}
                                           placeholder={this.state.pubSubProjectID}
                                           value={this.state.pubSubProjectID}
                                    />
                                </div>
                                <label htmlFor="pubSubProjectID">Topic</label>
                                <div>
                                    <input name="pubSubTopicID" id="pubSubTopicID" onChange={onChange}
                                           placeholder={"topicID"}
                                           value={this.state.pubSubTopicID}
                                    />
                                </div>
                                 <span
                                     className="fieldComment">Set topic to see full name: {newDataset?.pubSubTopic?.topic}
                                 </span>
                                <label htmlFor="pubSubFilters">Filters</label>
                                <div>
                                    <input name="pubSubFilters" id="pubSubFilters" onChange={onChange}
                                           placeholder={"key=value key2=value2"}
                                           value={this.state.pubSubFilters}
                                    />
                                </div>
                                <span className="fieldComment">
                                    Set filters on the format <code>key=value[,value2] key2=value3</code>.<br/>
                                        The resulting filter object is: {
                                    this.state.newDataset.pubSubTopic && this.state.newDataset.pubSubTopic?.filters
                                        ? JSON.stringify(this.state.newDataset.pubSubTopic?.filters) : ""}
                                 </span>
                            </div>
                     </span>
                        <span className="buttons">
                                        <button type="button"
                                                onClick={this.validateTopic.bind(this)}>Validate topic</button>
                                        <div>
                                            <ValidationStatus currentTopicValidated={this.currentTopicValidated()}
                                                              valid={this.state.topicValidated}
                                                              errors={this.state.topicValidationError}
                                                              validationRunning={this.state.topicValidationRunning}
                                            />
                                        </div>
                                    </span>
                    </DisplayIf>


                    <div className="buttons">
                        <input
                            className="callToAction"
                            type="submit"
                            value={editMode ? "Update" : "Create"}
                            disabled={editMode ? !this.state.valid ||
                                isEqual(this.state.newDataset, this.props.dataset) : !this.state.valid}
                        />
                        {editMode &&
                            <button
                                className="callToAction"
                                onClick={() => {
                                    this.props.setEditMode(false);
                                }}>
                                Cancel
                            </button>
                        }
                    </div>
                </form>
            </div>
        );
    }
}

const ValidationStatus = ({currentTopicValidated, valid, errors, validationRunning}) => {
    if (validationRunning) {
        return <div className="panel"><FontAwesomeIcon icon="spinner" pulse={true}/><p>Validating topic...</p></div>;
    }
    if (!currentTopicValidated && !errors) {
        return <div className="panel"><FontAwesomeIcon icon="hand-point-left"/> Check if this topic has the necessary
            permissions.</div>;
    }
    return (valid ?
            <div className="panel"><FontAwesomeIcon icon="thumbs-up"/> <p>This topic is valid!</p>
            </div> :
            <div className="panel error"><FontAwesomeIcon icon="thumbs-down"/> <p>This topic can't be used
                because: {errors}</p></div>
    )
};

const keyValueToObject = (value) => {
    return [...value.matchAll(filtersRegex)].reduce((acc, curr) => {
        if (curr[2].includes(",")) {
            acc[curr[1]] = curr[2].split(",")
        } else {
            acc[curr[1]] = curr[2]
        }
        return acc
    }, {})
}

const objectToKeyValue = (obj) => {
    return obj ? Object.entries(obj).map(([k, v]) => {
        if (Array.isArray(v)) {
            return `${k}=${v.join(",")}`
        }
        return `${k}=${v}`
    }).join(" ") : ""
}

export default CreateDataset;
