export const chartOptions = {
    maintainAspectRatio: false,
    title: {
        text: 'Last shipments'
    },
    scales: {
        xAxes: [{
            id: 'daily',
            type: 'time',
            time: {
                unit: 'day'
            },
            gridLines: {
                offsetGridLines: true,
            },
        }, {
            id: 'hourly',
            type: 'time',
            time: {
                unit: 'hour'
            },
            ticks: {
                display: false
            },
            gridLines: {
                drawTicks: false,
                offsetGridLines: false,
            },
        }],
        yAxes: [{
            id: 'left',
            type: 'linear',
            scaleLabel: {
                display: true,
                labelString: 'Million DAU',
            },
            ticks: {
                min: 0,
                beginAtZero: true
            }
        },
            {
                id: 'right',
                type: 'linear',
                position: 'right',
                scaleLabel: {
                    display: true,
                    labelString: 'Million Bump/Visits',
                },
                ticks: {
                    min: 0,
                    beginAtZero: true
                }
            }]
    },
};

export const generateShipmentChartData = (shipmentData) => {
    const rows = shipmentData.map(d => {
        return {"x": d.date, "y": d.rows / 1000000}
    });
    if (rows.length > 1) {

        const totalRows = shipmentData.reduce(function (a, b, index) {
            if (index === 1) return parseInt(a.rows) + parseInt(b.rows);
            return a + parseInt(b.rows);
        });
        const averageRows = totalRows / shipmentData.length;

        return {
            rows: {
                total: totalRows,
                average: averageRows
            },
        }
    }
    return {}
};
