import React, {Component} from 'react';
import './OrderDetails.css'
import {first} from "lodash";

import {NavLink} from 'react-router-dom';
import Spinner from "../spinner/Spinner";
import Schedule from "./Schedule";
import DisplayIf from "../displayif/DisplayIf";
import Collapsible from "../collapsible/Collapsible";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Panel from "../panel/Panel";
import {isDone} from "../orders/order-methods";
import RefreshOrderButton from "../orders/RefreshOrderButton";
import OrderActivationButton from "../orders/OrderActivationButton";
import ReplaceOrderButton from "../orders/ReplaceOrderButton";
import {loading} from "../FirestoreContainer";
import moment from "moment";

class OrderDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showSpec: false
        };
    }

    componentDidMount() {
        const {designs} = this.props
        const design = first(designs.data);
        Promise.all([
            design.view.get(),
            design.target.get()
        ]).then((values) => {
            this.setState({
                view: values[0].data(),
                target: values[1].data()
            })
            values[0].data().dataset.get()
            .then(value => {
                this.setState({
                    dataset: value.data(),
                })
            })
        })
    }

    render() {
        const {designs, order, lastShipmentNotComplete} = this.props;
        const {view, target, dataset} = this.state;
        if (loading(designs)) {
            return <Spinner/>
        }

        const design = first(designs.data);

        if (!design) {
            return <section className="orderFlow">
                <div className="panel error">No order design exists for this order</div>
            </section>
        }

        if ((!this.state.target && !this.state.view) || !this.state.dataset) {
            return <Spinner/>
        }

        // Get order creation date from it's ID and compare with last update time of dataset
        let isOrderStale = !!dataset.lastUpdatedOn && moment(dataset.lastUpdatedOn.toDate()) 
                            > moment(order.id.match("([0-9]{8}_[0-9]{6})")[0].replace('_', ' '));

        return (<Panel className="orderDetailsPanel">
            <div className="panelHeader">
                <h2>{order.name}</h2>
                <nav className="buttons">
                    <ReplaceOrderButton order={order} design={design} lastShipmentNotComplete={lastShipmentNotComplete}/>
                    <OrderActivationButton order={order}/>
                    <RefreshOrderButton order={order} autotrigger={!isDone(order)}/>
                </nav>
            </div>
            <section className="orderDetails">
                <dl>
                    <dt>Client
                    </dt>
                    <dd><NavLink to={`/clients/${design.clientId.id}`}>{order.clientName}</NavLink></dd>
                    <DisplayIf condition={!!order.replaces}>
                        <dt>Replaces order</dt>
                        <dd><NavLink to={`/orders/${order.replaces}`}>{order.replaces}</NavLink></dd>
                    </DisplayIf>
                    <DisplayIf condition={!!order.replacedBy}>
                        <dt>Replaced by</dt>
                        <dd><NavLink to={`/orders/${order.replacedBy}`}>{order.replacedBy}</NavLink></dd>
                    </DisplayIf>
                    <dt>Status</dt>
                    <dd>{order.active === false ? "Stopped" : "Active"}</dd>
                    <dt>Dataset Used</dt>
                    <dd><NavLink to={`/datasets/${view.dataset.id}`} target="_blank">{dataset.name}</NavLink> 
                        {isOrderStale && 
                            <>
                                <FontAwesomeIcon icon={"exclamation-triangle"} color="red" style={{"margin-left": "0.5em"}} />
                                <span className="error" style={{"padding-left": "0.5em"}}>
                                    Stale!! Replace order to make Dataset changes take effect!!
                                </span>
                            </>
                        }
                    </dd>
                    <dt>Reads from</dt>
                    <dd><NavLink to={`/views/${design.view.id}`} target="_blank">{view.name}</NavLink></dd>
                    <dt>Writes to</dt>
                    <dd><NavLink to={`/targets/${design.target.id}`} target="_blank">{target.name}</NavLink></dd>
                    <dt>Filename template</dt>
                    <dd>{design.filename}</dd>
                    <Schedule schedule={order.schedule}/>
                </dl>
                <input className="toggleButtonInput" id="showRawSpec"
                       type="checkbox" name="active"
                       onChange={() => this.setState({showSpec: !this.state.showSpec})}
                       checked={this.state.showSpec}/>
                <label className="toggleButton rawSpecButton" htmlFor="showRawSpec">
                    <FontAwesomeIcon icon="code"/> Show raw specification
                </label>

                <Collapsible open={this.state.showSpec} className="rawSpec">
                    <h3>Raw specification</h3>
                    <code>
                        {JSON.stringify(design.jobSpec, null, 2)}
                    </code>
                </Collapsible>
            </section>
        </Panel>)
    }

}

export default OrderDetails;
