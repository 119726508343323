import firebase from "firebase/app";
import request from "superagent";

const functionsUrl = process.env.REACT_APP_FIREBASE_FUNCTIONS_URL;


export const getSubscriberPermissions = topic => {
    return new Promise((resolve, reject) => {
        firebase
            .auth()
            .currentUser.getIdToken()
            .then(token => {
                return request
                    .put(`${functionsUrl}/permissions/subscriber/`)
                    .query({
                        topic: topic
                    })
                    .timeout({response: 20000})
                    .set("Authorization", "Bearer " + token)
                    .then(res => {
                        resolve(res.body);
                        console.log(res);
                    })
                    .catch(err => {
                        console.log(err);
                        console.log(err.response);
                        if (err.response) {
                            console.log("Failed to get pubsub permissions", err.response.body);
                        }
                        reject(err.response);
                    });
            });

    })
};
