import React from "react";
import {Link} from "react-router-dom";
import "./Client.css"

const Client = ({client}) => {
    return (
        <Link to={`/clients/${client._id}`} className={"consoleTile client" + (client.filtered ? " filtered":"")}>
            <h3>{client.name}</h3>
            <p><strong>{client.orders ? client.orders.length : 0}</strong> active orders</p>
        </Link>
    );
};
export default Client;
