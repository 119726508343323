import React, {Component} from "react";
import {BrowserRouter, Link, Route, Switch} from "react-router-dom";

import "./App.css";
import "react-widgets/styles.css";
import "css-tooltip/dist/css-tooltip.css";
import {auth, db, isUserFromAllowedDomain, login, logout} from "./firebase";

import DocumentTitle from 'react-document-title'
import Console from "./components/console/Console";
import CreateViewPage from "./containers/CreateViewPage";
import ViewPage from "./containers/ViewPage";
import OrderListPage from "./containers/OrderListPage";
import OrderDesignPage from "./containers/OrderDesignPage";
import OrderPage from "./containers/OrderPage";

import CreateClientPage from "./containers/CreateClientPage";
import ClientListPage from "./containers/ClientListPage";
import OrderDesignReviewPage from "./containers/OrderDesignReviewPage";
import UserAdminPage from "./containers/UserAdminPage";

import TargetsPage from "./containers/TargetsPage";
import Spinner from "./components/spinner/Spinner";
import {getClaims} from "./auth";

import ViewListPage from "./containers/ViewListPage";

import fontawesome from '@fortawesome/fontawesome'
import solid from '@fortawesome/fontawesome-free-solid';
import ClientDashboard from "./containers/ClientDashboard/ClientDashboard";
import CreateDatasetPage from "./components/datasets/CreateDatasetPage";
import DatasetPage from "./components/datasets/DatasetPage";
import DatasetListContainer from "./components/datasets/DatasetListContainer";
import QueryWizardContainer from "./containers/QueryWizard/QueryWizardContainer";
import ManageQueryWizardPage from "./containers/QueryWizard/ManageQueryWizardPage";
import CreateBucket from "./components/buckets/CreateBucket";
import TargetDetailPage from "./containers/TargetDetailPage";

fontawesome.library.add(solid);

class App extends Component {
    state = {
        claims: {},
        authenticated: false,
        user: null,
        loading: false,
    };

    componentDidMount() {
        this.setState({
            loading: true,
        });

        this.removeListener = auth().onAuthStateChanged(user => {
            if (this.removeMetadataListener) {
                this.removeMetadataListener();
            }
            if (user) {
                this.metadataRef = db.collection("metadata").doc(user.uid);
                this.removeMetadataListener = this.metadataRef.onSnapshot(() => {
                    getClaims(true).then(claims => {
                        this.setState({
                            claims,
                            authenticated: claims.admin || claims.unacastemployee,
                            user,
                            loading: false,
                        });
                    });
                }, err => console.error(err));

                getClaims().then(claims => {
                    this.setState({
                        claims,
                        authenticated: claims.admin || claims.unacastemployee,
                        user,
                        loading: false,
                    });
                });
            } else {
                this.setState({
                    claims: {},
                    authenticated: false,
                    user: null,
                    loading: false,
                });
            }
        });
    }

    componentWillUnmount() {
        this.removeListener();
    }

    render() {
        const {authenticated, user, loading, claims} = this.state;
        return (<DocumentTitle title="Lift. Data Order Console.">
                <React.StrictMode>
                    <BrowserRouter>
                        <div className="App">
                            <Header user={user} claims={claims}/>
                            <section className="App-Content">
                                {authenticated && (
                                    <Switch>
                                        <Route path="/querywizard/:tablename">
                                            <QueryWizardContainer claims={claims}/>
                                        </Route>
                                        <Route path="/managequerywizard/">
                                            <ManageQueryWizardPage/>
                                        </Route>
                                        <Route path="/datasets/create">
                                            <CreateDatasetPage claims={claims}/>
                                        </Route>
                                        <Route path="/datasets/:datasetId">
                                            <DatasetPage claims={claims}/>
                                        </Route>
                                        <Route exact path="/datasets">
                                            <DatasetListContainer claims={claims}/>
                                        </Route>
                                        <Route path="/buckets/create">
                                            <CreateBucket claims={claims}/>
                                        </Route>
                                        <Route path="/views/create">
                                            <CreateViewPage claims={claims}/>
                                        </Route>
                                        <Route path="/views/:viewId">
                                            <ViewPage claims={claims}/>
                                        </Route>
                                        <Route path="/views">
                                            <ViewListPage/>
                                        </Route>
                                        <Route exact path="/orderdesigns/:id/review">
                                            <OrderDesignReviewPage claims={claims}/>
                                        </Route>
                                        <Route exact path="/orders">
                                            <OrderListPage/>
                                        </Route>
                                        <Route path="/orders/:orderId">
                                            <OrderPage/>
                                        </Route>
                                        <Route exact path="/clients">
                                            <ClientListPage/>
                                        </Route>
                                        <Route
                                            exact
                                            path="/clients/create"
                                            render={props =>
                                                claims.admin ? (
                                                    <CreateClientPage {...props} />
                                                ) : (
                                                    <div>
                                                        Access Denied: You need to admin
                                                        access to create clients
                                                    </div>
                                                )
                                            }
                                        />

                                        <Route
                                            path="/clients/:clientId/targets/create"
                                            render={props =>
                                                claims.admin ? (
                                                    <TargetsPage {...props} />
                                                ) : (
                                                    <div>
                                                        Access Denied: You need admin
                                                        access to create targets
                                                    </div>
                                                )
                                            }
                                        />

                                        <Route
                                            path="/clients/:clientId/orders/create"
                                            render={props =>
                                                claims.admin ? (
                                                    <OrderDesignPage
                                                        {...props}
                                                        user={user}
                                                    />
                                                ) : (
                                                    <div>
                                                        Access Denied: You need admin
                                                        access to create orders
                                                    </div>
                                                )
                                            }
                                        />
                                        <Route path="/clients/:clientId">
                                            <ClientDashboard claims={claims}/>
                                        </Route>

                                        <Route path="/targets/:targetId">
                                            <TargetDetailPage claims={claims}/>
                                        </Route>

                                        <Route
                                            path="/adminland/users"
                                            render={props =>
                                                claims.admin ? (
                                                    <UserAdminPage {...props} />
                                                ) : (
                                                    <div>
                                                        Access Denied: You need admin
                                                        access to access the admin
                                                        pages.
                                                    </div>
                                                )
                                            }
                                        />

                                        <Route exact path="/">
                                            <Console
                                                claims={claims}
                                            />
                                        </Route>
                                    </Switch>
                                )}
                                {!authenticated && (
                                    <Landing
                                        user={user}
                                        loading={loading}
                                    />
                                )}
                            </section>
                            <Footer/>
                        </div>
                    </BrowserRouter>
                </React.StrictMode>
            </DocumentTitle>
        );
    }
}

const Footer = () => {
    return (
        <footer className="App-footer">
            <p>
                Made and supported by <a href="https://app.slack.com/client/T0373J9F9/CCJH450N6" target="_blank"
                                         rel="noopener noreferrer">team I/O</a>
                <span role="img" aria-label="cow"> 🐮 </span>
                <a href="https://unacast.com">unacast.com</a>
            </p>
        </footer>
    );
};

const Header = ({user, claims}) => {
    return (
        <header className="App-header">
            <Link to={"/"} className="App-title">
                <h4>Lift. Data Order Console.</h4>
            </Link>
            <LoggedIn user={user} claims={claims}/>
        </header>
    );
};

const LoggedIn = ({user}) => {
    if (!user) return <div/>;

    return (
        <div className="loggedIn">
            <div>{user.displayName}</div>
            <button className="callToAction" onClick={logout}>
                Logout
            </button>
        </div>
    );
};

const Login = () => {
    return (
        <div className="notLoggedIn">
            <button className="callToAction" onClick={login}>
                Log in with Google
            </button>
        </div>
    );
};

const Landing = ({user, loading}) => {
    return (
        <div>
            {loading && <section className="App-Content">
                <section><Spinner>Loading page...</Spinner></section>
            </section>}
            {!loading && (
                <section className="App-Content">
                    <section>
                        {user && !isUserFromAllowedDomain(user) && (
                            <div>
                                <h3>
                                    Dear {user.displayName}, only Unacast employees has access to this page.
                                </h3>
                                <LoggedIn/>
                            </div>
                        )}
                        {user && isUserFromAllowedDomain(user) && (
                            <div>
                                <h3>
                                    Dear {user.displayName}, you have unfortunately not
                                    been granted access just yet. Stay tuned!
                                </h3>
                            </div>
                        )}
                        {!user && (
                            <div>
                                <p>
                                    Log in using your Unacast email address
                                </p>
                                <Login/>
                            </div>
                        )}
                    </section>
                </section>
            )}
        </div>
    );
};

export default App;
