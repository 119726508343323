import React, {Component} from "react";
import "./Targets.css";
import {isEqual} from 'lodash';
import {createBucket, encryptSensitiveFields, encryptValue, validateBucket} from "./target-methods";

import {DropdownList} from "react-widgets";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import Collapsible from "../collapsible/Collapsible";
import urlJoin from "proper-url-join";
import TargetPolicyList from "./TargetPolicyList";

const targetTypeEnum = {
    S3: "S3",
    SFTP: "SFTP",
    AZURE: "Azure",
    GCS: "GCS",
    GDrive: "Google Drive",
    NewS3: "New S3 bucket",
};

class CreateTarget extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newTarget: {
                archived: false,
                name: "",
                targetType: "",
                folder: "",

                // s3
                accessKey: "",
                secretKey: "",
                bucket: "",
                region: "",

                // s3 new bucket
                partner: "",
                direction: "export",

                // sftp
                username: "",
                password: "",
                host: "",
                port:"22",

                //azure
                accountName: null,
                accountKey: null,
                serviceUrl: null,
                containerName: "",

                //gcs
                gcsBucket: "",

                //gdrive
                gdriveFolderId: "",

            },
            validated: false,
            validatedBucket: undefined,
            validatedGDrive: undefined,
            validationRunning: false,
            allowInvalidBucket: false,
            creationRunning: false,
            openPolicyList: false,
            useDefaultCredentials: false,
        };
    }


    formSubmitted(e) {
        e.preventDefault();
        encryptSensitiveFields(encryptValue, this.state.newTarget).then(newTarget => {
                const toSave = {
                    ...newTarget,
                    clientId: this.props.client._ref,
                };
                if (toSave.targetType === undefined) {
                    alert("Please choose a target type from the dropdown.");
                    return;
                }
                if (toSave.targetType === targetTypeEnum.S3 && toSave.region === "") {
                    alert("Please choose a region for the S3 bucket.");
                    return;
                }
                let folderpathInBucketname = toSave.bucket.match(/\/(.*)/);
                if (folderpathInBucketname && folderpathInBucketname[0]) {
                    this.setFormValue("bucket", toSave.bucket.replace(folderpathInBucketname[0], ""));
                    alert("Please only provide the name of the bucket in the bucket input. Use the folder/path input to fill in the path within the bucket.");
                    return;
                }
                if (toSave.targetType === targetTypeEnum.NewS3) {
                    toSave.targetType = targetTypeEnum.S3;
                }
                this.props.addTarget(toSave);
            }
        ).catch(err => {
            console.error(err)
            alert(`Unable to create target: ${err}`)
        })
    }

    formChanged(e) {
        if (e.target.name === "bucket" && e.target.value.toLowerCase().includes("s3://")) {
            this.setFormValue(e.target.name, e.target.value.slice(5));
        } else if (e.target.name === "serviceUrl") {
            let url = new URL(e.target.value);
            this.setFormValues({
                "serviceUrl": url,
                "accountName": url.host.split(".")[0],
                "containerName": url.pathname.substr(1)
            });
        } else {
            this.setFormValue(e.target.name, e.target.value);
        }

    }

    saveInvalidatedToggled(e) {
        this.setState({allowInvalidBucket: e.target.checked})
    }

    setFormValues(keyAndValues) {
        this.setState({
            newTarget: {
                ...this.state.newTarget,
                ...keyAndValues,
            },
        });
    }

    setFormValue(key, val) {
        this.setState({
            newTarget: {
                ...this.state.newTarget,
                [key]: val,
            },
        });
    }

    typeChosen(type) {
        this.setState({
            newTarget: {
                ...this.state.newTarget,
                targetType: type,
            },
        });
    }

    useDefaultCredsChecked(e) {
        let accessKey = "";
        let secretKey = "";
        if (e.target.checked) {
            accessKey = this.props.credentials.accessKey;
            secretKey = this.props.credentials.secretKey;
        }
        this.setState({
            newTarget: {
                ...this.state.newTarget,
                accessKey: accessKey,
                secretKey: secretKey
            },
            useDefaultCredentials: e.target.checked,
        })
    }

    regionChosen(region) {
        this.setFormValue('region', region)
    }

    s3BucketCanBeValidated() {
        const target = this.state.newTarget;
        return !!target.bucket && !!target.region && !!target.secretKey && !!target.accessKey;
    }

    currentGDriveValidated() {
        const target = this.state.newTarget;
        const newGDrive = {
            gdriveFolderId: target.gdriveFolderId,
            folder: target.folder
        }

        const validatedGDrive = this.state.validatedGDrive;
        return isEqual(newGDrive, validatedGDrive);
    }

    currentS3BucketValidated() {
        const target = this.state.newTarget;
        const newTargetBucket = {
            name: target.bucket,
            region: target.region,
            secretkey: target.secretKey,
            accesskey: target.accessKey,
            folder: target.folder
        };
        const validatedBucket = this.state.validatedBucket;

        return isEqual(newTargetBucket, validatedBucket);
    }

    validateGDrive() {
        const target = this.state.newTarget;
        const targetData = {
            folder: target.folder,
            gdriveFolderId: target.gdriveFolderId
        }
        this.setState({validationRunning: true});
        const valid = target.gdriveFolderId.startsWith("0A")
        this.setState({
            newTarget: {
                ...this.state.newTarget,
            },
            validated: valid,
            validatedGDrive: targetData,
            validationErrorMsg: valid ? "" : "The Folder ID must start with 0A to be a Shared Drive",
            validationRunning: false
        })
    }

    validateS3Bucket() {
        const target = this.state.newTarget;
        const targetData = {
            name: target.bucket,
            region: target.region,
            secretkey: target.secretKey,
            accesskey: target.accessKey,
            folder: urlJoin(target.folder, {leadingSlash: false})
        };
        this.setState({validationRunning: true});
        validateBucket(targetData).then(res => {

            const errMsgs = [
                res.DeleteObject !== "OK" ? "DeleteObject: " + res.DeleteObject.split("\n")[0] : "",
                res.GetObject !== "OK" ? "GetObject: " + res.GetObject.split("\n")[0] : "",
                res.PutObject !== "OK" ? "PutObject: " + res.PutObject.split("\n")[0] : "",
                res.HeadObject !== "OK" ? "HeadObject: " + res.HeadObject.split("\n")[0] : "",
            ].filter(msg => msg !== "").join(" AND ");

            this.setState({
                newTarget: {
                    ...this.state.newTarget,
                },
                validated: res.Success,
                validatedBucket: targetData,
                validationErrorMsg: errMsgs,
                validationRunning: false
            })
        }).catch(err => {
            this.setState({
                newTarget: {
                    ...this.state.newTarget,
                },
                validated: false,
                validatedBucket: targetData,
                validationErrorMsg: err.text,
                validationRunning: false
            });
        })
    }

    createBucket() {
        const target = this.state.newTarget;
        const bucketData = {
            name: target.bucket,
            region: target.region,
            direction: target.direction,
            partner: target.partner
        };

        this.setState({creationRunning: true});
        createBucket(bucketData).then(res => {

            console.log(res);
            this.setState({
                newTarget: {
                    ...this.state.newTarget,
                    secretKey: res.secretkey,
                    accessKey: res.accesskey,
                    region: res.region,
                },
                bucketCreated: res.accesskey !== "",
                creationRunning: false,
                bucketCreationErrorMsg: null
            })
        }).catch(err => {
            this.setState({
                creationRunning: false,
                bucketCreationErrorMsg: err.text
            });
        });
    }

    togglePolicyList() {
        this.setState({
            openPolicyList: !this.state.openPolicyList
        })
    }

    render() {
        const onChange = this.formChanged.bind(this);
        const newTarget = this.state.newTarget;

        const submitAllowed =
            (this.state.newTarget.targetType === targetTypeEnum.NewS3 && !!this.state.bucketCreated)
            || ((this.currentS3BucketValidated() || this.currentGDriveValidated()) && !!this.state.validated)
            || this.state.allowInvalidBucket
            || this.state.newTarget.targetType === targetTypeEnum.SFTP
            || this.state.newTarget.targetType === targetTypeEnum.AZURE
            || this.state.newTarget.targetType === targetTypeEnum.GCS
        return (
            <section className="createTarget">
                <h3>Create Target</h3>
                <p>
                    Self Service Unacast managed S3 bucket creation still under
                    development.
                </p>
                <p>
                    Do you need Team Data Lift to create a new Unacast managed
                    S3 bucket? Make a request in the{" "}
                    <code>#team-io</code> channel on Slack.
                </p>
                <form onSubmit={this.formSubmitted.bind(this)}>
                    <div className="fieldset">
                        <span className="field">
                            <label htmlFor={"name"}>Target Name</label>
                            <input
                                name={"name"}
                                placeholder={"Name this target"}
                                onChange={onChange}
                                value={newTarget.name}
                                required={true}
                            />
                        </span>
                    </div>
                    <div className="fieldset">
                        <span className="field">
                            <label>Type</label>
                            <DropdownList
                                name="targetType"
                                data={[
                                    targetTypeEnum.S3,
                                    targetTypeEnum.NewS3,
                                    targetTypeEnum.SFTP,
                                    targetTypeEnum.AZURE,
                                    targetTypeEnum.GCS,
                                    targetTypeEnum.GDrive,
                                ]}
                                placeholder={"Choose type"}
                                value={newTarget.targetType}
                                inputProps={{required: true}}
                                onChange={this.typeChosen.bind(this)}
                            />
                        </span>
                    </div>
                    {this.state.newTarget.targetType === targetTypeEnum.SFTP && (
                        <div className="fieldset">
                            <h3>SFTP Target Configuration</h3>
                            <SFTPDescription/>
                            <span className="field">
                                <label htmlFor={"host"}>Host</label>
                                <input
                                    name={"host"}
                                    placeholder={"Host"}
                                    onChange={onChange}
                                    value={newTarget.host}
                                    required={true}
                                />
                            </span>
                            <span className="field">
                                <label htmlFor={"port"}>Port</label>
                                <input
                                    name={"port"}
                                    placeholder={"22"}
                                    onChange={onChange}
                                    value={newTarget.port}
                                    required={true}
                                />
                            </span>
                            <span className="field">
                                <label htmlFor={"username"}>Username</label>
                                <input
                                    name={"username"}
                                    placeholder={"Username"}
                                    onChange={onChange}
                                    value={newTarget.username}
                                    required={true}
                                />
                            </span>
                            <span className="field">
                                <label htmlFor={"password"}>Password</label>
                                <input
                                    name={"password"}
                                    placeholder={"Password"}
                                    onChange={onChange}
                                    value={newTarget.password}
                                    required={true}
                                />
                            </span>
                            <span className="field">
                                <label htmlFor={"folder"}>Base Folder</label>
                                <input
                                    name={"folder"}
                                    placeholder={"Folder"}
                                    onChange={onChange}
                                    value={newTarget.folder}
                                />
                            </span>
                        </div>
                    )}
                    {this.state.newTarget.targetType === targetTypeEnum.AZURE && (
                        <div className="fieldset">
                            <h3>Azure Target Configuration</h3>
                            <AzureDescription/>
                            <span className="field">
                                <label htmlFor={"serviceUrl"}>Service URL w/SAS</label>
                                <input
                                    name={"serviceUrl"}
                                    placeholder={"ServiceURL"}
                                    onChange={onChange}
                                    value={newTarget.serviceUrl}
                                />
                            </span>
                            <span className="field">
                                <label htmlFor={"folder"}>Base Folder</label>
                                <input
                                    name={"folder"}
                                    placeholder={"Folder"}
                                    onChange={onChange}
                                    value={newTarget.folder}
                                />
                            </span>
                            <span className="field">
                                <p><strong>- OR -</strong></p>
                            </span>
                            <span className="field">
                                <label htmlFor={"containerName"}>Container</label>
                                <input
                                    name={"containerName"}
                                    placeholder={"Container"}
                                    onChange={onChange}
                                    value={newTarget.containerName}
                                    required={true}
                                />
                            </span>
                            <span className="field">
                                <label htmlFor={"accountName"}>Account Name</label>
                                <input
                                    name={"accountName"}
                                    placeholder={"AccountName"}
                                    onChange={onChange}
                                    value={newTarget.accountName}
                                />
                            </span>
                            <span className="field">
                                <label htmlFor={"accountKey"}>Account Key</label>
                                <input
                                    name={"accountKey"}
                                    placeholder={"AccountKey"}
                                    onChange={onChange}
                                    value={newTarget.accountKey}
                                />
                            </span>
                        </div>
                    )}
                    {this.state.newTarget.targetType === targetTypeEnum.GCS && (
                        <div className="fieldset">
                            <h3>GCS Target Configuration</h3>
                            <div className="targetDescription">
                                <GCSDescription/>
                            </div>
                            <span className="field">
                                <label htmlFor={"gcsBucket"}>Bucket</label>
                                    <h5>{'gs://'}</h5>
                                    <input
                                        id={"gcsBucketInput"}
                                        name={"gcsBucket"}
                                        placeholder={"Bucket"}
                                        onChange={onChange}
                                        value={newTarget.gcsBucket}
                                        required={true}
                                    />
                            </span>
                            <span className="field">
                                <label htmlFor={"folder"}>Base Folder</label>
                                <input
                                    name={"folder"}
                                    placeholder={"Folder"}
                                    onChange={onChange}
                                    value={newTarget.folder}
                                />
                            </span>
                        </div>
                    )}
                    {this.state.newTarget.targetType === targetTypeEnum.GDrive && (
                        <div className="fieldset">
                            <h3>Google Drive Target Configuration</h3>
                            <div className="targetDescription">
                                <GDriveDescription/>
                            </div>
                            <span className="field">
                                <label htmlFor={"gdriveFolderId"}>Folder ID</label>
                                    <h5>{''}</h5>
                                    <input
                                        id={"gdriveFolderIdInput"}
                                        name={"gdriveFolderId"}
                                        placeholder={"FolderID"}
                                        onChange={onChange}
                                        value={newTarget.gdriveFolderId}
                                        required={true}
                                    />
                            </span>
                            <span className="field">
                                <label htmlFor={"folder"}>Base Folder</label>
                                <input
                                    name={"folder"}
                                    placeholder={"Folder"}
                                    onChange={onChange}
                                    value={newTarget.folder}
                                />
                            </span>
                            <span className="buttons">
                                        <button type="button"
                                                disabled={this.currentGDriveValidated()}
                                                onClick={this.validateGDrive.bind(this)}>Validate</button>
                                        <div>
                                        <ValidationStatus currentBucketValidated={this.currentGDriveValidated()}
                                                          valid={this.state.validated}
                                                          errors={this.state.validationErrorMsg}
                                                          validationRunning={this.state.validationRunning}
                                        />
                                        </div>
                                    </span>
                        </div>
                    )}
                    {(this.state.newTarget.targetType === targetTypeEnum.S3 || this.state.newTarget.targetType === targetTypeEnum.NewS3) && (
                        <div className="fieldset">
                            <h3>S3 Target Configuration</h3>
                            <div className="targetDescription">
                                <S3Description/>
                                <TargetPolicyList openPolicyList={this.state.openPolicyList}
                                                  togglePolicyList={this.togglePolicyList}/>
                            </div>
                            <span className="field">
                                <label htmlFor={"bucket"}>Bucket</label>
                                    <h5>{'s3://'}</h5>
                                    <input
                                        id={"bucketInput"}
                                        name={"bucket"}
                                        placeholder={"Bucket"}
                                        onChange={onChange}
                                        value={newTarget.bucket}
                                        required={true}
                                    />
                            </span>
                            {this.state.newTarget.targetType === targetTypeEnum.S3 &&
                                <span className="field">
                                <label htmlFor={"folder"}>Folder/Path</label>
                                 <div>
                                    <input
                                        name={"folder"}
                                        placeholder={"Folder"}
                                        onChange={onChange}
                                        value={newTarget.folder}
                                    />
                                   <span className="fieldComment">
                                      Example: somefolder/anotherfolder/lastfolder
                                    </span>
                                </div>
                            </span>}
                            <span className="field">
                                <label htmlFor={"region"}>Region</label>
                                <DropdownList
                                    name="region"
                                    placeholder={"Select region"}
                                    value={newTarget.region}
                                    data={[
                                        "us-east-2",
                                        "us-east-1",
                                        "us-west-1",
                                        "us-west-2",
                                        "ap-south-1",
                                        "ap-northeast-3",
                                        "ap-northeast-2",
                                        "ap-southeast-1",
                                        "ap-southeast-2",
                                        "ap-northeast-1",
                                        "ca-central-1",
                                        "cn-north-1",
                                        "cn-northwest-1",
                                        "eu-central-1",
                                        "eu-west-1",
                                        "eu-west-2",
                                        "eu-west-3",
                                        "eu-north-1",
                                        "sa-east-1",
                                        "us-gov-east-1",
                                        "us-gov-west-1"
                                    ]}
                                    inputProps={{required: true}}
                                    onChange={this.regionChosen.bind(this)}
                                />
                            </span>
                            {this.state.newTarget.targetType === targetTypeEnum.S3 &&
                                <div className="credentialsSwitch">
                                    <div>
                                        <label className="switch">
                                            Use Unacast Delivery credentials
                                            <input className="dataFieldCheckbox" type="checkbox"
                                                   name="useDefaultCredentials" id="useDefaultCredentials"
                                                   onChange={this.useDefaultCredsChecked.bind(this)}
                                                   checked={this.state.useDefaultCredentials}/>
                                            <span className="slider round"/>
                                        </label>
                                    </div>
                                </div>
                            }

                            {this.state.newTarget.targetType === targetTypeEnum.NewS3 ?
                                <span className="fieldset">
                                    <span className="field">
                                        <label htmlFor={"partner"}>Partner</label>
                                        <div>
                                            <input
                                                name={"partner"}
                                                placeholder={"Name of partner"}
                                                onChange={onChange}
                                                onInput={e => {
                                                    e.target.value = e.target.value.replace(/[^0-9a-zA-Z+=,.@_-]/g, "")
                                                }}
                                                value={newTarget.partner}
                                            />
                                            <span className="fieldComment">
                                                Must contain only alphanumeric characters and/or the following: +=,.@_-
                                            </span>
                                        </div>
                                    </span>
                                    <span className="buttons">
                                    <button type="button"
                                            onClick={this.createBucket.bind(this)}
                                            disabled={this.state.creationRunning}>Create Bucket</button>
                                           <CreateBucketStatus bucketCreated={this.state.bucketCreated}
                                                               error={this.state.bucketCreationErrorMsg}
                                                               creationRunning={this.state.creationRunning}
                                           />
                                    </span>
                                    {this.state.bucketCreated && (
                                        <div className="targetDescription">
                                            <h4>A new bucket was created:</h4>
                                            <dl>
                                                <dt>Bucket</dt>
                                                <dd>{this.state.newTarget.bucket}</dd>
                                                <dt>Access Key</dt>
                                                <dd>{this.state.newTarget.accessKey}</dd>
                                                <dt>Secret Key</dt>
                                                <dd>{this.state.newTarget.secretKey}</dd>
                                                <dt>Region</dt>
                                                <dd>{this.state.newTarget.region}</dd>
                                            </dl>
                                        </div>)}
                                    <span className="field">
                                <label htmlFor={"folder"}>Folder/Path</label>
                                <div>
                                    <input
                                        name={"folder"}
                                        placeholder={"Folder"}
                                        onChange={onChange}
                                        value={newTarget.folder}
                                    />
                                   <span className="fieldComment">
                                      Example: somefolder/anotherfolder/lastfolder
                                    </span>
                                </div>
                            </span>
                                </span>
                                :
                                <span className="fieldset">
                                    <span className="field">
                                    <label htmlFor={"accessKey"}>Access Key</label>
                                    <input
                                        name={"accessKey"}
                                        placeholder={"Access Key"}
                                        onChange={onChange}
                                        value={newTarget.accessKey}
                                        required={true}
                                        disabled={this.state.useDefaultCredentials}
                                    />
                                    </span>
                                    <span className="field">
                                        <label htmlFor={"secretKey"}>Secret Key</label>
                                        <input
                                            name={"secretKey"}
                                            placeholder={"SecretKey"}
                                            onChange={onChange}
                                            value={newTarget.secretKey}
                                            required={true}
                                            disabled={this.state.useDefaultCredentials}
                                        />
                                    </span>
                                    <span className="buttons">
                                        <button type="button"
                                                disabled={!this.s3BucketCanBeValidated() || this.currentS3BucketValidated()}
                                                onClick={this.validateS3Bucket.bind(this)}>Validate</button>
                                        <div>
                                        <ValidationStatus currentBucketValidated={this.currentS3BucketValidated()}
                                                          valid={this.state.validated}
                                                          errors={this.state.validationErrorMsg}
                                                          validationRunning={this.state.validationRunning}
                                        />
                                        </div>
                                    </span>
                                </span>
                            }
                        </div>
                    )}
                    <div className="buttons">
                        <Collapsible
                            open={!this.state.validated && (this.currentS3BucketValidated() || this.currentGDriveValidated())}>
                            <label>Ignore failed bucket validation.</label><input type="checkbox"
                                                                                  onChange={this.saveInvalidatedToggled.bind(this)}/>
                        </Collapsible>
                        <input
                            type={"submit"}
                            disabled={!submitAllowed}
                            value={"Create Target"}
                            className="callToAction"
                        />
                    </div>
                </form>
            </section>
        );
    }
}

const ValidationStatus = ({currentBucketValidated, valid, errors, validationRunning}) => {
    if (validationRunning) {
        return <div className="panel"><FontAwesomeIcon icon="spinner" pulse={true}/><p>Validating bucket...</p></div>;
    }
    if (!currentBucketValidated) {
        return <div className="panel"><FontAwesomeIcon icon="hand-point-left"/> Check if this bucket can be used as a
            target.</div>;
    }
    return (valid ?
            <div className="panel"><FontAwesomeIcon icon="thumbs-up"/> <p>This bucket can be used as a target!</p>
            </div> :
            <div className="panel error"><FontAwesomeIcon icon="thumbs-down"/> <p>This bucket can't be used
                because: {errors}</p></div>
    )
};

const CreateBucketStatus = ({bucketCreated, error, creationRunning}) => {
    if (creationRunning) {
        return <div className="panel"><FontAwesomeIcon icon="spinner" pulse={true}/><p>Creating bucket...</p></div>;
    }
    if (bucketCreated) {
        return <div className="panel"><FontAwesomeIcon icon="thumbs-up"/> <p>A new bucket has been created!</p></div>
    }
    if (error != null) {
        return <div className="panel error"><FontAwesomeIcon icon="thumbs-down"/> <p>An error occurred: {error}</p>
        </div>
    }
    return null;
};

const S3Description = () => {
    return (
        <div>
            <h4>S3 Bucket</h4>
            <p>
                Samples or Subscriptions where Unacast or Client provides an S3
                bucket. Make sure we have the necessary policies.
            </p>
            <dl>
                <dt>Target Type</dt>
                <dd>File</dd>
                <dt>Folder structure</dt>
                <dd>
                    <code>
                        {
                            "s3://<BUCKET>/<FOLDER>/<DATASET_NAME>/<SHIPMENT_TIME>/"
                        }
                    </code>
                </dd>
                <dt>Cadence Allowed</dt>
                <dd>Up to once an hour.</dd>
            </dl>
        </div>
    );
};

const SFTPDescription = () => {
    return (
        <div className="targetDescription">
            <h4>SFTP</h4>
            <dl>
                <dt>Folder structure</dt>
                <dd>
                    <code>
                        {
                            "sftp://<HOST>:<PORT>/<FOLDER>/<DATASET_NAME>/<SHIPMENT_TIME>/"
                        }
                    </code>
                </dd>
                <dt>Base folder</dt>
                <dd>Configured below</dd>
            </dl>
        </div>
    );
};

const AzureDescription = () => {
    return (
        <div className="targetDescription">
            <h4>Azure</h4>
            <dl>
                <dt>Folder structure</dt>
                <dd>
                    <code>
                        {
                            "https://<ACCOUNT_NAME>.blob.core.windows.net/<CONTAINER>/<FOLDER>"
                        }
                    </code>
                </dd>
                <dt>Base folder</dt>
                <dd>Configured below</dd>
            </dl>
        </div>
    );
};

const GCSDescription = () => {
    return (
        <div className="targetDescription">
            <h4>GCS</h4>
            <dl>
                <dt>Folder structure</dt>
                <dd>
                    <code>
                        {
                            "gs://<BUCKET>/<OBJECT>"
                        }
                    </code>
                </dd>
                <dt>Base folder</dt>
                <dd>Configured below</dd>
            </dl>
        </div>
    );
};

const GDriveDescription = () => {
    return (
        <div className="targetDescription">
            <h4>Google Drive</h4>
            <dl>
                <dt>Folder structure</dt>
                <dd>
                    <code>
                        {
                            "https://drive.google.com/drive/u/0/folders/<FOLDER_ID>"
                        }
                    </code>
                </dd>
                <dt>Base folder</dt>
                <dd>Configured below</dd>
            </dl>
            <strong>This has to be be a Shared Drive! Shared folders in regular drives are not supported.</strong>
        </div>
    );
};

export default CreateTarget;
