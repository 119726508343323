import React, {Component} from 'react';
import './Spinner.css'
import logoOrange from './unacast-symbol-orange-rgb.svg'
import logoGreen from './unacast-symbol-arctic_green-rgb.svg'
import logoBlue from './unacast-symbol-deep_sea_blue-rgb.svg'
import logoWhite from './unacast-symbol-white-rgb.svg'
class Spinner extends Component {

    constructor(props){
        super();
        switch(props.color){
            case "orange":
                this.logo = logoOrange;
                break;
            case "green":
                this.logo = logoGreen;
                break;
            case "blue":
                this.logo = logoBlue;
                break;
            case "white":
                this.logo = logoWhite;
                break;
            default:
                this.logo = logoOrange;
        }
    }

    render() {
        return <div className="spinner">
        <img src={this.logo} className="spinner-logo" alt={"Spinner"}/>
            {this.props.children && <br/>}
            {this.props.children}
    </div> }
}
export default Spinner
