import React, {Component} from 'react';
import DisplayIf from "../../displayif/DisplayIf";
import Dropdown from "./Dropdown";
import FileConfigFormPartial from "./FileConfigFormPartial";
import CronExplained from "../../cron/CronExplained";
import Collapsible from "../../collapsible/Collapsible";

const schedules = [
    {name: "18:00 UTC", value: "0 0 18 * * * *"},
    {name: "00:45 and 12:45 UTC", value: "0 45 0,12 * * * *"},
    {name: "Every 2 hours UTC", value: "0 0 0/2 * * * *"},
    {name: "Custom Schedule", value: ""}
];

class ClientIntegrationFormPartial extends Component {

    constructor(props) {
        super(props);
        const {newOrder} = props;
        const usesPresetSchedule = (sched =>
                schedules
                    .filter(preset => preset.value === sched)
                    .length > 0
        )(newOrder.schedule);
        if (!usesPresetSchedule) {
            newOrder.customSchedule = newOrder.schedule;
            newOrder.schedule = "";
        }

    }

    scheduleChosen(schedule) {
        this.props.setField("schedule", schedule.value);
    }

    render() {
        const scheduleChosen = this.scheduleChosen.bind(this);

        const {newOrder, onChange, setField} = this.props;
        return <span>
                <DisplayIf condition={!!newOrder.target && !!newOrder.view && (newOrder.triggerType === undefined || newOrder.triggerType === "schedule")}>
                    <span className="field">
                        <label htmlFor="schedule">Schedule</label>
                        <div>
                            <Dropdown
                                data={schedules}
                                textField="name" valueField="value"
                                onChange={scheduleChosen}
                                value={newOrder.schedule}
                                placeholder="Choose schedule"
                            />
                            <Collapsible open={newOrder.schedule === ""}>
                                <fieldset className="subFields">
                                    <span className="field">
                                        <label htmlFor="customSchedule">Cron expression</label>
                                        <div>
                                            <input name="customSchedule" id="customSchedule" onChange={onChange}
                                                   placeholder={"* * * * * * *"}
                                                   value={newOrder.customSchedule}
                                            />
                                            <span className="fieldComment">Input a valid cron expression, including year and seconds, for the wanted schedule</span>
                                        </div>
                                    </span>
                                    <dl>
                                        <dt>Resulting schedule</dt><dd><CronExplained expression={newOrder.customSchedule}/></dd>
                                    </dl>
                                </fieldset>
                            </Collapsible>
                        </div>
                    </span>
                </DisplayIf>

                <DisplayIf condition={!!newOrder.schedule || !!newOrder.customSchedule || newOrder.triggerType === "pubsub"}>
                    <FileConfigFormPartial newOrder={newOrder} onChange={onChange} setField={setField}/>

                    <h3>Success file</h3>
                    <span className="field">
                        <label htmlFor="createSuccessFile">Create success file</label>
                        <input type="checkbox" name="createSuccessFile" id="createSuccessFile" checked={newOrder.createSuccessFile}
                               onChange={onChange}
                        />
                    </span>
                    <DisplayIf condition={newOrder.createSuccessFile}>
                        <span className="field">
                            <label htmlFor="successfile">Success file name</label>
                            <span>
                                <input
                                    disabled={!newOrder.createSuccessFile}
                                    type="text"
                                    name="successfileName"
                                    placeholder="_SUCCESS"
                                    value={newOrder.successfileName}
                                    onChange={onChange}
                                    required={true}
                                />
                                <span className="fieldComment">
                                    The default is _SUCCESS, but it can be changed to meet specific client needs
                                </span>
                            </span>
                        </span>
                        <span className="field">
                            <label htmlFor="successfilePostfix">Success file postfix</label>
                            <input type="checkbox" name="successfilePostfix"
                                   checked={newOrder.successfilePostfix}
                                   disabled={!newOrder.createSuccessFile}
                                   onChange={onChange}
                            />
                            <span className="fieldComment">
                                If checked, the successfile will have its name postfixed with a timestamp for the shipment.
                                eg:<code>{newOrder.successfileName || "_SUCCESS"}-2006_01_02_150405</code>
                            </span>
                        </span>
                    </DisplayIf>
                </DisplayIf>
        </span>
    }

}

export default ClientIntegrationFormPartial;
