import React, {Component} from 'react';
import OrderOverview from "./OrderOverview";
import {filter} from 'lodash';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Spinner from "../../components/spinner/Spinner";

class OrderOverviewList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingOrders: true,
            filter: {
                active: true,
                recurring: true,
            }
        };
    }

    componentDidMount() {
        const {designs} = this.props
        Promise.all(designs.data.map(d => {
            return !!d.orderRef && d.orderRef.get().then(o => d.order = o)
        })).then(() => this.setState({loadingOrders: false}))
    }

    filterChange(e) {
        const filter = e.target.name;
        const checked = e.target.checked;
        this.setState({filter: {...this.state.filter, [filter]: checked}})
    }

    render() {
        const {designs} = this.props;
        const submittedOrderdesigns = {
            data: filter(
                designs.data,
                od => od && !!od.orderRef
            ),
        };

        const filterChange = this.filterChange.bind(this);

        return <section className="orders">
            {(this.state.loadingOrders) ? <Spinner>Loading orders</Spinner> :
                <span>
                    <ul className="optionslist">
                        <li>
                            <label className="switch">
                                <FontAwesomeIcon icon="play"/> Active
                                <input id="filterActive" type="checkbox"
                                       name="active" onChange={filterChange}
                                       checked={this.state.filter.active}/>
                                <span className="slider round"/>
                            </label>
                        </li>
                        <li>
                           <label className="switch">
                                <FontAwesomeIcon icon="redo-alt"/> Recurring
                                <input id="filterRecurring" type="checkbox"
                                       name="recurring" onChange={filterChange}
                                       checked={this.state.filter.recurring}/>
                                <span className="slider round"/>
                            </label>
                        </li>
                    </ul>
                    {submittedOrderdesigns.data.map(od =>
                        <OrderOverview
                            key={od._id} design={od}
                            showOnlyRecurring={this.state.filter.recurring}
                            showOnlyActive={this.state.filter.active}
                        />
                    )}
                </span>
            }
        </section>
    }
}

export default OrderOverviewList;
