import React from "react";
import Firestore, {loading} from "../components/FirestoreContainer";
import Spinner from "../components/spinner/Spinner";
import OrderList from "../components/orders/OrderList";
import {useHistory, useLocation} from "react-router-dom";

const OrderListPage = () => {

    return (
        <Firestore
            queryMapFn={db => {
                return {
                    orders: db.collection("ordercache").where('active', '==', true),
                };
            }}
        >
            <OrderWithLoader/>
        </Firestore>
    );
};

const OrderWithLoader = ({orders}) => {
    let history = useHistory()
    let location = useLocation()

    return loading(orders) ? (
        <section><Spinner>Fetching orders...</Spinner></section>
    ) : (
        <section>
            <OrderList orders={orders.data} title="Submitted Orders"
                       history={history} location={location}/>
        </section>
    );
};

export default OrderListPage;
