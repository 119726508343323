import React, {Component} from 'react';
import Panel from "../../components/panel/Panel";
import {NavLink} from "react-router-dom"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import moment from 'moment'
import "./OrderOverview.css"
import ShipmentChart from "./ShipmentChart";

class OrderOverview extends Component {

    static renderOrderStatus(shipment) {
        if (shipment) {
            if (shipment.status === "failed") {
                return <span className="badge red"><FontAwesomeIcon icon="exclamation-triangle"/> Failed</span>
            } else if (shipment.status === "aborted") {
                return <span className="badge grey"><FontAwesomeIcon icon="times"/> Aborted</span>
            } else if (shipment.hanging) {
                return <span className="badge orange"><FontAwesomeIcon icon="clock"/> Hanging</span>
            }
        }
    }

    static panelClassnames(failed, hanging, inactive) {
        let classnames = "orderOverview";
        if (failed) {
            classnames = classnames + " failed"
        }
        if (hanging) {
            classnames = classnames + " hanging"
        }
        if (inactive) {
            classnames = classnames + " inactive"
        }
        return classnames;
    }

    render() {
        const {design, showOnlyRecurring, showOnlyActive} = this.props;
        const shouldRender = design.order && design.order.data() && (design.order.data().active || !showOnlyActive);
        if (!shouldRender) {
            return null
        }
        const order = design.order.data();
        const isRecurring = Object.keys(order.schedule).length !== 0 || order.triggerType === "pubsub";
        if (showOnlyRecurring && !isRecurring) {
            return null
        }
        const lastShipment = order.shipments[0];
        let hasNextShipment = true;

        if (!order.schedule.nextShipment || moment(order.schedule.nextShipment).isBefore(moment.now()) || !isRecurring) {
            hasNextShipment = false;
        }

        return <Panel
            className={OrderOverview.panelClassnames(lastShipment && lastShipment.status === "failed", lastShipment && lastShipment.hanging, order && !order.active)}>
            <header>
                {isRecurring && <span className="badge purple"><FontAwesomeIcon icon="redo-alt"/>Recurring</span>}
                {order && order.active && <span className="badge green"><FontAwesomeIcon icon="play"/>Active</span>}
                {OrderOverview.renderOrderStatus(lastShipment)}
            </header>
            <h3>
                <NavLink to={`/orders/${order.id}`}>
                    {design.name}
                </NavLink>
            </h3>
            <div className="orderDetails">
                    <ShipmentChart key={order.id} shipments={order.shipments}/>
            </div>
            <footer>
                <dl>
                    {!!lastShipment && lastShipment.completed && (
                        <span><dt>Last shipment</dt><dd>{moment(lastShipment.completed).fromNow()}</dd></span>)}
                </dl>
                <dl>
                    {isRecurring && hasNextShipment && (
                        <span><dt>Next shipment</dt><dd> {moment(order.schedule.nextShipment).fromNow()}</dd></span>)}
                </dl>
                <NavLink to={`/orders/${order.id}`}>
                    <FontAwesomeIcon icon="file-alt"/> Details
                </NavLink>
            </footer>

        </Panel>
    }
}

export default OrderOverview;
