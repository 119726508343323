import React from 'react'

import Firestore, {loading} from "../../components/FirestoreContainer";
import Spinner from "../../components/spinner/Spinner";
import QueryWizard from "./QueryWizard";
import {useParams, useLocation} from "react-router-dom";

const qs = require('querystring');

const QueryWizardContainer = () => {
    const {tableRef} = useParams()();
    const location = useLocation()();
    const baseQueryname = qs.parse(location.search)["?baseQueryname"];
    return (
        <Firestore key={tableRef}
            queryMapFn={db => {
                return {
                    bqTable: db.collection("bq_table").doc(tableRef),
                };
            }}
            mutators={() => {
                return {};
            }}
        >
            <QueryWizardContainerWithoutFireBaseContext baseQueryname={baseQueryname}/>
        </Firestore>
    );
};

const QueryWizardContainerWithoutFireBaseContext = ({ bqTable, baseQueryname }) => {
    return (
        <section>
            {loading(bqTable) ? (
                <Spinner />
            ) : (
               <QueryWizard tableInfo={bqTable.data} baseQueryname={baseQueryname} />
            )}
        </section>
    );
};


export default QueryWizardContainer;

