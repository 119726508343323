import moment from "moment";

export function activeOrder(order) {
    let schedule = order.schedule;
    const nextShipmentInTheFuture =
        schedule && schedule.nextShipment && moment(schedule.nextShipment).isAfter(moment())
    const recentSample =
        (!schedule || !schedule.nextShipment)
        && order.shipments.filter(s => {
            return moment(`${s.completed}`).isAfter(moment().subtract(7, 'days'))
        }).length > 0
    return nextShipmentInTheFuture || recentSample
}
