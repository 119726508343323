import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {NavLink} from "react-router-dom"
const ViewControls = ({
    view,
    isEditor,
    archiving,
    setArchiving,
    archiveView,
    dearchiveView,
}) => {
    const disable = isEditor || archiving;
    return (
        <div className="buttons">
            <NavLink className="btn callToAction" to={`/views/create?cloneView=${view._id}`}><FontAwesomeIcon icon="clone"/> Clone</NavLink>
            {!view.archived && (
            <button
                disabled={view.archived && disable}
                className="danger callToAction"
                onClick={() => {
                    setArchiving(true);
                    archiveView().then(() => setArchiving(false));
                }}><FontAwesomeIcon icon="eye-slash"/> Archive
            </button>
            )}
            {!!view.archived && (
            <button
                disabled={!view.archived && disable}
                className="callToAction"
                onClick={() => {
                    setArchiving(true);
                    dearchiveView().then(() => setArchiving(false));
                }}>
                <FontAwesomeIcon icon="eye"/> Re-activate View
            </button>
            )}
        </div>
    );
};

export default ViewControls;
