import React, {Component} from 'react';
import "./ClientDashboard.css";
import {filter} from 'lodash';
import DocumentTitle from 'react-document-title'
import Panel from "../../components/panel/Panel";
import FirestoreContainer, {loading} from "../../components/FirestoreContainer";
import Spinner from "../../components/spinner/Spinner";
import TargetLinkList from "./TargetLinkList";
import {useParams} from "react-router-dom";
import ViewLinkList from "./ViewLinkList";
import OrderOverviewList from "./OrderOverviewList";
import OrderDesignList from "./OrderDesignList"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function ClientDashboard({claims}) {
    const {clientId} = useParams();

    return <FirestoreContainer key={clientId}
                               queryMapFn={db => {
                                   const clientRef = db.collection("clients")
                                       .doc(clientId);
                                   const orderDesigns = db.collection("orderdesign")
                                       .where("clientId", "==", clientRef);
                                   const views = db.collection("views")
                                       .where("client", "==", clientRef);
                                   const targets = db.collection("targets")
                                       .where("clientId", "==", clientRef);

                                   return {
                                       clientRef, orderDesigns, views, targets
                                   };
                               }}
                               mutators={db => {
                                   return {
                                       archiveClient: () =>
                                           db
                                               .collection("clients")
                                               .doc(clientId)
                                               .update("state", "archived"),
                                       reactivateClient: () =>
                                           db
                                               .collection("clients")
                                               .doc(clientId)
                                               .update("state", "active"),
                                   };
                               }}
                               defaultViewState={{
                                   settingState: false,
                               }}
                               viewStateMutators={setClientState => {
                                   return {
                                       setState: isSettingState =>
                                           setClientState({settingState: isSettingState}),
                                   };
                               }}
    >
        <ClientDashboardContent claims={claims} clientId={clientId}/>
    </FirestoreContainer>

}

class ClientDashboardContent extends Component {

    render() {
        let {clientRef, orderDesigns, views, targets, archiveClient, reactivateClient, setState, settingState} = this.props;
        if (loading(clientRef) || loading(orderDesigns)) {
            return <DocumentTitle title="Loading client information..."><Spinner>Loading client information...</Spinner></DocumentTitle>
        }

        const drafts = filter(orderDesigns.data, (design) => {
            return !design.orderRef
        });

        const client = clientRef.data[0];
        let archived = client.state === "archived";
        return (<DocumentTitle title={client.name}>
            <div className="clientDashboard">
                <section className="clientInfo">
                    <h1>{client.name}</h1>
                    {client.state === "archived" && <div className="archivedDetail">This client is archived</div>}
                </section>
                <section className="clientDetails">
                    <OrderOverviewList designs={orderDesigns}/>
                    <Panel className="orderComponents">
                        {archived &&
                        <div className="buttons left">
                            <button
                                disabled={!archived && settingState}
                                className="callToAction"
                                onClick={() => {
                                    setState(true);
                                    reactivateClient().then(() => setState(false));
                                }}><FontAwesomeIcon icon="play"/> Reactivate Client
                            </button>
                        </div>}

                        <OrderDesignList designs={drafts} clientId={client._id}/>
                        <ViewLinkList views={views}/>
                        <TargetLinkList targets={targets} clientId={client._id}/>

                        {!archived &&
                        <div className="buttons left">
                            <button
                                disabled={archived && settingState}
                                className="danger callToAction"
                                onClick={() => {
                                    setState(true);
                                    archiveClient().then(() => setState(false));
                                }}><FontAwesomeIcon icon="eye-slash"/> Archive Client
                            </button>
                        </div>}
                    </Panel>
                </section>
            </div>
        </DocumentTitle>)
    }

}

export default ClientDashboard;


