import React, {Component} from "react";
import {each} from "lodash";
import firebase from "firebase/app";
import "firebase/firestore";


class FirestoreContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            results: {},
            viewState: props.defaultViewState,
        };
    }

    componentDidMount() {
        this.subscribe(this.props)
    }

    componentWillUnmount() {
        this.unsubscribe();
    }

    subscribe(props) {
        const db = firebase.firestore();
        const queryMap = props.queryMapFn(db, this.state.viewState);

        let results = {};
        each(queryMap, (query, key) => {
            results[key] = {
                loading: true,
                docs: null,
            };

            results[key].unsubscribe = query.onSnapshot(snapshot => {
                const docs = !snapshot.docs ? [snapshot] : snapshot.docs;
                this.setState({
                    results: {
                        ...this.state.results,
                        [key]: {
                            ...this.state.results[key],
                            loading: false,
                            data: docs.map(doc => {
                                return doc.exists
                                    ? Object.assign(doc.data(), {
                                        _ref: doc.ref,
                                        _id: doc.id,
                                    })
                                    : null;
                            }),
                        },
                    },
                });
            });
            query.get().catch(err => {
                console.log(err);
            });
        });

        if (this.props.mutators) this.mutators = this.props.mutators(db)
        if (this.props.viewStateMutators)
            this.viewStateMutators = this.props.viewStateMutators(
                newPartialViewState => {
                    this.setState({
                        viewState: Object.assign(
                            this.state.viewState,
                            newPartialViewState
                        ),
                    });
                }
            );

        this.setState({
            results,
        });
    }

    unsubscribe() {
        each(this.state.results, res => res.unsubscribe());
    }

    render() {
        React.Children.only(this.props.children);
        return React.Children.map(this.props.children, child => {
            return React.cloneElement(child, {
                ...this.state.results,
                ...this.props,
                ...this.mutators,
                ...this.state.viewState,
                ...this.viewStateMutators,
            })
        });
    }
}

export function loading(prop) {
    return !prop || prop.loading
}

export const removeRefandId = (toSave) => {
    const { _ref, _id, ...newObject } = toSave;
    return newObject;
}

export default FirestoreContainer;
