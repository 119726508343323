import React, {Component} from "react";
import { format } from 'sql-formatter';
import {NavLink} from "react-router-dom"

import {DropdownList} from "react-widgets";
import Spinner from "../spinner/Spinner";
import {capitalize, isEmpty, map, omitBy} from "lodash";
import "./CreateView.css";
import QueryValidator from "./QueryValidator";
import Collapsible from "../collapsible/Collapsible";
import Panel from "../panel/Panel";
import DisplayIf from "../displayif/DisplayIf";
import flatMap from 'array.prototype.flatmap';
import {loading} from "../FirestoreContainer";

// Expected names for some of the columns needed in a query to be able
// to run snapshot and device report queries on the result
// Try to keep in sync with a similar object found in order-design.js
const defaultViewColumns = {
    eventTimeColumn: "timestamp",
    eventTimeColumnFormat: "TIMESTAMP",
    identifierColumn: "identifier",
    ipAddressColumn: "ip",
    accuracyColumn: "ha",
    latitudeColumn: "venue_lat",
    countryColumn: "country"
};

const cleanState = {
    working: false,
    valid: false,
    schemaFields: {},
    newView: {
        name: "",
        extractFrom: "bigquery",
        description: "",
        query: "",
        client: undefined,
        dataset: null,
        archived: false,
        createDeviceReport: false,
        eventTimeColumn: defaultViewColumns.eventTimeColumn,
        eventTimeColumnFormat: defaultViewColumns.eventTimeColumnFormat,
        identifierColumn: defaultViewColumns.identifierColumn,
        ipAddressColumn: "",
        accuracyColumn: "",
        latitudeColumn: "",
        countryColumn: "",
        countryShort: "", // TODO: add a way to choose this in the UI
        nonStandardIdentifierColumn: false,
        disableReports: false,
        useComplexFields: false
    },
};

export const timestampFormats = ["TIMESTAMP", "UNIX_MILLIS", "UNIX_SECONDS"];

class CreateView extends Component {
    constructor(props) {
        const {viewToClone, datasets, clients} = props
        super(props);
        this.state = cleanState;
        if (!!viewToClone) {
            for (let fieldName in cleanState.newView) {
                if (cleanState.newView.hasOwnProperty(fieldName)) {
                    if (typeof viewToClone[fieldName] === "undefined") {
                        if (this.state.newView[fieldName] !== "") {
                            this.state.newView[fieldName] = null;
                        }
                    } else {
                        this.state.newView[fieldName] = viewToClone[fieldName];
                    }
                }
            }
            const cloneClient = viewToClone.client ? clients.data.find(c => c._id === viewToClone.client.id) : null;
            const cloneDataset = datasets.data.find(c => c._id === viewToClone.dataset.id);
            this.state.newView = {
                ...this.state.newView,
                client: cloneClient,
                dataset: cloneDataset,
                name: "",
                cloneOf: viewToClone._ref,
                archived: false
            };
        }
    }

    onFormChange(name, value) {
        let changes = {};
        changes[name] = value;
        this.onFormChanges(changes)
    }

    onFormChanges(changes) {
        this.setState({
            valid: this.state.valid && !Object.keys(changes).includes("query"),
            newView: {
                ...this.state.newView,
                ...changes,
            },
        });
    }

    onDeviceReportFieldChange(target) {
        const name = target.name;
        const value = target.value;
        const missmatchingField = this.viewAndQueryColumnMissmatch(value);
        if (target.style) {
            if (missmatchingField) {
                target.style.borderColor = target.required ? 'var(--theme-color-error)' : 'var(--theme-color-warning)';
            } else {
                target.style.borderColor = 'var(--theme-border-color-input)';
            }
        }
        const updatedNewView = {
            ...this.state.newView,
            [name]: value,
        };

        const validTimeColumnFormat = this.eventTimeColumnFormatValid(updatedNewView.eventTimeColumnFormat, updatedNewView.eventTimeColumn);
        const valid = this.findMissmatchedQueryColumns(updatedNewView).length === 0
            && validTimeColumnFormat;

        this.setState({
            valid: valid,
            newView: updatedNewView,
        });
    }

    schemaFieldsContains(column) {
        return this.state.schemaFields.hasOwnProperty(column)
    }

    viewAndQueryColumnMissmatch(column) {
        return !this.schemaFieldsContains(column)
    }

    findMissmatchedQueryColumns(updatedNewView) {
        let newView = this.state.newView;
        if (updatedNewView) {
            newView = updatedNewView
        }
        let viewColumnDefinitions = [newView.identifierColumn];
        if (newView.createDeviceReport) {
            viewColumnDefinitions.push(
                newView.eventTimeColumn,
                newView.ipAddressColumn,
                newView.accuracyColumn,
                newView.latitudeColumn,
                newView.countryColumn
            );
        }
        return viewColumnDefinitions.filter((viewCol) => {
            return !this.schemaFieldsContains(viewCol) && viewCol.length > 0;
        });
    }

    eventTimeColumnFormatValid(format, eventTimeColumn) {
        let valid = false;
        if (this.state.schemaFields[eventTimeColumn] === 'TIMESTAMP') {
            valid = format === 'TIMESTAMP'
        } else if (this.state.schemaFields[eventTimeColumn] === 'INTEGER') {
            valid = ["UNIX_MILLIS", "UNIX_SECONDS"].includes(format);
        }
        return valid
    };

    validateQueryColumns(createDeviceReportChecked, extraViewChanges) {
        const missmatchedQueryColumns = this.findMissmatchedQueryColumns();

        let updatedNewView = {
            ...this.state.newView,
            ...extraViewChanges,
        };
        //If schema contains a column that hasn't been set in newView
        map(defaultViewColumns, (value, key) => {
            if (updatedNewView[key] === "" && this.schemaFieldsContains(defaultViewColumns[key])) {
                updatedNewView[key] = defaultViewColumns[key]
            }
        });

        let valid = true;
        if (createDeviceReportChecked && missmatchedQueryColumns) {
            if (missmatchedQueryColumns.includes(this.state.newView.identifierColumn)) {
                valid = false;
                updatedNewView.nonStandardIdentifierColumn = missmatchedQueryColumns.includes(this.state.newView.identifierColumn)
            }
        }
        this.setState({
            valid: valid,
            newView: updatedNewView,
        });
    }

    renderMissmatchedDeviceReportColumns(newView) {
        const onDeviceReportChange = e => this.onDeviceReportFieldChange(e.target);

        const missmatchedColumns = omitBy(defaultViewColumns, (value) => {
            return value === defaultViewColumns.identifierColumn
                || value === defaultViewColumns.eventTimeColumnFormat
                || !this.viewAndQueryColumnMissmatch(value)
        });

        if (this.state.newView.cloneOf) {
            map(missmatchedColumns, (value, key) => {
                if (this.state.newView[key] !== "") {
                    missmatchedColumns[key] = this.state.newView[key]
                }
                return null;
            })
        }

        const unCamelCase = (str) => {
            str = str.replace(/([a-z\xE0-\xFF])([A-Z\xC0\xDF])/g, '$1 $2');
            str = str.toLowerCase(); //add space between camelCase text
            return capitalize(str);
        };

        let missmatchedColumnSpans = map(missmatchedColumns, (value, key) => {
            const requiredField = ["eventTimeColumn"].includes(key);
            let color = 'var(--theme-border-color-input)';
            if (!this.schemaFieldsContains(value)) {
               color = requiredField ? 'var(--theme-color-error)' : 'var(--theme-color-warning)';
            }

            return (
                <span className="field" key={key}>
                    <label htmlFor={key}>{unCamelCase(key)}</label>
                    <div>
                        <input
                            style={{borderColor: color}}
                            name={key}
                            required={requiredField}
                            placeholder={value}
                            onChange={onDeviceReportChange}
                            value={newView[key]}
                        />
                    </div>
                </span>
            )
        });

        if (!isEmpty(this.state.schemaFields) && missmatchedColumnSpans.length > 0) {
            return (
                <fieldset className="subFields">
                    <h2>Device report</h2>
                    <br/>
                    <span className="field" key="eventTimeColumnFormat">
                    <label htmlFor="eventTimeColumnFormat">Event time format</label>
                    <DropdownList
                        id="eventTimeColumnFormat"
                        data={timestampFormats}
                        value={newView.eventTimeColumnFormat}
                        onChange={value => onDeviceReportChange({target: {name: "eventTimeColumnFormat", value}})
                        }/>
                    </span>

                    <span className="fieldComment">
                        The columns listed below is either missing from the query or called something else than the defaults.
                        Please add them to the query or enter their alias(es) here, <strong>or</strong> if your query doesn't contain them
                        leave the fields empty.
                    </span>
                    <br/>
                    {missmatchedColumnSpans}
                </fieldset>
            )
        }
    }

    validateBeforeSubmitting() {
        const newView = this.state.newView;
        if (!newView.dataset) {
            alert("Please choose a dataset before saving");
            return false;
        }
        if (!newView.client) {
            alert("Please choose a client (or 'All') for that should have access to this view.");
            return false;
        }

        return !((this.state.newView.disableReports || !this.state.newView.createDeviceReport) &&
            !window.confirm(`You have chosen to either disable reporting completely or to disable the the device report! Are you sure you want to create this view?`));

    }

    onSubmit(e) {
        e.preventDefault();

        if (!this.validateBeforeSubmitting()) {
            return;
        }

        this.setState({
            working: true,
        });

        let toSave = Object.assign({}, this.state.newView);
        toSave = Object.assign(toSave, {
            dataset: this.state.newView.dataset._ref,
            client: this.state.newView.client._ref,
        });
        this.props.addView(toSave);
    }

    query() {
        let query = this.state.newView.query;
        if (this.state.newView.dataset) {
            query = (this.state.newView.dataset.baseQuery || "") + "\n" + query;
        }
        return query
    }

    static fetchBaseQueryName(query) {
        let re = /with ([a-zA-Z]+) as/;
        let queryName = query.toLowerCase().match(re);
        return queryName[1];
    }

    static unnestRecord(name, field) {
        return flatMap(field.fields, f => {
            let newName = `${name}.${f.name}`;
            if (f.type === "RECORD") {
                return this.unnestRecord(newName, f);
            } else {
                return {name: newName, type: f.type}
            }
        })
    }

    render() {
        const {newView, working, hasComplexFields} = this.state;
        const {datasets, clients, viewToClone} = this.props;
        const activeDatasets = datasets.data.filter(dataset => !(dataset.state === "archived"));
        const onChange = e => this.onFormChange(e.target.name, e.target.value);
        const onDeviceReportChange = e => this.onDeviceReportFieldChange(e.target);

        if (working) {
            return <div className="createView"><Spinner/></div>;
        }

        let clientList = clients.data.sort((a, b) => {
            const nameA = a.name.toUpperCase(); // ignore upper and lowercase
            const nameB = b.name.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            // names must be equal
            return 0;
        });
        return (
            <div className="createView">
                <DisplayIf condition={!!viewToClone}>
                    <Panel>
                        Creating clone of <NavLink to={`/views/${viewToClone._id}`}>{viewToClone.name}</NavLink>
                    </Panel>
                </DisplayIf>
                <form onSubmit={e => this.onSubmit(e)}>
                    <h2>View Properties</h2>
                    <span className="field">
                        <label htmlFor="clients">Client</label>
                        <div>
                            {!loading(clients) && (
                                <DropdownList
                                    name="clients"
                                    data={[{name: "All", _ref: null}].concat(clientList)}
                                    placeholder={"Choose which clients can use this view"}
                                    value={newView.client}
                                    inputProps={{required: true}}
                                    onChange={client =>
                                        this.onFormChange("client", client)
                                    }
                                    textField="name"
                                    valueField="_id"
                                />
                            )}
                        </div>
                    </span>

                    <span className="field">
                        <label htmlFor={"name"}>Name</label>
                        <div>
                            <input
                                name="name"
                                placeholder={(!!viewToClone && `Clone of ${viewToClone.name}`) || "Short name"}
                                onChange={onChange}
                                value={newView.name}
                                required={true}
                            />
                        </div>
                    </span>
                    <span className="field">
                        <label htmlFor={"description"}>{"Description"}</label>
                        <div>
                            <textarea
                                cols={60}
                                rows={10}
                                name={"description"}
                                placeholder={
                                    "Description of view intended for sales reps."
                                }
                                onChange={onChange}
                                value={newView.description}
                                required={true}
                            />
                        </div>
                    </span>

                    <span className="field">
                        <label>Dataset</label>
                        {!loading(datasets) && (
                            <div>
                                <DropdownList
                                    name="datasets"
                                    data={activeDatasets}
                                    placeholder={"Pick Dataset"}
                                    value={newView.dataset}
                                    inputProps={{required: true}}
                                    onChange={dataset =>
                                        this.onFormChange("dataset", dataset)
                                    }
                                    textField="name"
                                    valueField="_id"
                                />

                            </div>
                        )}
                    </span>

                    {!!newView.dataset && newView.dataset.baseQuery && (<span>
                        <span className="field">
                            <label>BaseQuery</label>
                            <code className="sql baseQuery">{format(newView.dataset.baseQuery)}</code>
                        </span>
                    </span>)}
                    <span className="field">
                        <label htmlFor={"query"}>{"Query"}</label>
                        <div>
                            <textarea
                                cols={60}
                                rows={10}
                                name={"query"}
                                placeholder={"Bigquery Standard-SQL"}
                                onChange={onChange}
                                value={newView.query}
                                required={true}
                                className="sql"
                            />
                            <p>
                                BigQuery Standard-SQL Query to be executed upon
                                order execution. To use Legacy-SQL instead, add <strong>#legacySQL</strong> at the start.
                            </p>
                        </div>
                    </span>
                    {newView.dataset && newView.dataset.tablename &&
                    <span className="field text-right">
                        <NavLink
                            to={`/querywizard/${newView.dataset.tablename.id}${"?baseQueryname=" + CreateView.fetchBaseQueryName(newView.dataset.baseQuery)}`}
                            target="_blank">
                            <strong>Try the QueryWizard!</strong>
                        </NavLink>
                    </span>}

                    <QueryValidator
                        query={this.query.bind(this)()}
                        progressType={newView.dataset ? newView.dataset.progressType : "none"}
                        onSuccess={(results) => {
                            this.setState({
                                valid: true,
                                schemaFields: results.query.schema ? results.query.schema.fields.reduce((acc, field) => {
                                    if (field.type === "RECORD") {
                                        const fields = CreateView.unnestRecord(field.name, field);
                                        fields.forEach(f => {
                                            acc[f.name] = f.type
                                        });
                                    } else {
                                        acc[field.name] = field.type;
                                    }
                                    return acc;
                                }, {}) : {},
                                hasComplexFields: results.hasComplexFields
                            });
                            this.validateQueryColumns(this.state.newView.createDeviceReport);
                        }}
                        key={this.query.bind(this)()}
                        validateBlacklistWhereClause
                    />

                    <h2>Reporting</h2>
                    <p>
                        These values are used when creating the various reports needed for billing, analysis, etc.
                    </p>
                    <span className="field">
                        <label htmlFor="disableReports">Disable all reports</label>
                        <div>
                            <input type="checkbox" name="disableReports" id="disableReports"
                                   onChange={e => this.onFormChange("disableReports", e.target.checked)}
                                   checked={newView.disableReports}/>
                           <span className="fieldComment">
                                Please don't do this if you're sure it's absolutely necessary!
                            </span>
                        </div>
                    </span>

                    <Collapsible open={!newView.disableReports}>
                        <DisplayIf
                            condition={!isEmpty(this.state.schemaFields) && this.viewAndQueryColumnMissmatch(defaultViewColumns.identifierColumn)}>
                            <span className="field">
                            <fieldset className="subFields">
                                <span className="field">
                                    <label htmlFor={"identifierColumn"}>Identifier column</label>
                                    <div>
                                        <span className="fieldComment">
                                            Seems like the identifier column has a custom name, please specify what it is
                                        </span>
                                        <input
                                            style={{borderColor: this.schemaFieldsContains(newView.identifierColumn) ? 'var(--theme-border-color-input)' : 'var(--theme-color-error)'}}
                                            name="identifierColumn"
                                            placeholder="identifier"
                                            onChange={onDeviceReportChange}
                                            value={newView.identifierColumn}
                                            required={newView.createDeviceReport}
                                        />
                                    </div>
                                </span>
                            </fieldset>
                            </span>
                        </DisplayIf>

                        <span className="field">
                            <label htmlFor="createDeviceReport">Create device report</label>
                            <div>
                                <input type="checkbox" name="createDeviceReport" id="createDeviceReport"
                                       onChange={e => {
                                           !isEmpty(this.state.schemaFields) && this.validateQueryColumns(e.target.checked, {createDeviceReport: e.target.checked});
                                       }}
                                       checked={newView.createDeviceReport}/>
                                <span className="fieldComment">
                                    The device report is a list of unique IDs created for each shipment.
                                    The report is used to calculate DAUs and MAUs and is usually required for billing purposes.
                                </span>
                            </div>
                        </span>
                        <span className="field">
                            <Collapsible open={newView.createDeviceReport}
                                         className={newView.createDeviceReport ? "open" : ""}>
                                {this.renderMissmatchedDeviceReportColumns(newView)}
                            </Collapsible>
                        </span>
                    </Collapsible>

                    <DisplayIf condition={newView.disableReports || !newView.createDeviceReport}>
                        <Panel className="error"><strong>NB!</strong> If you disable the device report we might not be
                            able to correctly bill the clients for orders using this view!</Panel>
                    </DisplayIf>

                    <DisplayIf condition={hasComplexFields}>
                        <span className="field" style={{border: "solid 2px var(--theme-color-error)"}}>
                            <label htmlFor="useComplexFields">Use complex fields</label>
                            <div>
                                <input type="checkbox" name="useComplexFields" id="useComplexFields"
                                    onChange={e => this.setState({ useComplexFields: e.target.checked})} />
                                <span className="fieldComment">
                                    Using a query that has complex fields like array or struct in the output can't be exported using a row based format like CSV, TSV or PSV. <strong>Are you sure you want to create the view containing the complex fields? Check the checkbox if you want to continue.</strong>
                                </span>
                            </div>
                        </span>
                    </DisplayIf>
                    <div className="buttons">
                        <input
                            className="callToAction"
                            type="submit"
                            value={"Create"}
                            disabled={!this.state.valid || (this.state.hasComplexFields && !this.state.useComplexFields)}
                        />
                    </div>
                </form>
            </div>
        );
    }
}

export default CreateView;
