import Collapsible from "../../collapsible/Collapsible";
import {DropdownList} from "react-widgets";
import DisplayIf from "../../displayif/DisplayIf";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {Component} from "react";


class ShowAdvancedFromPartial extends Component {

    constructor() {
        super();
        this.state = {
            showAdvanced: false
        }
    }

    render() {
        const {newOrder, setField, onChange} = this.props;

        return (<span><Collapsible open={this.state.showAdvanced}>
                <fieldset className="subFields">
                <span className="field">
                    <label htmlFor="fileFormat">File format</label>
                    <DropdownList
                        id="fileFormat"
                        data={["CSV", "TSV", "PSV", "NDJSON", "AVRO", "PARQUET"]}
                        value={newOrder.fileFormat}
                        onChange={(v) => {
                            setField("fileFormat", v)}
                        }
                    />
                </span>

                    <span className="field">
                        <label htmlFor="noCompression">Uncompressed</label>
                        <input type="checkbox" name="noCompression" id="noCompression" onChange={onChange}
                        checked={newOrder.noCompression}
                        />
                    <span className="fieldComment">
                        By checking this box, data will be sent without compression. <br/>
                        <strong>NOTE:</strong> This increases Unacast's cost of serving the order by about 3x.
                    </span>
                    </span>

                    <span className="field">
                        <label htmlFor="singleFile">Force single file</label>
                        <input type="checkbox" name="singleFile" id="singleFile" onChange={onChange}
                               checked={newOrder.singleFile}
                        />
                    <span className="fieldComment">
                        Forces all data to be placed in a single file instead of being split up over many files.<br/>
                        <strong>NOTE:</strong> If the data takes up more than 1GBi, the order will fail if this option is turned on.
                    </span>
                    </span>
                    <span className="field">
                        <label htmlFor="noPrintHeader">No Header</label>
                        <input type="checkbox" name="noPrintHeader" id="noPrintHeader" onChange={onChange}
                               checked={newOrder.noPrintHeader}
                        />
                    <span className="fieldComment">
                        By checking this box, the header will be left out. <br/>
                    </span>
                    </span>
                </fieldset>
            </Collapsible>
            <DisplayIf condition={!this.state.showAdvanced}>
    <button type="button" onClick={() => this.setState({showAdvanced: true})}>
    <FontAwesomeIcon icon="chevron-down"/> Show advanced
        </button>
    </DisplayIf>
    <DisplayIf condition={this.state.showAdvanced}>
    <button type="button" onClick={() => this.setState({showAdvanced: false})}>
    <FontAwesomeIcon icon="chevron-up"/> Hide advanced
        </button>
    </DisplayIf></span>)
    }
}

export default ShowAdvancedFromPartial;
