import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import {filter,sortBy} from 'lodash';
import Spinner from "../../components/spinner/Spinner";
import {loading} from "../../components/FirestoreContainer";

class TargetLinkList extends Component {
    render() {
        const {targets, clientId} = this.props;
        if (loading(targets)) {
            return <Spinner>Loading targets...</Spinner>
        }
        const activeTarget = sortBy(
            filter(targets.data, (t) => !t.archived),
            (t) => t.name.toLowerCase()
        )
        return <section>
            <h2>
                Targets
                <NavLink to={`/clients/${clientId}/targets/create`}
                         className="btn callToAction addButton">+</NavLink>
            </h2>
            <ul className="componentList">
                {activeTarget.map(t => <li key={t._id}>
                    <NavLink to={`/targets/${t._id}`}>{t.name}</NavLink>
                    </li>)}
            </ul>
            {activeTarget.length === 0 && <span>No existing targets</span>}
        </section>
    }

}

export default TargetLinkList;
