import React, { Component } from "react";
import "./Targets.css";
import "./CreateTarget";
import TargetsList from "./TargetsList";
import CreateTarget from "./CreateTarget";

class Targets extends Component {
    render() {
        return (
            <div className="targets">
                <CreateTarget
                    credentials={this.props.credentials}
                    client={this.props.client}
                    addTarget={this.props.addTarget}
                />
                <div className="existingTargets">
                    <TargetsList targets={this.props.targets} isEditor={true} />
                </div>
            </div>
        );
    }
}

export default Targets;
