import React, {Component} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NavLink } from "react-router-dom";
import PropTypes from 'prop-types';
import DisplayIf from "../displayif/DisplayIf";

class ReplaceOrderButton extends Component {
    render() {
        const {order, design, lastShipmentNotComplete} = this.props;
        const clientId = design.clientId.id;
        let isRecurring = !!design.schedule || design.triggerType === "pubsub";
        return (
            <DisplayIf condition={!order.replacedBy && isRecurring && !lastShipmentNotComplete}>
                <NavLink className="btn" to={`/clients/${clientId}/orders/create?replace=${order.id}&cloneDesign=${design._id}`} >
                    <FontAwesomeIcon icon="clone"/> Replace order
                </NavLink>
            </DisplayIf>
        )
    }
}

ReplaceOrderButton.defaultProps = {
    order: PropTypes.any.isRequired,
    design: PropTypes.any.isRequired,
};

export default ReplaceOrderButton;
