import React from "react";
import Spinner from "../spinner/Spinner";
import "./ShipmentSpec.css";

const ShipmentSpec = ({ spec }) => {
    return (
        <div className="shipmentSpec">
            <h5>Shipment Specification:</h5>
            <pre className="">
                {spec && JSON.stringify(spec, null, 4)}
                {!spec && <Spinner />}
            </pre>
        </div>
    );
};

export default ShipmentSpec;
