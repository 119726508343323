import React, {Component} from 'react';
import {sortBy} from 'lodash'
import {NavLink} from "react-router-dom"

class OrderDesignList extends Component {
    render() {
        const {designs, clientId} = this.props;
        const designlist = sortBy(designs,
            (d) => d.name.toLowerCase()
        );
        return <section>
            <h2>
                Orders
                <NavLink to={`/clients/${clientId}/orders/create`} className="btn callToAction addButton">+</NavLink>
            </h2>
            <ul className="componentList">
                {designlist.map(d => <li key={d._id}>
                    <NavLink to={`/orderdesigns/${d._id}/review`}>{d.name}</NavLink>
                </li>)}
            </ul>
            {designlist.length === 0 && <span>No designs</span>}

        </section>
    }

}

export default OrderDesignList;
