import {Component} from 'react';
import cronstrue from "cronstrue/dist/cronstrue";

function humanize(cronExpression) {
    var numTokens = cronExpression && cronExpression.split(" ").filter(t => t !== "").length;
    if (!cronExpression || numTokens !== 7) {
        return null;
    }
    let description = "";
    try {
        description = cronstrue.toString(cronExpression, {throwExceptionOnParseError: true});
    } catch (e) {
        description = "invalid: "+e;
    }

    return description;
}

class CronExplained extends Component {
    render() {
        return humanize(this.props.expression)
    }

}

export default CronExplained;
