import React, {Component} from "react";
import "../targets/Targets.css";
import {createBucket} from "../targets/target-methods";

import {DropdownList} from "react-widgets";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

class CreateBucket extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newBucket: {
                accessKey: "",
                secretKey: "",
                bucket: "",
                region: "",
                acl: "",
                partner: "",
                direction: "",
            },
            validated: false,
            validatedBucket: undefined,
            validationRunning: false,
            allowInvalidBucket: false,
            creationRunning: false,
            openPolicyList: false
        };
    }

    formChanged(e) {
        this.setFormValue(e.target.name, e.target.value)
    }

    saveInvalidatedToggled(e) {
        this.setState({allowInvalidBucket: e.target.checked})
    }

    setFormValue(key, val) {
        this.setState({newBucket: {
                ...this.state.newBucket,
                [key]: val,
            }
        });
    }

    regionChosen(region) {
        this.setFormValue('region', region)
    }

    directionChosen(direction) {
        this.setFormValue('direction', direction)
    }

    createBucket() {
        const bucket = this.state.newBucket;

        if (bucket.direction === "import" && !bucket.bucket.includes("uc-import")) {
            alert("Bucketname must contain \"uc-import\" if direction is import");
        } else {
            const bucketData = {
                name: bucket.bucket,
                region: bucket.region,
                direction: bucket.direction,
                partner: bucket.partner
            };

            this.setState({creationRunning: true});
            createBucket(bucketData).then(res => {

                console.log(res);
                this.setState({
                    newBucket : {
                        ...this.state.newBucket,
                        secretKey: res.secretkey,
                        accessKey: res.accesskey,
                        region: res.region,
                    },
                    bucketCreated: res.accesskey !== "",
                    creationRunning: false,
                    bucketCreationErrorMsg: null
                });

            }).catch(err => {
                this.setState({
                    creationRunning: false,
                    bucketCreationErrorMsg: err.text
                });
            });
        }
    }

    togglePolicyList() {
        this.setState({
            openPolicyList: !this.state.openPolicyList
        })
    }

    copyCredentialsToClipboard() {
       let newBucket = this.state.newBucket;
       let clipText = `Bucket: ${newBucket.bucket}
        Region: ${newBucket.region}
        AccessKey: ${newBucket.accessKey}
        SecretKey: ${newBucket.secretKey}`;
        navigator.clipboard.writeText(clipText)
            .then(() => this.setState({copiedToClipboard: true}))
            .catch(err => console.log(`Could not copy, error: ${err}`));
    }

    render() {
        const onChange = this.formChanged.bind(this);

        return (
            <section className="createTarget">
                <h3>Create Bucket</h3>
                    <div className="fieldset">
                        <span className="field">
                            <label htmlFor={"bucket"}>Bucket</label>
                            <input
                                name={"bucket"}
                                placeholder={"Bucket"}
                                onChange={onChange}
                                value={this.state.newBucket.bucket}
                                required={true}
                            />
                        </span>
                        <span className="field">
                            <label htmlFor={"region"}>Region</label>
                            <DropdownList
                                name="region"
                                placeholder={"Select region"}
                                value={this.state.newBucket.region}
                                data={[
                                    "us-east-2",
                                    "us-east-1",
                                    "us-west-1",
                                    "us-west-2",
                                    "ap-south-1",
                                    "ap-northeast-3",
                                    "ap-northeast-2",
                                    "ap-southeast-1",
                                    "ap-southeast-2",
                                    "ap-northeast-1",
                                    "ca-central-1",
                                    "cn-north-1",
                                    "cn-northwest-1",
                                    "eu-central-1",
                                    "eu-west-1",
                                    "eu-west-2",
                                    "eu-west-3",
                                    "eu-north-1",
                                    "sa-east-1",
                                    "us-gov-east-1",
                                    "us-gov-west-1"
                                ]}
                                inputProps={{required: true}}
                                onChange={this.regionChosen.bind(this)}
                            />
                        </span>
                        <span className="field">
                            <label htmlFor={"direction"}>Direction</label>
                            <DropdownList
                                name="direction"
                                placeholder={"Select direction"}
                                value={this.state.newBucket.direction}
                                data={[
                                    "export",
                                    "import"
                                ]}
                                inputProps={{required: true}}
                                onChange={this.directionChosen.bind(this)}
                            />
                        </span>
                            <span className="fieldset">
                                <span className="field">
                                    <label htmlFor={"partner"}>Partner/client</label>
                                    <div>
                                        <span className="fieldComment">
                                            Must contain only alphanumeric characters and/or the following: +=,.@_-
                                        </span>
                                        <input
                                            name={"partner"}
                                            placeholder={"Name of partner/client"}
                                            onChange={onChange}
                                            onInput={e => {
                                                e.target.value = e.target.value.replace(/[^0-9a-zA-Z+=,.@_-]/g, "")
                                            }}
                                            value={this.state.newBucket.partner}
                                        />
                                    </div>
                                </span>
                                <span className="buttons">
                                <button type="button"
                                        onClick={this.createBucket.bind(this)}
                                        disabled={this.state.creationRunning}>Create Bucket</button>
                                       <CreateBucketStatus bucketCreated={this.state.bucketCreated}
                                                           error={this.state.bucketCreationErrorMsg}
                                                           creationRunning={this.state.creationRunning}
                                       />
                                </span>
                                {this.state.bucketCreated && (
                                   <div>
                                       <div className="targetDescription">
                                            <h4>A new bucket was created:</h4>
                                            <dl>
                                                <dt>Bucket</dt>
                                                <dd>{this.state.newBucket.bucket}</dd>
                                                <dt>Access Key</dt>
                                                <dd>{this.state.newBucket.accessKey}</dd>
                                                <dt>Secret Key</dt>
                                                <dd>{this.state.newBucket.secretKey}</dd>
                                                <dt>Region</dt>
                                                <dd>{this.state.newBucket.region}</dd>
                                            </dl>
                                        </div>
                                       <button onClick={this.copyCredentialsToClipboard.bind(this)}>
                                           <FontAwesomeIcon
                                               icon="copy"/> {this.state.copiedToClipboard ? "Copied!" : "Copy info to clipboard"}
                                       </button>
                                   </div>)}
                            </span>
                    </div>
            </section>
        );
    }
}

const CreateBucketStatus = ({bucketCreated, error, creationRunning}) => {
    if (creationRunning) {
        return <div className="panel"><FontAwesomeIcon icon="spinner" pulse={true}/><p>Creating bucket...</p></div>;
    }
    if (bucketCreated) {
        return <div className="panel"><FontAwesomeIcon icon="thumbs-up"/> <p>A new bucket has been created!</p></div>
    }
    if (error != null) {
        return <div className="panel error"><FontAwesomeIcon icon="thumbs-down"/> <p>An error occurred: {error}</p>
        </div>
    }
    return null;
};


export default CreateBucket;
