import React, {Component} from 'react';

class Collapsible extends Component {

    render() {
        const classes = (this.props.className || "").split(" ");
        classes.push("collapsible");

        if(!this.props.open){
            classes.push("collapsed");
        }

        const props = {
            ...this.props,
            className: classes.join(" ")
        };
        return React.createElement(this.props.containerType || "div", props, this.props.children)
    }

}

export default Collapsible;
