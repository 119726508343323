import React, { Component } from "react";
import { Link } from "react-router-dom";
import Spinner from "../spinner/Spinner";
import DisplayIf from "../displayif/DisplayIf";

class Order extends Component {
    constructor(props) {
        super(props);
        this.state = {
            view: null,
            target: null,
            client: null,
        };
    }

    componentDidMount() {
        const orderdesign = this.props.orderdesign;
        orderdesign.view.get().then(doc => this.setState({ view: doc.data() }));
        orderdesign.target
            .get()
            .then(doc => this.setState({ target: doc.data() }));
        orderdesign.clientId.get().then(doc => {
            this.setState({ client: { ...doc.data(), _id: doc.id } });
        });
    }

    isLoading() {
        return (
            this.state.view === null ||
            this.state.target === null ||
            this.state.client === null
        );
    }

    render() {
        const orderdesign = this.props.orderdesign;
        const { target, view, client } = this.state;
        return (
            <div className="order consoleTile">
                <h3>{orderdesign.name}</h3>
                {this.isLoading() ? (
                    <Spinner>Loading order design...</Spinner>
                ) : (
                    <dl>
                        <dt>Client</dt>
                        <dd>
                            <Link to={`/clients/${client._id}`}>
                                {client.name}
                            </Link>
                        </dd>
                        <dt>View</dt>
                        <dd>{view.name}</dd>
                        <dt>Target</dt>
                        <dd>{target.name}</dd>
                        <dt>Created By</dt>
                        <dd>{orderdesign.createdBy}</dd>
                        <DisplayIf condition={!!orderdesign.replaceOrderId}>
                            <dt>Replaces order</dt>
                            <dd><Link to={`/orders/${orderdesign.replaceOrderId}`}>{orderdesign.replaceOrderId}</Link></dd>
                        </DisplayIf>
                        <dt>Actions</dt>
                        <dd>
                            {!orderdesign.created && (
                                <span>
                                    <Link
                                        to={`/orderdesigns/${
                                            orderdesign._id
                                        }/review`}
                                    >
                                        Go to review
                                    </Link>
                                </span>
                            )}
                            {orderdesign.created && (
                                <span>
                                    <Link
                                        to={`/orders/${
                                            orderdesign.orderRef.id
                                        }`}
                                    >
                                        Order Details
                                    </Link>
                                </span>
                            )}
                        </dd>
                    </dl>
                )}
            </div>
        );
    }
}

export default Order;
