import React from "react";
import "./console.css";
import orderLogo from "../../img/icon-unacast_prox.png";
import clientLogo from "../../img/icon-investors.png";
import unicornLogo from "../../img/unicorn.svg";
import bucketLogo from "../../img/bucket.png";
import locationAnalyticsLogo from "../../img/icon-location_analysis.png";

import BigButton from "../bigbutton/BigButton";
import ActivityTimeLine from "../activity/ActivityTimeLine";
import {NavLink} from "react-router-dom";
import {filter} from "lodash";

const editorClaim = "admin";

const tiles = [
    {
        link: "/orders",
        icon: orderLogo,
        title: "Orders",
        adminOnly: false,
        description: "Data Orders to Clients",
    },
    {
        link: "/clients",
        icon: clientLogo,
        title: "Clients",
        description: "Directory of all clients",
        adminOnly: false,
        className: "client",
        quickActions: [
            {
                link: "/clients/create",
                title: "Add Client",
                requiredClaim: editorClaim,
            },
        ],
    },
    {
        link: "/views",
        icon: locationAnalyticsLogo,
        title: "Views",
        description: "Queries executed to get data for orders",
        adminOnly: false,
        quickActions: [
            {
                link: "/views/create",
                title: "Create View",
                requiredClaim: editorClaim,
            },
        ],
    },
    {
        link: "/datasets",
        icon: locationAnalyticsLogo,
        title: "Datasets",
        description: "Query to get specific product",
        adminOnly: true,
        quickActions: [
            {
                link: "/datasets/create",
                title: "Create Dataset",
                requiredClaim: editorClaim,
            },
        ],
    },
    {
        link: "/adminland/users",
        icon: unicornLogo,
        title: "Adminland",
        adminOnly: false,
        requiredClaim: editorClaim,
        description: "Where unicorns live",
        quickActions: [{link: "/adminland/users", title: "Manage users"}],
    },
    {
        link: "/buckets/create",
        icon: bucketLogo,
        title: "Bucket",
        adminOnly: false,
        description: "Create a new S3 bucket",
    },
];

const noteTiles = [];

const Console = ({claims}) => {
    const tilesToShow = filter(tiles, ({requiredClaim = ""}) =>
        requiredClaim ? claims[requiredClaim] : true
    ).map(tile => {
        return {
            ...tile,
            quickActions: filter(
                tile.quickActions,
                qa => (qa.requiredClaim ? claims[qa.requiredClaim] : true)
            ),
        };
    });

    return (
        <div className="console">
            <section className="consoleView">
                <nav className="consoleActions">
                    <ButtonTiles tiles={tilesToShow} admin={claims.admin}/>
                </nav>
                <nav className="consoleActions">
                    <NoteTiles tiles={noteTiles}/>
                </nav>
            </section>
            <aside className="consoleAside consoleView">
                <ActivityTimeLine/>
            </aside>
        </div>
    );
};

const ButtonTiles = ({tiles, admin}) => {
    return tiles.map((tile, i) => {
        return ((tile.adminOnly && !admin ? null :
                <div key={i} className="consoleButtonAndQuickLinks">
                    <BigButton
                        className={["consoleTile", tile.className].join(" ")}
                        linkTo={tile.link}
                        icon={tile.icon}
                    >
                        <h3>{tile.title}</h3>
                        <p className="description">{tile.description}</p>
                    </BigButton>
                    <QuickActions quickActions={tile.quickActions}/>
                </div>
        ));
    });
};

const NoteTiles = ({tiles}) => {
    return tiles.map((tile, i) => {
        return (
            <div key={i} className="consoleTile noteTile light">
                <h3>{tile.title}</h3>
                <div className="noteTileContent">{tile.note}</div>
            </div>
        );
    });
};

const QuickActions = ({quickActions = []}) => {
    return (
        <div>
            {quickActions.map((action, i) => {
                return (
                    <NavLink key={i} to={action.link}>
                        {action.title}
                    </NavLink>
                );
            })}
        </div>
    );
};

export default Console;
