import firebase from "firebase/app";
import request from "superagent";

const functionsUrl = process.env.REACT_APP_FIREBASE_FUNCTIONS_URL;

export const rerunShipmentFromStatus = (id, status) => {
    return new Promise((resolve, reject) => {
        firebase
            .auth()
            .currentUser.getIdToken()
            .then(token => {
                return request
                    .post(`${functionsUrl}/rerun/shipments/${id}/_rerun?fromStatus=${status}`)
                    .timeout({response: 20000})
                    .set("Authorization", "Bearer " + token)
                    .then(res => {
                        resolve(res.text);
                    })
                    .catch(err => {
                        if (err.response) {
                            console.error(["rerun failed for id " + id + " with status " + status,
                                err.response.body || err.response.text,
                                err.response]
                            );
                        }
                        reject(err.response || err);
                    });
            })
            .catch(err => {
                reject(err);
            });
    })
};

export const abortShipment = (id, comment) => {
    return new Promise((resolve, reject) => {
        firebase
            .auth()
            .currentUser.getIdToken()
            .then(token => {
                return request
                    .put(`${functionsUrl}/shipments/${id}/_abort`)
                    .query({
                        comment: comment
                    })
                    .timeout({response: 20000})
                    .set("Authorization", "Bearer " + token)
                    .then(() => {
                        resolve();
                    })
                    .catch(err => {
                        if (err.response) {
                            console.error(["abort failed for id " + id ,
                                err.response.body || err.response.text,
                                err.response]
                            );
                        }
                        reject(err.response || err);
                    });
            })
            .catch(err => {
                reject(err);
            });
    })
};

