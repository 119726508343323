import firebase from "firebase/app";
import request from "superagent";

const functionsUrl = process.env.REACT_APP_FIREBASE_FUNCTIONS_URL;

export const isSubscription = order => {
    return !!order.schedule && !!order.schedule.cronExpression;
};

export const isDone = order => {
    if (order.shipments.length === 0) {
        return false;
    }
    let latest = order.shipments[0];
    return ["complete", "failed"].indexOf(latest.status) >= 0
};

export const syncOrder = id => {
    return new Promise((resolve, reject) => {
        firebase
            .auth()
            .currentUser.getIdToken()
            .then(token => {
                return request
                    .put(`${functionsUrl}/refresh/orders/${id}/_sync`)
                    .timeout({response: 20000})
                    .set("Authorization", "Bearer " + token)
                    .then(res => {
                        resolve(res.text);
                    })
                    .catch(err => {
                        if (err.response) {
                            console.error(["sync failed for id " + id,
                                err.response.body || err.response.text,
                                err.response]
                            );
                        }
                        reject(err.response || err);
                    });
            })
            .catch(err => {
                reject(err);
            });
    })
};

export const deactivateOrder = id => {
    return new Promise((resolve, reject) => {
        firebase
            .auth()
            .currentUser.getIdToken()
            .then(token => {
                return request
                    .delete(`${functionsUrl}/orders/${id}`)
                    .timeout({response: 20000})
                    .set("Authorization", "Bearer " + token)
                    .then(res => {
                        resolve(res.text);
                    })
                    .catch(err => {
                        console.error(err);
                        console.error(err.response);
                        if (err.response)
                            console.log("deactivation failed", err.response.body);
                        reject(err.response);
                    });
            });

    })
};

export const reactivateOrder = id => {
    return new Promise((resolve, reject) => {
        firebase
            .auth()
            .currentUser.getIdToken()
            .then(token => {
                return request
                    .put(`${functionsUrl}/orders/${id}/_reactivate`)
                    .timeout({response: 20000})
                    .set("Authorization", "Bearer " + token)
                    .then(res => {
                        resolve(res.text);
                    })
                    .catch(err => {
                        console.error(err);
                        console.error(err.response);
                        if (err.response)
                            console.log("reactivation failed", err.response.body);
                        reject(err.response);
                    });
            });

    })
};
