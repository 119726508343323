import React from "react";
import "./Targets.css";

const Target = ({target, isEditor = false, archive}) => {
    return (
        <div className="target panel">
            <h4>{target.name}</h4>
            <dl>
                <dt>Type</dt>
                <dd>{target.targetType}</dd>
                <dt>Placement</dt>
                <dd>
                    {placement(target)}
                </dd>
                {target.targetType === "S3" && <dt>Region</dt>}
                {target.targetType === "S3" && <dd>{target.region}</dd>}
            </dl>

            {target.archived && <div className="archivedDetail">Archived</div>}
            {isEditor &&
                archive && (
                    <div className="buttons">
                        <button
                            className="small"
                            disabled={target.archived}
                            onClick={() => archive()}
                        >
                            Archive
                        </button>
                    </div>
                )}
        </div>
    );
};

function placement(target) {
    switch (target.targetType) {
        case "S3":
            return `s3://${target.bucket}/${target.folder}`;
        case "SFTP":
            return `sftp://${target.host}${target.port ? ":" + target.port : ""}/${target.folder}`;
        case "Azure":
            return `https://${target.accountName}.blob.core.windows.net/${target.folder}`;
        case "GCS":
            return `gs://${target.gcsBucket}/${target.folder}`;
        case "Google Drive":
            return `https://drive.google.com/drive/u/0/folders/${target.gdriveFolderId}/${target.folder}`;
        default:
    }
}

export default Target;
