import React from "react";
import FirestoreContainer, {loading} from "../FirestoreContainer";
import Spinner from "../spinner/Spinner";
import {NavLink} from "react-router-dom";
import DatasetList from "./DatasetList";

const DatasetListContainer = () => {
    return (
        <FirestoreContainer
            queryMapFn={db => {
                return {
                    datasets: db.collection("datasets")
                }
            }}
            mutators={() => {
                return {};
            }}
        >
            <DatasetListLayout />
        </FirestoreContainer>
    );
};

const DatasetListLayout = ({datasets}) => {

    return (
        <section>
            <h1>Datasets</h1>
            <NavLink to={`/datasets/create`}>Create Dataset</NavLink>
            {loading(datasets) && <Spinner/>}
            {!loading(datasets) && (
                <DatasetList
                    datasets={datasets.data}
                />)
            }
        </section>
    );
};

export default DatasetListContainer;
