import firebase from "firebase/app";
import request from "superagent";

const functionsUrl = process.env.REACT_APP_FIREBASE_FUNCTIONS_URL;

export const dryRun = query => {
    return new Promise((resolve, reject) => {
        firebase
            .auth()
            .currentUser.getIdToken()
            .then(token => {
                return request
                    .post(`${functionsUrl}/bigquery/_dryrun`)
                    .timeout({response: 3600000})
                    .set("Authorization", "Bearer " + token)
                    .set("accept", "application/json")
                    .send({"query":query})
                    .then(result => {
                        resolve(result.body.statistics);
                    })
                    .catch(err => {
                        reject(err);
                    })
            })
            .catch(err => {
                reject(err);
            });
    });
};

export const validateQuery = query => {
    return new Promise((resolve, reject) => {
        firebase
            .auth()
            .currentUser.getIdToken()
            .then(token => {
                return request
                    .post(`${functionsUrl}/bigquery/_validateQuery`)
                    .timeout({response: 3600000})
                    .set("Authorization", "Bearer " + token)
                    .set("accept", "application/json")
                    .send({"query":query})
                    .then(result => {
                        resolve(result.body.statistics);
                    })
                    .catch(err => {
                        reject(err);
                    })
            })
            .catch(err => {
                reject(err);
            });
    });
};
