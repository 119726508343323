import React, {Component} from 'react';
import {syncOrder} from "./order-methods";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import './RefreshOrderButton.css'

class RefreshOrderButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            working: false,
            failed: false,
            syncing: false,
        }
    }

    componentDidMount() {
        if (this.props.autotrigger) {
            this.sync(this.props.order.id);
        }
    }

    componentWillUnmount() {
        this.setState({...this.state, syncing: false})
    }

    sync(id) {
        if (this.state.working) {
            return;
        }
        this.setState({working: true, failed: false, syncing: true});
        syncOrder(id)
            .then(() => {
                this.state.syncing && this.setState({working: false, failed: false, syncing: false})
            })
            .catch(() => {
                this.state.syncing && this.setState({working: false, failed: true, syncing: false})
            });
    }

    render() {
        return this.props.compact ? this.renderCompact() : this.renderFull();
    }

    icon() {
        return this.state.failed ? "exclamation-triangle" : "sync-alt"
    }

    renderFull() {
        return (
            <button onClick={() => this.sync(this.props.order.id)} disabled={this.state.working}>
                <FontAwesomeIcon icon={this.icon()}
                                 spin={this.state.working}/> {this.state.working ? "Refreshing..." : "Refresh"}
            </button>
        );
    }

    renderCompact() {
        return (
            <span>
            <FontAwesomeIcon
                className="refreshOrderButton compact"
                icon={this.icon()}
                onClick={() => this.sync(this.props.order.id)}
                disabled={this.state.working}
                spin={this.state.working}
            />
            </span>
        );
    }
}

export default RefreshOrderButton;
