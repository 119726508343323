import React from "react";
import {Link} from "react-router-dom";
import Firestore from "../components/FirestoreContainer";
import ClientList from "../components/clients/ClientList";

const ClientListPage = () => {
    return (
        <Firestore
            queryMapFn={db => {
                return {
                    clients: db.collection("clients"),
                    orders: db.collection("ordercache").where('active', '==', true)
                };
            }}
        >
            <ClientListLayout/>
        </Firestore>
    );
};

const ClientListLayout = ({clients, orders}) => {
    return <section>
        <Link to="/clients/create">Add a New Client</Link>
        <ClientList clients={clients} orders={orders}/>
    </section>
};

export default ClientListPage;
