import React, { Component } from "react";
import Spinner from "../components/spinner/Spinner";
import firebase from "firebase/app";
import request from "superagent";
import "./UserAdmin.css";
import DisplayIf from "../components/displayif/DisplayIf";

const defaultState = {
    loading: false,
    users: [],
    nextPageToken: undefined,
    prevPageToken: undefined,
};

class UserAdminPage extends Component {
    constructor(props) {
        super(props);
        this.state = Object.assign(defaultState, {});
    }
    componentDidMount() {
        this.fetchUsers();
    }

    fetchUsers(pageToken) {
        this.setState({
            loading: true,
        });
        getUsers(pageToken)
            .then(res => {
                console.log(res.body);
                this.setState({
                    nextPageToken: res.body.pageToken,
                    users: res.body.users,
                    loading: false,
                });
            })
            .catch(err => {
                console.log("could not fetch users", err);
                this.setState({
                    loading: false,
                });
            });
    }

    render() {
        const { loading, users, nextPageToken } = this.state;
        return (
            <section>
                <h3>User Admin Page</h3>
                {loading && <Spinner />}
                {!loading && (
                    <UserListWithControls
                        users={users}
                        editUserClaims={(userUid, claims) => {
                            console.log("update-claims", userUid, claims);
                            this.setState({
                                loading: true,
                            });
                            updateClaims(userUid, claims)
                                .then(res => {
                                    console.log("updated claims", res);
                                    this.fetchUsers();
                                })
                                .catch(err => {
                                    console.log("failed to update claims", err);
                                    this.setState({ loading: false });
                                });
                        }}
                    />
                )}
                <DisplayIf condition={nextPageToken}>
                    <button onClick={() => this.fetchUsers(nextPageToken)}>Next</button>
                </DisplayIf>
            </section>
        );
    }
}

const UserListWithControls = ({ users, editUserClaims }) => {
    return (
        <div>
            {users.map(user => {
                return (
                    <User
                        key={user.uid}
                        className="listRow"
                        user={user}
                        editUserClaims={editUserClaims}
                    />
                );
            })}
        </div>
    );
};

const User = ({ user, editUserClaims, className }) => {
    return (
        <div className={className}>
            <div>{user.displayName}</div>
            <div>{user.email}</div>
            <UserControl user={user} editUserClaims={editUserClaims} />
        </div>
    );
};

const UserControl = ({ user, editUserClaims }) => {
    const isAdmin = user.customClaims && user.customClaims.admin;
    const isUnacastEmployee =
        user.customClaims && user.customClaims.unacastemployee;
    return (
        <div className="userAdminControls">
            <label>
                admin{" "}
                <input
                    className="userAdminControlsCheckbox"
                    type="checkbox"
                    onChange={() =>
                        editUserClaims(user.uid, {
                            ...user.customClaims,
                            admin: !isAdmin,
                        })
                    }
                    checked={isAdmin}
                />
            </label>
            <label>
                Unacast Employee{" "}
                <input
                    className="userAdminControlsCheckbox"
                    type="checkbox"
                    onChange={() =>
                        editUserClaims(user.uid, {
                            ...user.customClaims,
                            unacastemployee: !isUnacastEmployee,
                        })
                    }
                    checked={isUnacastEmployee}
                />
            </label>
        </div>
    );
};

const getUsers = nextPageToken => {
    return firebase
        .auth()
        .currentUser.getIdToken()
        .then(token => {
            return request
                .get(
                    `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URL}/userAdmin/signedupusers`
                )
                .query({nextPageToken})
                .timeout({ response: 30000 })
                .set("Authorization", "Bearer " + token);
        });
};

const updateClaims = (userUid, claims) => {
    return firebase
        .auth()
        .currentUser.getIdToken()
        .then(token => {
            return request
                .put(
                    `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URL}/userAdmin/userclaims/${userUid}`
                )
                .timeout({ response: 30000 })
                .set("Authorization", "Bearer " + token)
                .send({
                    claims,
                });
        });
};

export default UserAdminPage;
