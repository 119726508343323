import React, {Component} from 'react';
import urlJoin from 'proper-url-join';
import {curlyDateFmt} from "../orderdesign/order-methods";
import moment from "moment";
import {decryptValue} from "../targets/target-methods";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class FilePlacement extends Component {

    constructor(props) {
        super(props);
        this.state = {
            clipboardIcon: "clipboard"
        }
    }

    render() {
        const {design, created, selectedShipment, target} = this.props;
        if (!target) {
            return null;
        }

        const date = moment(`${selectedShipment.started}`).utc()
        const legacyFormat = moment(created).isBefore('2020-09-02');
        const isMultiQuery = selectedShipment.spec && selectedShipment.spec.generate.bigQuery.query.includes("--folder")
        let multiQueryPostfix = ""
        if (isMultiQuery) {
            multiQueryPostfix = "[some-folder-from-the-query]/"
        }

        let storagePath;
        if (target.targetType === "Azure") {
            let firstPart = `https://${target.accountName}.blob.core.windows.net`
            if (target.serviceUrl) {
                decryptValue(target.serviceUrl).then(decryptedServiceUrl => {
                    const serviceUrl = decryptedServiceUrl.match(/(https:\/\/.*)\?.*/);
                    if (serviceUrl) {
                        firstPart = serviceUrl[1]
                    }
                })
            }
            storagePath = urlJoin(firstPart, target.containerName, curlyDateFmt(design.folderPath, legacyFormat, date), {trailingSlash: true});
        } else if (target.targetType === "SFTP") {
            storagePath = urlJoin(`sftp://${target.host}`, target.folder, curlyDateFmt(design.folderPath, legacyFormat, date), {trailingSlash: true});
        } else if (target.targetType === "GCS") {
            storagePath = urlJoin(`gs://${target.gcsBucket}`, target.folder, curlyDateFmt(design.folderPath, legacyFormat, date), {trailingSlash: true});
        } else if (target.targetType === "Google Drive") {
            storagePath = urlJoin(`https://drive.google.com/drive/u/0/folders/${target.gdriveFolderId}`, target.folder, curlyDateFmt(design.folderPath, legacyFormat, date), {trailingSlash: true})
        } else {
            storagePath = urlJoin(`s3://${target.bucket}`, target.folder, curlyDateFmt(design.folderPath, legacyFormat, date), {trailingSlash: true});
        }

        return (<span>
            <dt>File placement</dt>
            <div>
                <dd className="filePathList"><span id='filePath'>{storagePath}</span>{multiQueryPostfix}
                    <button className={'plain'} onClick={this.copyFilePath.bind(this)}>
                        <FontAwesomeIcon id={'clipboardIcon'} icon={this.state.clipboardIcon}/>
                    </button>
                </dd>
            </div>
        </span>)
    }

    copyFilePath() {
        const filePath = document.getElementById('filePath');
        const range = document.createRange();
        range.selectNode(filePath);
        window.getSelection().addRange(range);
        document.execCommand('Copy');
        window.getSelection().removeAllRanges();

        this.setState({clipboardIcon: 'check'})
        setTimeout(() => this.setState({clipboardIcon: 'clipboard'}), 1000)
    }

}

export default FilePlacement;
