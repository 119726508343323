import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

const authProvider = new firebase.auth.GoogleAuthProvider();
authProvider.addScope("https://www.googleapis.com/auth/plus.login");

const emailAuthDomain = "unacast.com";

firebase.initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIRBASE_MESSAGING_SENDER_ID,
});

export const {auth} = firebase;
export const db = firebase.firestore();
export const login = async () => {
    await firebase.auth().signInWithPopup(new firebase.auth.GoogleAuthProvider());
}

export const logout = () => auth().signOut();
export const getUser = () => {
    const {currentUser: user} = auth();
    return user;
};
export const isUserFromAllowedDomain = user =>
    user && user.email.match(`.*@${emailAuthDomain}$`);
