import React, {Component} from 'react';
import PropTypes from "prop-types";
import moment from 'moment'
import {generateShipmentChartData} from "./shipment-chart-methods";

class ShipmentChart extends Component {
    render() {
        const {shipments} = this.props;
        const chart = chartForShipments(shipments);
        return (chart &&
            <div className="shipmentChartContainer">
                <div className="ShipmentDetailsContainer">
                    {chart.rows &&
                    <div><h4>Visits/Bumps</h4>
                        <span><b>Total:</b> {formatNumber(chart.rows.total)}</span>
                        <span><b>Average:</b> {formatNumber(chart.rows.average)}</span>
                    </div>
                    }
                </div>
            </div>)
    }
}

ShipmentChart.defaultProps = {
    shipments: PropTypes.any.isRequired
};

export default ShipmentChart;

function formatNumber(n) {
    if (typeof n === 'number') {
        return n.toLocaleString("en-US", {maximumFractionDigits: 0})
    }
    return n
}

function chartForShipments(shipments) {

    let chartData = {
        data: {
            datasets: [],
        }
    };

    if (shipments && shipments.length > 0) {
        const shipmentData = shipments.map(shipment => {
            const available = shipment.events.find(evt => evt.type === "available");
            const completeEvent = shipment.events.find(evt => evt.type === "complete");
            const exportedEvent = shipment.events.find(evt => evt.type === "exported");

            const status = shipment.status;
            let numRows = 0;
            if (!!completeEvent && exportedEvent && exportedEvent.details && exportedEvent.details.numRows != null) {
                numRows = exportedEvent.details.numRows;
            }
            const date = available ? available.time : completeEvent.time;
            return {
                status,
                date: moment.utc(date).format("YYYY-MM-DD HH:mm:ss"),
                rows: numRows
            };
        });
        chartData = {
            ...chartData,
            ...generateShipmentChartData(shipmentData)
        }
    }

    return chartData;
}
