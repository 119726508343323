import React, {Component} from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {rerunShipmentFromStatus} from './shipment-methods'

class RestartButton extends Component {
    constructor(props) {
        super(props);
        this.state = {working: false, failed: false}
    }

    restart(shipmentId) {
        this.setState({working: true});
        rerunShipmentFromStatus(shipmentId, 'available')
            .then(() => {
                this.setState({working: false, failed: false})
            })
            .catch(err => {
                console.log(['Restart err',err]);
                this.setState({working: false, failed: true})
            });
    }

    icon() {
        return this.state.failed ? "exclamation-triangle":"play"
    }

    render() {

        return (
            <button type={"button"} className={"callToAction tooltip-multiline tooltip-bottom-left"}
                    data-tooltip="A restart means rewinding this shipment and running all its steps from the start"
                    onClick={() => this.restart(this.props.shipmentId)}>
                <FontAwesomeIcon icon={this.icon()} spin={this.state.working}/> Restart
            </button>
        );
    }
}

export default RestartButton;
