import React, {Component} from 'react';
import "./Panel.css";

class Panel extends Component {
    render() {
        var classes = ["panel"];
        if (!!this.props.className){
            classes = classes.concat(this.props.className.split());
        }
        return (
            <div {...this.props} className={classes.join(" ")}> {this.props.children}</div>
        )
    }

}

export default Panel;
