import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import RefreshOrderButton from "./RefreshOrderButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {first} from "lodash";
import {isSubscription, syncOrder, isDone} from "./order-methods";
import cronstrue from 'cronstrue';
import moment from 'moment';
import './OrderSummaryCompact.css';
import DisplayIf from "../displayif/DisplayIf";
import OrderOverview from "../../containers/ClientDashboard/OrderOverview";

class OrderSummaryCompact extends Component {
    render() {
        let order = this.props.order;
        const lastShipment = first(order.shipments);
        const hasShipment = !!lastShipment;

        if (hasShipment) {
            syncIfInProgress(lastShipment.status, () => syncOrder(order.id))
        }

        let orderDeactivated = order.active === false;
        let cssClass = ["panel", "orderSummaryCompact", "order", orderDeactivated && isSubscription(order) ? " inactive" : ""].join(" ").trim();

        let scheduleIcon = order.active === false ? "ban" : "redo-alt";
        let shouldAutorefreshOrder = !isDone(order) || (!!order.schedule.nextShipment && moment(order.schedule.nextShipment).isBefore(moment()));
        return (
            <li className={cssClass}>
                <div className="orderSummaryHeader">
                    <h3 className="orderClientName">{order.clientName}</h3>
                    {hasShipment && OrderOverview.renderOrderStatus(lastShipment)}
                </div>
                <div className="content">
                    <h4 className="orderName"><NavLink to={`/orders/${order.id}`}> {order.name} </NavLink></h4>
                    {isSubscription(order) && (
                        <p className="scheduleDescription description">
                            <FontAwesomeIcon
                                icon={scheduleIcon}/> {scheduleString(order)} {orderDeactivated && isSubscription(order)}
                        </p>
                    )}
                    {!hasShipment && (
                        <span>No shipment yet... <RefreshOrderButton compact={true} order={order}
                                                                     autotrigger={shouldAutorefreshOrder}/></span>
                    )}
                    {hasShipment && (
                        <dl>
                            <dt>Latest shipment</dt>
                            <dd>{shipmentTimeString(lastShipment.completed)}</dd>
                            <dt>Status</dt>
                            <dd>{lastShipment.status.toUpperCase()} <RefreshOrderButton compact={true} order={order}
                                                                                     autotrigger={shouldAutorefreshOrder}/>
                            </dd>
                            <DisplayIf condition={order.active && isSubscription(order) && lastShipment.status !== "aborted"} >
                                <dt>Next shipment</dt>
                                <dd>{shipmentTimeString(order.schedule.nextShipment)}</dd>
                            </DisplayIf>

                        </dl>
                    )}
                </div>
            </li>
        );
    }
}

export default OrderSummaryCompact;

function shipmentTimeString(timestamp) {
    let next = moment(timestamp);
    let nextString = next.format("YYYY-MM-DD HH:mm");
    let nextDiff = next.from(moment());
    return `${nextString} (${nextDiff})`
}

function scheduleString(order) {
    if (order.active === false) {
        return "Stopped"
    }
    if (!!order.schedule && order.schedule.cronExpression) {
        let cron = order.schedule.cronExpression;
        if (cron.split(" ").length < 7) {
            cron = "0 " + cron //This is what the cron lib we use in go does if you only supply 6 parts to the cron expr.
        }
        return cronstrue.toString(cron) + " (UTC)";
    } else {
        return "Once";
    }

}

function syncIfInProgress(statusOfOrder, syncFunc) {
    if (['complete', 'failed'].indexOf(statusOfOrder) >= 0) {
        return; //Nothing happens after this (unless the failed is marked for rerun), so we stop syncing.
    }
    syncFunc();
}
