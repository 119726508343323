import React, {Component} from "react";
import "./View.css";
import "./ViewList.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import ViewSummary from "./ViewSummary";
import Spinner from "../spinner/Spinner";
import DisplayIf from "../displayif/DisplayIf";
import {DropdownList} from "react-widgets";
import {loading} from "../FirestoreContainer";

class ViewList extends Component {
    constructor(props) {
        super(props);
        let products = props.datasets.map(dataset => dataset.product);
        products = products
            .filter((item, pos) => {
                return products.indexOf(item) === pos;
            })
            .sort();
        products.push("All products");
        let clients = props.clients.map(client => client.name);
        clients.push("All clients");

        this.state = {
            loadingViews: true,
            products: products,
            clients: clients,
            filter: {
                recurring: false,
                product: "All products",
                client: "All clients",
                search: "",
                showClientSpecific: false,
                showArchived: false,
            }
        };
    }

    componentDidMount() {
        Promise.all(this.props.views.map(v => {return v.dataset.get()
            .then(d => v.product = d.data().product)
            .then(() => {!!v.client && v.client.get().then(c => {v.clientName = c.data().name; v.clientId = c.id})})}))
            .then(() => this.setState({loadingViews:false}));
    }

    updateDropDownFilter(filter, value) {
        this.setState(
            {filter:
                {...this.state.filter,
                    [filter]: value
                }
        });
    }

    filterChange(e) {
        const filter = e.target.name;
        const checked = e.target.checked;
        this.setState({filter: {...this.state.filter, [filter]: checked}})
    }

    updateSearch(e) {
        let search = "";
        try {
            search = new RegExp(e.target.value);
        } catch (e) {
        }

        this.props.editFilter({...this.props.filter, search});
    }

    render() {
        const {views, archivedViews} = this.props;

        const filterChange = this.filterChange.bind(this);

        let filteredViews = views;
        if (!this.state.loadingViews) {
            let allViews = views
            if (this.state.filter.showArchived) {
                allViews = views.concat(archivedViews.data)
            }
            filteredViews = allViews
                .filter(v => this.state.filter.product === "All products" || v.product === this.state.filter.product)
                .filter(v => (this.state.filter.client === "All clients" || v.clientName === this.state.filter.client))
                .filter(v => (this.state.filter.showClientSpecific || (this.state.filter.client !== "All clients" || v.client === null)));
        }

        return (
            <div>
                {/*{false && (<div>*/}
                {/*        <input*/}
                {/*            type="search"*/}
                {/*            placeholder={"Search by (part of) view name"}*/}
                {/*            className="filter"*/}
                {/*            name={"updateSearch"}*/}
                {/*            onChange={this.updateSearch.bind(this)}*/}
                {/*            value={this.props.filter.search}*/}
                {/*        />*/}
                {/*    </div>*/}
                {/*)}*/}

                {this.state.loadingViews && <Spinner>Loading views</Spinner>}

                <DisplayIf condition={!this.state.loadingViews}>
                    <div>
                        <label className="switch">
                            <FontAwesomeIcon icon="trash-alt"/> Show Archived
                            <input id="showArchived" type="checkbox"
                                   disabled={loading(archivedViews)}
                                   name="showArchived" onChange={filterChange}
                                   checked={this.state.filter.showArchived}/>
                            <span className="slider round"/>
                        </label>
                        <label className="switch">
                            Show Client Specific
                            <input id="showClientSpecific" type="checkbox"
                                   name="showClientSpecific" onChange={filterChange}
                                   checked={this.state.filter.showClientSpecific}/>
                            <span className="slider round"/>
                        </label>
                    </div>
                    <div>
                        <DropdownList
                            id="product"
                            name="product"
                            defaultValue="All products"
                            data={this.state.products}
                            onChange={product => (this.updateDropDownFilter("product", product))}
                        />
                    </div>
                    <div>
                        <DropdownList
                            id="client"
                            name="client"
                            defaultValue="All clients"
                            data={this.state.clients}
                            onChange={client => (this.updateDropDownFilter("client", client))}
                        />
                    </div>
                    {filteredViews.length === 0 &&
                    <span>No views... (check filters, some clients don't have client specific views)</span>}
                    <div className="consoleActions viewList">
                        {filteredViews.map((view, i) => {
                            return <ViewSummary key={i} view={view} viewId={view._id}/>;
                        })}
                    </div>
                </DisplayIf>
            </div>
        );
    }
}

export default ViewList;
