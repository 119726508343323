import React, {Component} from "react";
import moment from "moment";
import HexImage from "../heximage/HexImage";
import {NavLink} from "react-router-dom";
import {loading} from "../FirestoreContainer";

class Activity extends Component {
    constructor(props) {
        super(props);
        this.state = {
            order: {loading: props.showLinks},
            view: {loading: props.showLinks},
            client: {loading: props.showLinks},
        };
    }

    componentDidMount() {
        if (this.props.showLinks) {
            const {activity} = this.props;
            activity.orderRef.get().then(order => this.setState({order: {...order.data(), loading:false}}));
            activity.viewRef.get().then(view => this.setState({view: {...view.data(), loading:false}}));
            activity.clientRef.get().then(client => this.setState({client: {...client.data(), loading:false}}));

        }
    }

    render() {
        const activity = this.props.activity;
        let user = activity.user;
        if (!user) {
            user = {
                name: "Unknown",
                picture: `https://robohash.org/${activity.timestamp}?bgset=any`,
            };
        }
        if (loading(this.state.order) || loading(this.state.view) || loading(this.state.client)) {
            return null
        }
        return (
            <li className="activityTimeLineEvent" key={activity._id}>
                <span className="eventTime">{moment().to(activity.timestamp)}</span>
                <div className="eventInfo">
                    <div className="eventUser"><HexImage url={user.picture} id={activity._id}/></div>
                    <div className="eventDetails">
                        {activity.kind === "SubmitOrder" && (
                            <div>
                                <h4 className="activityTimeLineEventType">
                                    Order Created
                                </h4>
                            </div>
                        )}
                        <div className="byLine">{user.name}</div>
                        {this.props.showLinks &&
                        <ActivityLinks activity={activity} order={this.state.order} view={this.state.view}
                                       client={this.state.client}/>}
                    </div>
                </div>
            </li>

        );
    }
}

const ActivityLinks = ({activity, order, view, client}) => {
    const orderName = !!order ? order.name : "link";
    const clientName = !!client ? client.name : "link";
    const viewName = !!view ? view.name : "link";
    return (
        <div className="eventLinks">
            {activity.orderRef && (
                <span><NavLink to={"orders/" + activity.orderRef.id}>{orderName}</NavLink><br/></span>
            )}
            {activity.clientRef && (
                <span>Client:&nbsp;<NavLink to={"clients/" + activity.clientRef.id}>{clientName}</NavLink></span>
            )}
            {activity.viewRef && (
                <span>View:&nbsp;<NavLink to={"views/" + activity.viewRef.id}>{viewName}</NavLink></span>
            )}
        </div>
    );
};

export default Activity;
