import React from "react";
import Firestore, {loading} from "../components/FirestoreContainer";
import "./Order.css";
import OrderDesignForm from "../components/orderdesign/OrderDesignForm";

import {first} from "lodash";
import moment from 'moment'
import Spinner from "../components/spinner/Spinner";
import qs from 'querystring'

const OrderDesignPage = ({match, user, history, location}) => {
    const clientId = match.params.clientId;
    const cloneDesignId = qs.parse(location.search)["cloneDesign"] || qs.parse(location.search)["?cloneDesign"];
    const replaceOrderId = qs.parse(location.search)["?replace"] || qs.parse(location.search)["replace"];
    return (
        <Firestore key={`${clientId}-${cloneDesignId}-${replaceOrderId}`}
            queryMapFn={db => {
                const clientRef = db.collection("clients").doc(clientId);
                const queries = {
                    clients: clientRef,
                    targets: db
                        .collection("targets")
                        .where("clientId", "==", clientRef)
                        .where("archived", "==", false),
                    orders: db
                        .collection("orderdesign")
                        .where("clientId", "==", clientRef),
                };
                if (!!cloneDesignId) {
                    queries.designToClone = db.collection("orderdesign").doc(cloneDesignId)
                }
                return queries;
            }}
            mutators={db => {
                return {
                    addOrder: order => {
                        const id = `${order.name}-${moment().format('YYYYMMDD_HHmmss')}`;
                        db
                            .collection("orderdesign")
                            .doc(id)
                            .set(order)
                            .then(() => {
                                history.push(`/orderdesigns/${id}/review`);
                            })
                            .catch(err => {
                                console.log(err);
                                history.push(`/`);
                            })
                    }
                };

            }}
        >
            <OrderLayout
                history={history} match={match}
                user={user}
                replaceOrderId={!!replaceOrderId && replaceOrderId}
                onChangeClient={clientId => {
                    history.push({
                        ...location,
                        state: {...location.state, clientId},
                    });
                }}
            />
        </Firestore>
    );
};

const OrderLayout = ({
                         orders,
                         targets,
                         clients,
                         designToClone,
                         addOrder,
                         user,
                         replaceOrderId,
                         onChangeClient,
                         history,
                         match
                     }) => {
    if (loading(targets) || loading(clients) || loading(orders) || (!!designToClone && loading(designToClone))) {
        return <Spinner/>
    }
    let clonedWithoutJobSpec;
    if(!!designToClone){
        clonedWithoutJobSpec = {...first(designToClone.data)};
        delete clonedWithoutJobSpec.jobSpec;
        delete clonedWithoutJobSpec.created;
        delete clonedWithoutJobSpec.orderRef;
        delete clonedWithoutJobSpec.submittedDesign;
    }

    return (
        <div className="orders">
            <OrderDesignForm
                addOrder={addOrder}
                targets={targets}
                client={first(clients.data)}
                user={user}
                onChangeClient={onChangeClient}
                designToClone={!!clonedWithoutJobSpec && clonedWithoutJobSpec}
                replaceOrderId={replaceOrderId}
                history={history}
                match={match}
            />
        </div>
    );
};

export default OrderDesignPage;
