import React, {Component} from "react";
import {filter, set} from "lodash";
import {NavLink} from "react-router-dom"

import Spinner from "../spinner/Spinner";
import DisplayIf from "../displayif/DisplayIf"
import ViewPickerContainer from "../../containers/ViewPickerContainer";
import OrderDesignSummary from "./OrderDesignSummary";

import "./OrderDesignForm.css";
import SampleFormPartial from "./partials/SampleFormPartial";
import ClientIntegrationFormPartial from "./partials/ClientIntegrationFormPartial";
import Dropdown from "./partials/Dropdown";
import Panel from "../panel/Panel";
import moment from "moment";
import {loading} from "../FirestoreContainer";

const commonDefaults = {
    name: "",
    view: "",
    target: undefined,
    customSchedule: undefined,
    triggerType: "schedule",
    filename: "",
    filenameBase: "data",
    successfileName: "_SUCCESS",
    createSuccessFile: false,
    noPrintHeader: false,
    successfilePostfix: false,
    noCompression: false,
    fileFormat: "CSV",
    singleFile: false
};

class OrderDesignForm extends Component {
    constructor(props) {
        super(props)
        const {designToClone} = props
        this.state = {};
        let sampleDefault = {
            ...commonDefaults,
            schedule: "",
            pubSubTopic: {},
            folderPath: "samples/{{year}}{{month}}{{day}}_{{hour}}{{minute}}{{second}}/",
        };
        sampleDefault.filename = this.filename(sampleDefault);

        let clientIntegrationDefault = {
            ...commonDefaults,
            schedule: undefined,
            pubSubTopic: {},
            folderPath: "{{year}}/{{month}}/{{day}}/{{hour}}/",
        };
        clientIntegrationDefault.filename = this.filename(clientIntegrationDefault);

        let orderType = "sample";
        if (designToClone) {
            designToClone.view.get().then(v => {
                this.setState({loadingCloneView: false});
                this.viewChosen({
                    ...v.data(),
                    _ref: v.ref
                }, false);
                this.setFieldAllDesigns("filenameBase", designToClone.filenameBase)
            });
            designToClone.target.get().then(t => {
                this.setState({loadingCloneTarget: false});
                this.targetChosen({
                    ...t.data(),
                    _ref: t.ref
                });
            });

            if (!!designToClone.schedule || designToClone.triggerType === "pubsub") {
                orderType = "clientIntegration";
            }

            if (!!designToClone.filename) {
                this.state.filenameFromClone = true;
            }

            if (orderType === "clientIntegration") {
                clientIntegrationDefault = {
                    ...clientIntegrationDefault,
                    ...designToClone
                };
            } else {
                sampleDefault = {
                    ...sampleDefault,
                    ...designToClone
                };
            }
        }

        this.state = {
            loadingCloneView: !!designToClone,
            loadingCloneTarget: !!designToClone,
            orderType: orderType,
            design: {
                sample: sampleDefault,
                clientIntegration: clientIntegrationDefault,
            },
        };

    }

    componentDidMount() {
        const targets = this.props.targets;
        if (hasSingleTarget(this.props)) {
            this.setFieldAllDesigns("target", targets.data[0])
        }
    }

    formChange(e) {
        let newVal = e.target.value;
        if (e.target.type === "checkbox") {
            newVal = !!e.target.checked
        }
        if (e.target.name === "name") {
            newVal = cleanUpTableName(e.target.value);
        }
        this.setField(e.target.name, newVal);
    }

    commonFieldChange(e) {
        let newVal = e.target.value;
        if (e.target.type === "checkbox") {
            newVal = !!e.target.checked
        }
        if (e.target.name === "name") {
            newVal = cleanUpTableName(e.target.value);
        }
        this.setFieldAllDesigns(e.target.name, newVal);
    }

    viewChosen(view, updateFilename = false) {
        this.setFieldAllDesigns("view", view);
        if (!!view) {
            view.dataset.get().then(dataset => {
                const datasetName = dataset.data().name;
                const initialMaxLoadTimestampDays = dataset.data().initialMaxLoadTimestamp;
                const triggerType = dataset.data().triggerType;
                const pubSubTopic = dataset.data().pubSubTopic;
                if (updateFilename && !this.props.designToClone) {
                    this.setFieldAllDesigns("filenameBase", this.composeFilenameBase(datasetName));
                }
                if (initialMaxLoadTimestampDays) {
                    this.setFieldAllDesigns("initialMaxLoadTimestamp", moment().subtract(initialMaxLoadTimestampDays, "days").format())
                }
                if (triggerType) {
                    this.setFieldAllDesigns("triggerType", triggerType);
                    if (pubSubTopic) {
                        this.setFieldAllDesigns("pubSubTopic", pubSubTopic);
                    }
                } else {
                    this.setFieldAllDesigns("triggerType", "schedule");
                }
            });
            if (hasSingleTarget(this.props)) {
                this.setFieldAllDesigns("target", this.props.targets.data[0])
            }
        } else {
            if (!this.props.designToClone) {
                this.setFieldAllDesigns("filenameBase", "");
            }
        }
    }

    filename(design) {
        const compressionSuffix = (design.noCompression
            || design.fileFormat === "AVRO"
            || design.fileFormat === "PARQUET") ? "" : ".gz";
        const filebaseName = design.filenameBase;
        const multifileSuffix = design.singleFile ? "" : "*";
        const datePostfix = design.filePostfix || "";
        const extension = this.findFileExtension(design.fileFormat);
        return `${filebaseName}${datePostfix}${multifileSuffix}.${extension}${compressionSuffix}`;
    }

    findFileExtension(fileFormat) {
        switch (fileFormat) {
            case 'PSV':
                return 'csv';
            default:
                return (fileFormat || "").toLowerCase();
        }
    }

    composeFilenameBase(filename) {
        return `${this.removeIllegalCharacters(filename)}`;
    }

    removeIllegalCharacters = s => s.toLowerCase().replace(/[^0-9a-z_]/g, "");

    currentDesign() {
        const design = this.state.design[this.state.orderType];
        design.filename = this.filename(design);

        if (!design.customSchedule) {
            delete design.customSchedule
        }

        return design;
    }

    chosenSchedule() {
        const order = this.currentDesign();
        if (!!order.customSchedule && !order.schedule) {
            return order.customSchedule;
        } else {
            return order.schedule;
        }
    }

    createOrder(e) {
        e.preventDefault();
        let order = {...this.currentDesign()};
        let current = Object.assign(order, {
            view: order.view._ref,
            target: order.target._ref,
            clientId: this.props.client._ref,
            createdBy: this.props.user.email,
            schedule: this.chosenSchedule(),
            submit: true,
        });
        if (!!this.props.replaceOrderId) {
            current.replaceOrderId = this.props.replaceOrderId;
        }
        this.props.addOrder(current);
    }

    targetChosen(target) {
        this.setFieldAllDesigns("target", target);
    }

    setField(field, value) {
        let obj = this.state.design[this.state.orderType];
        set(obj, field, value);
        this.setState({
            design: {
                ...this.state.design,
                [this.state.orderType]: obj,
            }
        });
    }

    setFieldAllDesigns(fieldname, value) {
        let designs = {};
        for (let type in this.state.design) {
            let design = this.state.design[type];
            set(design, fieldname, value);
            designs[type] = design;
        }
        this.setState({design: designs})
    }

    typeChosen(orderType) {
        this.setState({
            orderType: orderType.value,
        });
    }

    formValid = (toValidate = {}) => {
        let values = {...toValidate};
        if (!values.schedule) {
            delete values.schedule;
        } else {
            delete values.customSchedule;
        }
        if (!values.createSuccessFile) {
            delete values.successfileName;
            delete values.successfilePostfix;
        }
        if (this.state.orderType === "sample") {
            delete values.schedule;
        }
        if (!values.triggerType) {
            delete values.triggerType;
        }
        if (!values.pubSubTopic) {
            delete values.pubSubTopic;
        }
        let invalidFields = filter(values, (val, _) =>
            typeof val === "boolean" ? false : !val
        );
        return invalidFields.length === 0;
    };

    setAndValidateInitialMaxLoadTimestamp(e) {
        let regexp = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\+\d{2}:\d{2}$/;
        this.setState({initialMaxLoadTimestampError: !e.target.value.match(regexp)});
        this.setFieldAllDesigns("initialMaxLoadTimestamp", e.target.value);
    }

    render() {
        const onChange = this.formChange.bind(this);
        const setField = this.setField.bind(this);
        const commonFieldChange = this.commonFieldChange.bind(this);
        const typeChosen = this.typeChosen.bind(this);
        const newOrder = this.currentDesign.bind(this)();
        const formValid = this.formValid.bind(this);
        const validateInitialMaxLoadTimestamp = this.setAndValidateInitialMaxLoadTimestamp.bind(this);

        const {client, targets, replaceOrderId} = this.props;

        if (!client || loading(targets) || this.state.loadingCloneView || this.state.loadingCloneTarget) {
            return <Spinner/>
        }

        return (
            <section className="createOrder">
                <h2>Order designer</h2>
                <DisplayIf condition={!!replaceOrderId}>
                    <Panel>
                        This order will replace <NavLink to={`/orders/${replaceOrderId}`}>{replaceOrderId}</NavLink>
                    </Panel>
                </DisplayIf>
                {!!this.props.client && (
                    <form onSubmit={this.createOrder.bind(this)}>
                        <span className="field">
                            <label htmlFor="client">Client</label>
                            <span>
                                <input type="text" name="client" placeholder="Name of client" disabled={true}
                                       value={this.props.client.name}/>   <span className="fieldComment"/>
                            </span>
                        </span>
                        <span className="field">
                            <label htmlFor="name">Name</label>
                            <span>
                                <input type="text" name="name" placeholder="Name of order" onChange={commonFieldChange}
                                       required={true}
                                       value={newOrder.name}/>
                                <span className="fieldComment">
                                    Only letters, numbers and spaces are valid
                                    characters.
                                </span>
                            </span>
                        </span>

                        <span className="field">
                            <label htmlFor="orderType">Order Type</label>
                            <Dropdown placeholder={"Order type"}
                                      onChange={typeChosen}
                                      data={[
                                          {name: 'Sample', value: 'sample'},
                                          {name: 'Recurring', value: 'clientIntegration'}
                                      ]}
                                      textField="name" valueField="value"
                                      value={this.state.orderType}
                            />
                        </span>
                        <DisplayIf condition={!!this.state.orderType}>

                            <span className="field">
                                <label htmlFor="View">View</label>
                                <ViewPickerContainer
                                    client={this.props.client}
                                    view={newOrder.view}
                                    onPick={view => this.viewChosen(view)}/>
                            </span>

                            <DisplayIf condition={!!newOrder.view && newOrder.initialMaxLoadTimestamp}>
                                 <span className="field">
                                    <label htmlFor="initialMaxLoadTimestamp">Initial MaxLoadTimestamp</label>
                                     <div>
                                    <input className={this.state.initialMaxLoadTimestampError ? "error" : ""}
                                           type="text" name="initialMaxLoadTimestamp"
                                           placeholder={newOrder.initialMaxLoadTimestamp}
                                           value={newOrder.initialMaxLoadTimestamp}
                                           onChange={value => validateInitialMaxLoadTimestamp(value)}
                                    />
                                         <span className="fieldComment">Only change this if you wish to override the current initial timestamp<br/>Be careful to follow this format: 2001-01-01T10:10:10+00:00</span>
                                     </div>
                                 </span>
                            </DisplayIf>
                            <span className="field">
                                <label htmlFor="target">Target</label>
                                <DisplayIf condition={!hasSingleTarget(this.props)}>
                                    <Dropdown disabled={client === ""} placeholder={"Pick Target"}
                                              onChange={target => this.targetChosen(target)}
                                              values={targets}
                                              value={newOrder.target}
                                              textField="name" valueField="_id"/>
                                </DisplayIf>
                                {newOrder.target && <DisplayIf condition={hasSingleTarget(this.props)}>
                                    <input type="text" value={newOrder.target.name} disabled={true}/>
                                </DisplayIf>}
                            </span>

                            {this.state.orderType === 'sample' &&
                            <SampleFormPartial newOrder={newOrder} setField={setField} onChange={onChange}/>}
                            {this.state.orderType === 'clientIntegration' &&
                            <ClientIntegrationFormPartial newOrder={newOrder} setField={setField} onChange={onChange}/>
                            }

                            <OrderDesignSummary order={newOrder}/>

                            <div className="buttons">
                                <input
                                    type="submit"
                                    disabled={!formValid(newOrder)}
                                    value={"Save Order Design"}
                                    className="callToAction"
                                />
                            </div>
                        </DisplayIf>
                    </form>
                )}
            </section>
        );
    }
}

function cleanUpTableName(orderName) {
    if (orderName === undefined) {
        return undefined;
    }
    orderName = orderName.replace(/[^a-zA-Z0-9_ ]/g, ""); //remove all illegal signs (for BQ table names) except spaces
    return orderName;
}

function hasSingleTarget(props) {
    return props.targets.data && props.targets.data.length === 1
}

export default OrderDesignForm;
