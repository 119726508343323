import React, {Component} from 'react';
import {NavLink} from "react-router-dom"
import {filter, sortBy} from 'lodash'
import Spinner from "../../components/spinner/Spinner";
import {loading} from "../../components/FirestoreContainer";

class ViewLinkList extends Component {
    render() {
        const {views} = this.props;
        if (loading(views)) {
            return <Spinner>Loading views...</Spinner>
        }
        const activeViews = sortBy(
            filter(views.data, (t) => !t.archived),
            (v) => v.name.toLowerCase()
        )
        return <section>
            <h2>
                Views
                <NavLink to={`/views/create`} className="btn callToAction addButton">+</NavLink>
            </h2>
            <ul className="componentList">
                {activeViews.map(v => <li key={v._id}>
                    <NavLink to={`/views/${v._id}`}>{v.name}</NavLink>
                </li>)}
            </ul>
            {activeViews.length === 0 && <span>No existing views</span>}
        </section>
    }

}

export default ViewLinkList;
