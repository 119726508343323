import React from "react";
import FirestoreContainer, {loading} from "../components/FirestoreContainer";
import ViewList from "../components/views/ViewList";
import Spinner from "../components/spinner/Spinner";
import {NavLink} from "react-router-dom";

const ViewListContainer = () => {
    return (
        <FirestoreContainer
            queryMapFn={db => {
                return {
                    views: db.collection("views").where("archived", "!=", true),
                    archivedViews: db.collection("views").where("archived", "==", true),
                    datasets: db.collection("datasets"),
                    clients: db.collection("clients"),
                };
            }}
        >
            <ViewListLayout/>
        </FirestoreContainer>
    );
};

const ViewListLayout = ({views, archivedViews, datasets, clients}) => {
    return (
        <section>
            <h1>Views</h1>
            <NavLink to={`/views/create`}>Create View</NavLink>
            {(loading(views) || loading(datasets) || loading(clients)) && <Spinner/>}
            {(!loading(views) && !loading(datasets) && !loading(clients)) && (
                <ViewList
                    views={views.data}
                    archivedViews={archivedViews}
                    datasets={datasets.data}
                    clients={clients.data}
                />


            )}
        </section>
    );
};

export default ViewListContainer;
