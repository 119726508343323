import React from "react";
import FirestoreContainer, {loading} from "../components/FirestoreContainer";
import {View, ViewControls} from "../components/views";
import Spinner from "../components/spinner/Spinner";
import {first} from "lodash";
import {useParams} from "react-router-dom";
import "./ViewPage.css";
import {NavLink} from "react-router-dom";


const getOrderCache = (orderCacheRefs) => {
    return Promise.all(orderCacheRefs.map((orderCacheRef) => {
        return orderCacheRef.get().
            then((fetched) => {
                return fetched.data();
            })
        }))
}


const ViewPage = ({claims}) => {
    const {viewId} = useParams();

    return (
        <FirestoreContainer key={viewId}
            queryMapFn={db => {
                const views = db.collection("views").doc(viewId);
                const designsUsingView = db.collection("orderdesign").where("view", "==", views);
                return {
                    views,
                    designsUsingView,
                };
            }}
            mutators={db => {
                return {
                    archiveView: () =>
                        db
                            .collection("views")
                            .doc(viewId)
                            .update("archived", true),
                    dearchiveView: () =>
                        db
                            .collection("views")
                            .doc(viewId)
                            .update("archived", false),
                };
            }}
            defaultViewState={{
                archiving: false,
            }}
            viewStateMutators={setViewState => {
                return {
                    setArchiving: isArchiving =>
                        setViewState({archiving: isArchiving}),
                };
            }}
        >
            <ViewPageLayout claims={claims}/>
        </FirestoreContainer>
    );
};

const ViewPageLayout = ({
                            views,
                            claims,
                            archiveView,
                            dearchiveView,
                            archiving,
                            setArchiving,
                            designsUsingView,
                        }) => {
                            
    const [loadingOrders, setLoadingOrder] = React.useState(true);
    const [orders, setOrders] = React.useState([]);

    if (loading(designsUsingView) || loading(views)) {
        return <Spinner>Loading view data...</Spinner>
    }
    else if(orders.length == 0 && designsUsingView.data.length > 0)
        getOrderCache(designsUsingView.data.
            filter(d => d.orderRef !== undefined)
            .map(d => d.orderRef))
        .then(res => {
            setOrders(res.flat().filter(res => res.active));
            setLoadingOrder(false);
        })
    
    const view = first(views.data);
    return (
        <section>
            <section>
                {view && (
                    <ViewControls
                        view={view}
                        isEditor={claims.admin}
                        archiveView={archiveView}
                        dearchiveView={dearchiveView}
                        archiving={archiving}
                        setArchiving={setArchiving}
                    />
                )}
                <h3>View Details</h3>
                {loading(views)  && <Spinner />}
                {view && <View view={view} />}
            </section>
                <h3 style={{"padding-top": "2em"}}>
                    Orders Using This View
                </h3>
                {loadingOrders && <Spinner/>}
                {!!orders && orders.length > 0 && (
                    <section className="Scrollable">
                        {!!orders && orders.map(d =>
                            <div><NavLink to={`/orders/${d.id}`}>{d.name}</NavLink></div>
                        )}
                    </section>
                )}
                {!loadingOrders && !!orders && orders.length == 0 && <div> No Active orders found</div>}
        </section>
    );
};

export default ViewPage;
