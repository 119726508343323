import firebase from "firebase/app";
import request from "superagent";

const functionsUrl = process.env.REACT_APP_FIREBASE_FUNCTIONS_URL;


export const validateBucket = bucket => {
    return new Promise((resolve, reject) => {
        firebase
            .auth()
            .currentUser.getIdToken()
            .then(token => {
                return request
                    .get(`${functionsUrl}/buckets/permissions/${bucket.name}`)
                    .query({
                        accesskey: bucket.accesskey,
                        region: bucket.region,
                        secretkey: bucket.secretkey,
                        subdirPath: encodeURIComponent(bucket.folder)
                    })
                    .timeout({response: 20000})
                    .set("Authorization", "Bearer " + token)
                    .then(res => {
                        resolve(res.body);
                    })
                    .catch(err => {
                        console.log(err);
                        console.log(err.response);
                        if (err.response) {
                            console.log("Failed to validate bucket", err.response.body);
                        }
                        reject(err.response);
                    });
            });

    })
};

export const createBucket = bucket => {
    return new Promise((resolve, reject) => {
        firebase
            .auth()
            .currentUser.getIdToken()
            .then(token => {
                return request
                    .put(`${functionsUrl}/buckets/buckets/${encodeURIComponent(bucket.name)}`)
                    .query({
                        direction: bucket.direction,
                        partner: bucket.partner,
                        region: bucket.region
                    })
                    .timeout({response: 20000})
                    .set("Authorization", "Bearer " + token)
                    .then(res => {
                        resolve(res.body);
                    })
                    .catch(err => {
                        console.log(err);
                        console.log(err.response);
                        if (err.response) {
                            console.log("Failed to create bucket", err.response.body);
                        }
                        reject(err.response);
                    });
            });

    })
};

export const encryptValue = value => {
    return new Promise((resolve, reject) => {
        firebase
            .auth()
            .currentUser.getIdToken()
            .then(token => {
                return request
                    .get(`${functionsUrl}/encryptValue?value=${encodeURIComponent(value)}`)
                    .timeout({response: 10000})
                    .set("Authorization", "Bearer " + token)
                    .then(res => {
                        resolve(res.text);
                    })
                    .catch(err => {
                        if (err.response) {
                            console.log(`Failed to encrypt value [${value}]: `, err.response.body);
                        }
                        reject(err);
                    });
            })
    });
};

export const encryptSensitiveFields = async (encryptFn, target) => {
    return {
        ...target,
        secretKey: target.secretKey ? await encryptFn(target.secretKey) : target.secretKey,
        password: target.password ? await encryptFn(target.password) : target.password,
        serviceUrl: target.serviceUrl ? await encryptFn(target.serviceUrl) : target.serviceUrl,
        accountKey: target.accountKey ? await encryptFn(target.accountKey) : target.accountKey,
    }
}

export const decryptValue = value => {
    return new Promise((resolve, reject) => {
        firebase
            .auth()
            .currentUser.getIdToken()
            .then(token => {
                return request
                    .get(`${functionsUrl}/decryptValue?value=${encodeURIComponent(value)}`)
                    .timeout({response: 10000})
                    .set("Authorization", "Bearer " + token)
                    .then(res => {
                        resolve(res.text);
                    })
                    .catch(err => {
                        if (err.response) {
                            console.log("Failed to encrypt value", err.response.body);
                        }
                        reject(err);
                    });
            })
    });
};
