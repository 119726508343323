import React, {Component} from 'react';
import {deactivateOrder, reactivateOrder} from "./order-methods";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import DisplayIf from "../displayif/DisplayIf";

class OrderActivationButton extends Component {
    constructor(props) {
        super(props);
        this.state = {working: false}
    }

    deactivate(id) {
        this.setState({working: true});
        deactivateOrder(id)
            .then(() => {
                this.setState({working: false});
            })
            .catch(() => {
                this.setState({working: false});
            })
    }

    reactivate(id) {
        if (!window.confirm("Are you sure you want to reactivate this order?\n" +
                "Any data not shipped since deactivation may be sent in the first shipment - this might be A LOT of data!")) {
            return;
        }
        this.setState({working: true});
        reactivateOrder(id)
            .then(() => {
                this.setState({working: false});
            })
            .catch(() => {
                this.setState({working: false});
            })
    }

    render() {
        let order = this.props.order;
        const deactivate = this.deactivate.bind(this);
        const reactivate = this.reactivate.bind(this);

        if (order.active) {
            return (
                <button type="button" onClick={() => deactivate(order.id)} disabled={this.state.working}>
                    <FontAwesomeIcon icon="ban" spin={this.state.working}/> Deactivate
                </button>
            );
        }

        return (
            <DisplayIf condition={!order.replacedBy}>
                <button type="button" onClick={() => reactivate(order.id)} disabled={this.state.working}>
                    <FontAwesomeIcon icon="play" spin={this.state.working} /> Reactivate
                </button>
            </DisplayIf>
        );
    }
}

export default OrderActivationButton;
