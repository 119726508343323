import React, {Component} from "react";
import "./QueryWizard.css";
import { format } from 'sql-formatter';
import DisplayIf from "../../components/displayif/DisplayIf";
import {DropdownList} from "react-widgets";
import {timestampFormats} from "../../components/views/CreateView";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {NavLink} from "react-router-dom";

const filterOnMaxMin = "Filter on max/min values";
const filterOnMatch = "filter on match";
const filterOnNonMatch = "filter on non-match";

class QueryWizard extends Component {
    constructor(props) {
        super(props);

        let schema = props.tableInfo[0].schema;
        let fields = Object.keys(schema).map(key => {
            let checked = true;
            if (props.tableInfo[0].defaultFields) {
                props.tableInfo[0].defaultFields.map(field => field.name === schema[key].name && !field.checked ? checked = false : null);
            }
            schema[key].checked = checked;
            schema[key].newName = null;
            schema[key].newType = null;
            schema[key].filter = false;
            schema[key].filterType = filterOnMaxMin; //Just sets this to default filterType
            schema[key].filterValues = "[value1, value2]";
            schema[key].filterMax = null;
            schema[key].filterMin = null;
            return schema[key]
        });
        let tableName = props.tableInfo[0].tablename;
        fields.sort((a, b) => {
            if (a.checked && !b.checked)
                return -1;
            if (!a.checked && b.checked)
                return 1;
            return 0;
        });

        this.state = {
            generatedQuery: null,
            table: tableName,
            fields: fields
        };
    }

    onFormChange(index, name, value) {
        this.setState(state => {
            const fields = state.fields.map((field, i) => {
                    if (i === index) {
                        field[name] = value;
                    }
                    return field;
                }
            );

            return {
                fields,
            };
        });
    }

    validateInputs() {
        //TODO: Check filter inputs with TYPE
        // this.state.fields.map(field => {
        //     let erroMsg = "";
        //     if (field.filter && (field.filterMax || field.filterMin || field.filterValues !== "[value1, value2]")) {
        //         if (field.filterType === filterOnMaxMin) {
        //             if (field.filterMin) {
        //
        //                 if (field.filterMax) {
        //
        //                 }
        //             } else {
        //
        //             }
        //         } else {
        //             if (field.filterType === filterOnMatch) {
        //
        //             } else {
        //
        //             }
        //         }
        //
        //     }
        // });

        return true;
    }

    generateQuery() {
        if (!this.validateInputs()) {
            return;
        }
        let queryString = "SELECT ";
        let filterString = "";
        const onlyIncludedFields = this.state.fields.filter(field => field.checked);
        onlyIncludedFields.map((field, i, arr) => {
            const endOfColumn = (arr.length - 1 === i) ? "" : ",";

            //Convert timestamp format:
            if (field.newType && ["UNIX_SECONDS", "UNIX_MILLIS"].includes(field.newType)) {
                queryString = `${queryString} ${field.newType}(${field.name}) AS ${field.newName ? field.newName : field.name}`
            } else {
                queryString = `${queryString} ${field.name}`
            }
            queryString = `${queryString}${field.newName ? " AS " + field.newName : ""}${endOfColumn}`;

            return queryString;
        });

        let tablename = this.props.baseQueryname ? this.props.baseQueryname : `\`${this.state.table}\``;

        queryString = `${queryString} FROM ${tablename}`;

        onlyIncludedFields.map(field => {
            if (field.filter && (field.filterMax || field.filterMin || field.filterValues !== "[value1, value2]")) {
                filterString = `${filterString} ${filterString === "" ? " WHERE " : " AND "} `;
                if (field.filterType === filterOnMaxMin) {
                    if (field.filterMin) {
                        filterString = `${filterString} ${field.name} > ${field.filterMin}`;
                        if (field.filterMax) {
                            filterString = `${filterString} AND ${field.name} < ${field.filterMax}`;
                        }
                    } else {
                        filterString = `${filterString} ${field.name} < ${field.filterMax}`;
                    }
                } else {
                    if (field.filterType === filterOnMatch) {
                        filterString = `${filterString} ${field.name} in ${field.filterValues}`;
                    } else {
                        filterString = `${filterString} ${field.name} not in ${field.filterValues}`;
                    }
                }

            }
            return filterString;
        });
        queryString = `${queryString}${filterString}`;

        this.setState({
            generatedQuery: queryString,
        })
    }

    copyQueryToClipboard() {
        navigator.clipboard.writeText(format(this.state.generatedQuery)).then(
            () => this.setState({copiedToClipboard: true})
        )
    }

    renderFilterComponenent(field, index) {
        if (field.filterType === filterOnMaxMin) {
            return <div>
                <label htmlFor={`filterMin-${field.name}`}>Min:</label>
                <div>
                    <input
                        name="filterMin"
                        id={`filterMin-${field.name}`}
                        onChange={e => this.onFormChange(index, "filterMin", e.target.value)}
                        value={field.filterMin}
                    />
                </div>
                <label htmlFor={`filterMax-${field.name}`}>Max:</label>
                <div>
                    <input
                        name="filterMax"
                        id={`filterMax-${field.name}`}
                        onChange={e => this.onFormChange(index, "filterMax", e.target.value)}
                        value={field.filterMax}
                    />
                </div>
            </div>;
        } else {
            return <div>
                <label
                    htmlFor={`filterValues-${field.name}`}>{`Values to ${field.filterType === filterOnMatch ? "include" : "exclude"}:`}</label>
                <div>
                    <input
                        name="filterValues"
                        id={`filterValues-${field.name}`}
                        placeholder="[value1, value2]"
                        onChange={e => this.onFormChange(index, "filterValues", e.target.value)}
                        value={field.filterValues}
                    />
                </div>
                <span className="fieldComment">
                        Fill in one or more values to include/exclude inside the brackets, example: ["FR", "ES", "DK"]
                    </span>
            </div>;
        }

    }


    render() {
        const {fields} = this.state;
        this.generateQuery = this.generateQuery.bind(this);

        return (
            <div className="queryWizard">
                <h2>Query Wizard</h2>
                <div className="dataFieldContainer">
                    <div className="selectedFieldsContainer">
                        {fields.map((field, index) => {
                            return (field.checked ?
                                <div key={index} className="dataField">
                                    <span className="field">
                                        <fieldset className="subFields">
                                            <h5 className={field.checked ? "" : "unchecked"}><label
                                                htmlFor={`checked-${field.name}`}>{field.name}: </label>
                                            <input type="checkbox" name="checked" id={`checked-${field.name}`}
                                                   onChange={e => this.onFormChange(index, "checked", e.target.checked)}
                                                   checked={field.checked}/></h5>
                                            {field.checked &&
                                            <div>
                                                <div className="nameAndTypeContainerDiv">
                                                    <div className="nameContainerDiv">
                                                        <label htmlFor={`newName-${field.name}`}>Rename field:</label>
                                                        <div>
                                                            <input
                                                                name="newName"
                                                                id={`newName-${field.name}`}
                                                                placeholder={field.name}
                                                                onChange={e => this.onFormChange(index, "newName", e.target.value)}
                                                                value={field.newName === null ? field.name : field.newName}
                                                                required={true}
                                                            />
                                                        </div>
                                                    </div>
                                                    {field.type === "TIMESTAMP" &&
                                                    <div className="typeContainerDiv">
                                                        <label htmlFor={`timestampFormat-${field.name}`}>Timestamp
                                                            format:</label>
                                                        <DropdownList
                                                            id={`timestampFormat-${field.name}`}
                                                            data={timestampFormats}
                                                            placeholder={field.type}
                                                            onChange={value => this.onFormChange(index, "newType", value)}
                                                            value={field.newType === null ? field.type : field.newType}
                                                            required={true}
                                                        />
                                                    </div>}
                                                </div>
                                                <span>
                                                <label htmlFor={`filter-${field.name}`}>Filter on this field: </label>
                                                <input type="checkbox" name="filter" id={`filter-${field.name}`}
                                                       onChange={e => this.onFormChange(index, "filter", e.target.checked)}
                                                       checked={field.filter}/>
                                                    {field.filter &&
                                                    <div>
                                                        <label htmlFor={`filterType-${field.name}`}>Filter Type:</label>
                                                        <DropdownList
                                                            id={`filterType-${field.name}`}
                                                            data={[filterOnMaxMin, filterOnMatch, filterOnNonMatch]}
                                                            placeholder={field.filterType}
                                                            onChange={value => this.onFormChange(index, "filterType", value)}
                                                            value={field.filterType}
                                                            required={false}
                                                        />
                                                        {this.renderFilterComponenent(field, index)}
                                                    </div>}
                                                </span>
                                            </div>}
                                       </fieldset>
                                    </span>
                                </div> : null
                            );
                        })}
                    </div>
                    <div className="unselectedFieldsContainer">
                        <h4>Other datafields: </h4>
                        {fields.map((field, index) => {
                            return (!field.checked ?
                                <div key={index} >
                                    <h5 className={field.checked ? "" : "unchecked"}><label
                                        htmlFor={`checked-${field.name}`}>{field.name}: </label>
                                    <input type="checkbox" name="checked" id={`checked-${field.name}`}
                                           onChange={e => this.onFormChange(index, "checked", e.target.checked)}
                                           checked={field.checked}/></h5>
                            </div>: null)
                        })}
                    </div>
                </div>
                <div className="buttonsContainer">
                    <span className="text-right">
                            <NavLink to="/managequerywizard" target="_blank">
                                <strong>Set default fields</strong>
                            </NavLink>
                    </span>

                    <div className="buttons">
                        <input
                            className="callToAction"
                            type="submit"
                            value={"Generate query"}
                            onClick={this.generateQuery}
                        />
                    </div>
                </div>

                <DisplayIf condition={this.state.generatedQuery}>
                    <h5>Generated query</h5>
                    <code className="sql">
                        {this.state.generatedQuery && format(this.state.generatedQuery)}
                    </code>
                    <button onClick={this.copyQueryToClipboard.bind(this)}>
                        <FontAwesomeIcon
                            icon="copy"/> {this.state.copiedToClipboard ? "Copied!" : "Copy info to clipboard"}
                    </button>
                </DisplayIf>
            </div>
        );
    }
}

export default QueryWizard;
