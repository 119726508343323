import React from 'react'

import Firestore, {loading} from "../../components/FirestoreContainer";
import Spinner from "../../components/spinner/Spinner";
import ManageQueryWizard from "./ManageQueryWizard";

const ManageQueryWizardPage = () => {
    return (
        <Firestore
            queryMapFn={db => {
                return {
                    bqTables: db.collection("bq_table"),
                };
            }}
            mutators={() => {
                return {};
            }}
        >
            <ManageQueryWizardPageWithoutFireBaseContext />
        </Firestore>
    );
};

const ManageQueryWizardPageWithoutFireBaseContext = ({ bqTables }) => {
    return (
        <section>
            {loading(bqTables) ? (
                <Spinner />
            ) : (
               <ManageQueryWizard bqTables={bqTables.data} />
            )}
        </section>
    );
};


export default ManageQueryWizardPage;

