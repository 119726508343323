import React, { useState } from "react";
import {useParams} from "react-router-dom"

import "./Dataset.css";

import { format } from 'sql-formatter';
import DocumentTitle from 'react-document-title';
import FirestoreContainer, {loading, removeRefandId} from "../FirestoreContainer";
import Spinner from "../spinner/Spinner";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {NavLink} from "react-router-dom";
import firebase from "firebase/app";
import "firebase/firestore";
import CreateDataset from "./CreateDataset";
import {useHistory} from "react-router-dom";
import moment from "moment";

const chunkArr = (arr, size) => {
    return arr.reduce((acc, val, ind) => {
       const subIndex = ind % size;
       if(!Array.isArray(acc[subIndex])){
          acc[subIndex] = [val];
       } else {
          acc[subIndex].push(val);
       }
       return acc;
    }, []);
};

const ViewList = ({views, dataset}) => {
    if (loading(views)) {
        return <Spinner/>
    } else {
        return (
            <section>
                <h3 style={{"paddingTop": "2em"}}>
                    Views Referencing this Dataset
                </h3>
                <div className="scrollableList">
                    {views.data.filter(v => v !== undefined).map((view) => {
                        return <div style={{"paddingTop": "0.5em"}} key={view._id}>
                            <NavLink to={"/views/" + view._id}> {view.name}</NavLink>
                        </div>;
                    })}
                    {!loading(views) && views.data.length === 0 && <div> No Views found</div>}
                </div>
                <OrderList views={views.data} dataset={dataset}/>
            </section>
        );
    }
}

const getOrderCache = (orderCacheRefs) => {
    return Promise.all(orderCacheRefs.map((orderCacheRef) => {
        return orderCacheRef.get().
            then((snapshot) => {
                return snapshot.data();
            })
        }))

}

const OrderList = ({views, dataset}) => {

    const [loading, setLoading] = React.useState(true);
    const [orders, setOrders] = React.useState([]);
    const db = firebase.firestore();

    React.useEffect(() => {
        let chunkedViews = chunkArr(views, 10);
        Promise.all(chunkedViews.map((chunkedView) => {
            const refs = chunkedView.map(v => v._ref);
            return db.collection("orderdesign").where("view", "in", refs).get().then((snapshot) => {
                    return snapshot.docs.map(doc => doc.data().orderRef);
                })
            })
        )
        .then(res => getOrderCache(res.flat().filter(res => res !== undefined)))
        .then(res => {
            setOrders(res.flat().filter(res => !!res && res.active));
            setLoading(false);
        });
    }, []);

    if (loading) {
        return <Spinner/>
    } else
        return (
            <section>
                <h3 style={{"paddingTop": "2em"}}>
                    Orders affected by this Dataset
                </h3>
                <div className="scrollableList">
                    {orders.filter(o => o !== undefined).map((order) => {
                        // Get order creation date from it's ID and compare with last update time of dataset
                        let isOrderStale = !!dataset.data[0].lastUpdatedOn && moment(dataset.data[0].lastUpdatedOn.toDate())
                            > moment(order.id.match("([0-9]{8}_[0-9]{6})")[0].replace('_', ' '));
                        return (
                            <div style={{
                                    "paddingTop": "0.5em",
                                    display: "flex",
                                    "justifyContent": "center"
                                }
                            } key={order.id}>
                                <NavLink to={"/orders/" + order.id}> {order.name}</NavLink>
                                {isOrderStale &&
                                    <>
                                        <FontAwesomeIcon icon={"exclamation-triangle"} color="red" style={{"marginLeft": "0.5em"}} />
                                        <span className="error" style={{"paddingLeft": "0.5em"}}>
                                            Stale!!
                                        </span>
                                    </>
                                }
                            </div>
                        );
                    })}
                    {!loading && !!orders && orders.length === 0 && <div> No Active orders found</div>}
                </div>
            </section>
        );
}

const DatasetPage = () => {
    const {datasetId} = useParams();
    const history = useHistory();

    return (
        <FirestoreContainer key={datasetId}
            queryMapFn={db => {
                const datasetDoc = db.collection("datasets").doc(datasetId);
                return {
                    datasetDoc: datasetDoc
                };
            }}
            mutators={db => {
                return {
                    archiveDataset: () =>
                        db
                            .collection("datasets")
                            .doc(datasetId)
                            .update("state", "archived"),
                    updateDataset: dataset =>
                        db
                            .collection("datasets")
                            .doc(datasetId)
                            .set(removeRefandId(dataset), {merge: true})
                            .then(() => {
                                console.log(datasetId, "updated");
                                history.push(`/datasets`);
                            })
                            .catch(err =>
                                console.log(
                                    datasetId,
                                    "failed to update",
                                    err
                                )
                            )
                };
            }}
            defaultViewState={{
                settingState: false,
            }}
            viewStateMutators={setDatasetState => {
                return {
                    setState: isSettingState =>
                        setDatasetState({settingState: isSettingState}),
                };
            }}
        >
            <Dataset/>
        </FirestoreContainer>
    );
};

const Dataset = ({datasetDoc, archiveDataset, setState, settingState, updateDataset}) => {

    const {datasetId} = useParams();
    const [editMode, setEditMode] = useState(false);

    if (loading(datasetDoc)) {
        return <Spinner/>
    } else {
        let dataset = datasetDoc.data[0];
        let archived = dataset.state === "archived";
        return (<DocumentTitle title={`Dataset - ${dataset.name}`}>
            {editMode ?
                <CreateDataset
                    editMode={editMode}
                    setEditMode={setEditMode}
                    dataset={dataset}
                    updateDataset={updateDataset}
                /> : (
                <section>
                    <div className="buttons">
                        {!editMode && (
                            <button
                                className="callToAction"
                                onClick={() => {
                                    setEditMode(true);
                                }}> Edit
                            </button>
                        )}
                        {!archived && (
                            <button
                                disabled={archived && settingState}
                                className="danger callToAction"
                                onClick={() => {
                                    setState(true);
                                    archiveDataset().then(() => setState(false));
                                }}><FontAwesomeIcon icon="eye-slash"/> Archive
                            </button>
                        )}
                    </div>
                    <h3>Dataset Details</h3>
                    <div className="dataset">
                        {dataset.state === "archived" && <p>This dataset is archived</p>}
                        <dl>
                            <dt>Name</dt>
                            <dd>{dataset.name}</dd>
                            <dt>Product</dt>
                            <dd>{dataset.product}</dd>
                            {dataset.initialMaxLoadTimestamp &&
                            <div>
                                <dt>Initial MaxLoad Timestamp</dt>
                                <dd>{dataset.initialMaxLoadTimestamp} days</dd>
                            </div>}
                            {dataset.pubSubTopic &&
                            <div>
                                <dt>PubSub Topic</dt>
                                <dd>{dataset.pubSubTopic.topic}</dd>
                                <dt>PubSub Topic Filters</dt>
                                <dd>{JSON.stringify(dataset.pubSubTopic.filters)}</dd>
                            </div>}
                            <dt>ProgressType</dt>
                            <dd>{dataset.progressType}</dd>
                            {dataset?.lastUpdatedOn && (
                                <>
                                    <dt>Last Updated</dt>
                                    <dd>{moment(dataset?.lastUpdatedOn.toDate()).format("DD MM YYYY hh:mm:ss")}</dd>
                                </>
                            )}


                        </dl>
                        <h5>Base Query</h5>
                        <code className="sql">
                            {dataset.baseQuery && format(dataset.baseQuery)}
                        </code>
                        <h5>Progress Query</h5>
                        <code className="sql">
                            {dataset.progressQuery && format(dataset.progressQuery)}
                        </code>
                    </div>
                    <FirestoreContainer key={datasetId}
                        queryMapFn={db => {
                            const datasetDoc = db.collection("datasets").doc(datasetId);
                            const views = db.collection("views").where("dataset" , "==" , datasetDoc);
                            return {
                                views: views,
                                dataset: datasetDoc,
                            };
                        }}
                    >
                        <ViewList/>
                    </FirestoreContainer>
                </section>
                )
            }
        </DocumentTitle>);
    }
};

export default DatasetPage;

