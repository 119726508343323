import React from "react";
import Firestore from "../components/FirestoreContainer";
import CreateClient from "../components/clients/CreateClient";
import ClientList from "../components/clients/ClientList";
import "./CreatePage.css";

const CreateClientPage = () => {
    return (
        <Firestore
            queryMapFn={db => {
                return {
                    clients: db.collection("clients"),
                };
            }}
            mutators={db => {
                return {
                    addClient: client => db.collection("clients").add(client),
                };
            }}
        >
            <CreateClientLayout />
        </Firestore>
    );
};

const CreateClientLayout = ({ clients, addClient }) => {
    return (
        <div className="createPage">
            <CreateClient addClient={addClient} clients={clients} />
            <ClientList clients={clients} />
        </div>
    );
};

export default CreateClientPage;
