import React, {Component} from "react";
import Spinner from "../spinner/Spinner";
import Client from "./Client";
import {loading} from "../FirestoreContainer";
import {activeOrder} from "./clientList-methods";

class ClientList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: "",
            onlyActive: true,
        }
    }

    updateFilter(e) {
        try {
            this.setState({filter: new RegExp(e.target.value.toLowerCase())})
        } catch (e) {
            console.error("Failed to update filter: ", e)
        }
    }

    filterChange(e) {
        const checked = e.target.checked;
        this.setState({onlyActive: checked})
    }

    render() {
        const title = this.props.title || "All Clients";
        const clients = this.props.clients;
        const orders = this.props.orders;

        if (loading(clients) || loading(orders)) {
            return <Spinner/>
        }

        const ordersByClientName = orders.data
            .filter(order => activeOrder(order))
            .reduce((acc, curr) => {
                acc[curr.clientName] = [curr, ...(acc[curr.clientName] || [])]
                return acc
            }, {})

        const filteredClients = clients.data
            .filter(client => client.name.toLowerCase().match(this.state.filter))
            .map(client => Object.assign(client,
                {
                    "orders":
                        ordersByClientName[client.name.toLowerCase().replace(/[^0-9a-z_]/g, "")]
                }
            ))
            .filter(client => !this.state.onlyActive ||
                (client.state !== "archived" &&
                    client.orders &&
                    client.orders.length > 0))

        const filterChange = this.filterChange.bind(this);
        return (
            <section className="clientList">
                <h2>{title}</h2>
                <div>
                    <input type="search" placeholder={"Search by (part of) clientname"} className="filter"
                           style={{width: "20em"}}
                           name={"updateFilter"}
                           onChange={this.updateFilter.bind(this)}
                    />
                </div>
                <label className="switch">
                    Only active clients
                    <input id="filterActive" type="checkbox"
                           name="active" onChange={filterChange}
                           checked={this.state.onlyActive}/>
                    <span className="slider round"/>
                </label>
                <div className="consoleActions">
                    {filteredClients
                        .sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
                        .map(c => <Client key={c._id} client={c}/>)
                    }
                </div>
                <p>{filteredClients.length} clients listed</p>
            </section>
        );
    }
}

export default ClientList;
