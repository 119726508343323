import moment from "moment";

export function curlyDateFmt(path, legacyFormat, date) {
    const time = moment(date);
    if (legacyFormat) {
        path = path
            // for backwards compatibility with historic orders
            .replace(/2006/g, "{{year}}")
            .replace(/01/g, "{{month}}")
            .replace(/02/g, "{{day}}")
            .replace(/15/g, "{{hour}}")
            .replace(/04/g, "{{minute}}")
            .replace(/05/g, "{{second}}")
    }
    return path
        .replace(/{{year}}/, time.format("YYYY"))
        .replace(/{{month}}/, time.format("MM"))
        .replace(/{{month_literal}}/, time.format("MMMM").toLowerCase())
        .replace(/{{prev_month_literal}}/, time.subtract(1, 'months').format('MMMM').toLowerCase())
        .replace(/{{day}}/, time.format("DD"))
        .replace(/{{hour}}/, time.format("HH"))
        .replace(/{{minute}}/, time.format("mm"))
        .replace(/{{second}}/, time.format("ss"))
}

