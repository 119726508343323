import React, { Component } from "react";

class CreateClient extends Component {
    constructor() {
        super();
        this.state = {
            newClient: {
                name: "",
                state: "active"
            },
        };
    }

    formSubmitted(e) {
        e.preventDefault();
        let clientAlreadyExists = false;
        this.props.clients.data.map(client => {
            if (client.name.toLowerCase() === this.state.newClient.name.toLowerCase()) {
               clientAlreadyExists = true;
            }
            return client;
        });

        if (clientAlreadyExists) {
            alert("NB! Client already exists");
        } else {
            console.log(this.state.newClient);
            this.props.addClient(this.state.newClient)
                .then(() => {
                    alert("Client was created!");
                    window.location.reload();
                })
                .catch(err => {
                    console.log(arguments);
                    console.log(err);
                });
        }
    }

    formChanged(e) {
        this.setState({
            newClient: {
                ...this.state.newClient,
                [e.target.name]: e.target.value,
            },
        });
    }

    render() {
        const onChange = this.formChanged.bind(this);
        const newClient = this.state.newClient;

        return (
            <section className="createClient">
                <h2>Create New Client</h2>
                <form onSubmit={this.formSubmitted.bind(this)}>
                    <h3>Client</h3>
                    <div className="fieldset">
                        <span className="field">
                            <label htmlFor={"Name"}>Client Name</label>
                            <input
                                name={"name"}
                                placeholder={"Name this client"}
                                onChange={onChange}
                                value={newClient.Name}
                                required={true}
                            />
                        </span>
                    </div>
                    <div className="buttons">
                        <input
                            type={"submit"}
                            value={"Create Client"}
                            className="callToAction"
                        />
                    </div>
                </form>
            </section>
        );
    }
}

export default CreateClient;
