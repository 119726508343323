import React, {Component} from "react";
import FirestoreContainer, {loading} from "../components/FirestoreContainer";
import Spinner from "../components/spinner/Spinner";
import {ViewPicker, ViewSummaryCompact} from "../components/views";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DisplayIf from "../components/displayif/DisplayIf";

const ViewPickerContainer = ({onPick, view, client}) => {
    return (
        <FirestoreContainer key={client._ref}
            queryMapFn={db => {
                return {
                    clientViews: db
                        .collection("views")
                        .where("archived", "==", false)
                        .where("client", "==", client._ref),
                    views: db
                        .collection("views")
                        .where("archived", "==", false)
                        .where("client", "==", null),

                };
            }}
            defaultViewState={{
                showPicker: false,
            }}
            viewStateMutators={setViewState => {
                return {
                    openPicker: e => {
                        e.preventDefault();
                        setViewState({showPicker: true});
                    },
                    closePicker: () => {
                        setViewState({showPicker: false});
                    },
                };
            }}
        >
            <ViewPickerLoader onPick={onPick} view={view}/>
        </FirestoreContainer>
    );
};

class ViewPickerLoader extends Component {

    render() {
        let {
            views,
            clientViews,
            view,
            onPick,
            openPicker,
            closePicker,
            showPicker,
        } = this.props;

        if (!!view) {
            return (<div className="summaryWithActions">
                <ViewSummaryCompact view={view}>
                    <FontAwesomeIcon className="closeButton" icon="times-circle" onClick={() => onPick(null)}/>
                </ViewSummaryCompact>
            </div>);
        }

        return (
            <div>
                <DisplayIf condition={!showPicker}>
                    <button onClick={openPicker}>Pick View</button>
                </DisplayIf>

                <DisplayIf condition={showPicker}>
                <div className="viewPickerList">
                    {loading(views) || loading(clientViews) ? (
                        <Spinner/>
                    ) : (
                        <ViewPicker
                            views={clientViews.data.concat(views.data)}
                            onPick={view => {
                                onPick(view);
                                closePicker();
                            }}
                        />
                    )}
                </div>
                </DisplayIf>
            </div>
        );
    }

}

export default ViewPickerContainer;
