import React, {Component} from 'react';
import FilenameFormPartial from "./FilenameFormPartial";
import ShowAdvancedFromPartial from "./ShowAdvancedFromPartial";
import DisplayIf from "../../displayif/DisplayIf";

class FileConfigFormPartial extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showAdvanced: false
        }
    }

    render() {
        const {newOrder, setField, onChange} = this.props;

        const hasLegacyFolderPath = newOrder.folderPath.match(/2006|01|02|15/g);

        return (<fieldset>
            <h3>Transferred Files</h3>
            <FilenameFormPartial newOrder={newOrder} onChange={onChange}/>
            <ShowAdvancedFromPartial newOrder={newOrder} setField={setField} onChange={onChange}/>

            <h3>File placement</h3>
            <span className="field">
                <label htmlFor="folderPath">Sub folder</label>
                <span>
                    <input
                        type="text"
                        name="folderPath"
                        style={hasLegacyFolderPath && {borderColor: 'var(--theme-color-error)'}}
                        placeholder="{{year}}/{{month}}/{{day}}/{{hour}}/"
                        value={newOrder.folderPath}
                        onChange={onChange}
                        required={true}
                    />
                    <DisplayIf condition={hasLegacyFolderPath}>
                        <span className="fieldComment">
                            <strong>You have a folder name that contains numerals! Is this intentional?
                            The old golang style <code>2006/01/02/15</code> date based folder naming is
                            deprecated! The numeral parts of the folder name will be take literally.</strong>
                        </span>
                    </DisplayIf>
                    <span className="fieldComment">
                            You can insert datetime values in the Sub folder path by using variables on the form
                            <code>&#123;&#123;timefield}}</code> where timefield can be any of year, month, day, hour,
                            minute or second.<br/>
                            Ex: <code>&#123;&#123;year}}/&#123;&#123;month}}/&#123;&#123;day}}/&#123;&#123;hour}}/</code>
                    </span>
                </span>
            </span>
        </fieldset>)
    }

}

export default FileConfigFormPartial;
