import React from "react";
import "./OrderDesignSubmit.css";
import { NavLink } from "react-router-dom";
import Spinner from "../spinner/Spinner";

const OrderDesignSubmit = ({
    onSubmitToShipit,
    onDiscard,
    submittable,
    working,
}) => {
    return (
        <div
            className={`panel ${working &&
                "orderDesignSubmit-loading"}`}
        >
            {working && (
                <div className="orderDesignSubmit-overlay">
                    <Spinner />
                </div>
            )}
            <p>
                Submitting an order for execution will mark the exact
                specification described above for execution.
            </p>
            <p>
                Have another pair of eyes look over the specification before
                submition by sharing <NavLink to="review">{"this"}</NavLink>{" "}
                link.
            </p>
            <div className="buttons">
                <button
                    disabled={working || !submittable}
                    className=""
                    onClick={onDiscard}
                >
                    Discard Draft
                </button>
                <button
                    disabled={working || !submittable}
                    type="submit"
                    className="callToAction"
                    onClick={onSubmitToShipit}
                >
                    Place Order
                </button>
            </div>
        </div>
    );
};

export default OrderDesignSubmit;
