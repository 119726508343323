import React, {Component} from 'react'
import ShipmentEvents from "../shipments/ShipmentEvents";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import moment from 'moment';

import './ShipmentsList.css'
import FilePlacement from "../orderflow/FilePlacement";
import {first} from "lodash";

class ShipmentsList extends Component {

    constructor(props) {
        const {shipments} = props
        super(props);
        this.state = {
            selectedShipmentId: shipments.length > 0 ? shipments[0].id : null,
            numShownShipments: 3
        }
    }


    componentDidMount() {
        const {designs} = this.props
        const design = first(designs.data);
        design.target.get().then((value) => {
            this.setState({
                target: value.data()
            })
        })
    }

    selectShipment(shipment) {
        this.setState({selectedShipmentId: shipment.id, detailsExpanded: true})
    }

    showMoreShipments() {
        this.setState({numShownShipments: this.state.numShownShipments + 3})
    }

    render() {
        let {shipments, designs} = this.props;
        const design = first(designs.data);
        let shownShipments = shipments.slice(0, this.state.numShownShipments)
        const showShipment = shipments.length > 0
            && shipments.find(s => s.id === this.state.selectedShipmentId)
        return (
            <section className="shipmentsView">
                <div className="shipmentsList">
                    <h3>Shipments</h3>
                    {(!shipments || shipments.length === 0) && (<div>No shipments exist for this order yet.</div>)}
                    {shownShipments.length > 0 && (
                        <section>
                            <ul className="panelList">
                                {shownShipments.map(shipment => <ShipmentSummary
                                    selected={this.state.selectedShipmentId === shipment.id}
                                    key={shipment.id}
                                    shipment={shipment}
                                    design={design}
                                    target={this.state.target}
                                    onclick={() => this.selectShipment(shipment)}
                                />)}
                            </ul>
                            {shownShipments.length < shipments.length &&
                            <button onClick={this.showMoreShipments.bind(this)}>Show older</button>
                            }

                        </section>
                    )}
                </div>

                <div className="shipmentDetails">
                    {showShipment && <ShipmentEvents shipment={showShipment}/>}
                </div>
            </section>
        )
    }
}

const ShipmentSummary = ({selected, shipment, onclick, design, target}) => {
    const icons = {
        "failed": "exclamation-triangle",
        "complete": "check-circle",
        "aborted": "times",
        "default": "circle-notch",
    };


    let icon = icons[shipment.status] || icons.default;
    let inProgress = ["failed", "complete", "aborted"].indexOf(shipment.status) === -1;
    return (<li className={`panel shipmentSummary clickable ${selected && "selected"}`} onClick={onclick}>
        <h4>{moment().to(moment(shipment.started))}</h4>
        <div className={"shipmentSummaryDetails"}>
            <FontAwesomeIcon icon={icon} className={"shipmentStatusBadge " + shipment.status} spin={inProgress}/>
            <dl>
                <dt>Status</dt>
                <dd>{shipment.status}</dd>
                <dt>Started</dt>
                <dd>{moment(shipment.started).format("YYYY-MM-DD HH:mm:ss")}</dd>
                <dt>Completed</dt>
                <dd>{shipment.completed && moment(shipment.completed).format("YYYY-MM-DD HH:mm:ss")}</dd>
                <FilePlacement design={design} selectedShipment={shipment} created={design.createTime} target={target} exactFolder={true}/>
            </dl>
        </div>
    </li>)
};

export default ShipmentsList;
