import React from "react";
import moment from "moment";

const GBi = 1024.0 * 1024 * 1024;
const TimeLineEvent = ({event}) => {
    let details;
    switch (event.type) {
        case "exported":
            details = (
                <dl>
                    <dt>Rows exported</dt>
                    <dd>{parseInt(event.details.numRows, 10).toLocaleString("en")}</dd>
                    {event.details.cost !== undefined && (
                        <span>
                            <dt>Cost</dt>
                            <dd>${parseFloat(event.details.cost).toFixed(2).toLocaleString("en")}</dd>
                        </span>

                    )}
                </dl>
            );
            break;
        case "transferred":
            details = (
                <dl>
                    <dt>Files transferred</dt>
                    <dd>{parseInt(event.details.numFiles, 10).toLocaleString("en")}</dd>
                    <dt>GB transferred</dt>
                    <dd>{(parseInt(event.details.bytesTransferred, 10) / GBi).toFixed(3).toLocaleString("en")}</dd>
                    <dt>Cost</dt>
                    <dd>${parseFloat(event.details.cost).toFixed(2).toLocaleString("en")}</dd>
                </dl>
            );
            break;
        case "complete":
            details = (
                <dl>
                    <dt>Snapshot taken</dt>
                    <dd>{event.details.snapshotTaken ? "yes" : "no"}</dd>
                    <dt>Device report generated</dt>
                    <dd>{event.details.deviceReportCreated ? "yes" : "no"}</dd>
                    <dt>Success file generated</dt>
                    <dd>{event.details.successFileGenerated ? "yes" : "no"}</dd>
                </dl>
            );
            break;
        case "aborted":
            details = (
                <dl>
                    <dt>Aborted by</dt>
                    <dd>{event.details.user}</dd>
                    <dt>Comment</dt>
                    <dd>{event.details.comment}</dd>
                </dl>
            );
            break;
        case "failed":
            details = (
                <span className="failedEvent">{event.details.errorMessage}</span>
            );
            break;
        default:
            details = ""
    }

    return (<li key={event.id}>
        <div className="event">
            <div className="eventTime">{moment(event.time).format("HH:mm:ss")}</div>
            <h4 className="eventType">{humanizeEventName(event.type)}</h4>
            {details && (<div className="eventDetails">
                {details}
            </div>)}
        </div>
    </li>)
};

export default TimeLineEvent


function humanizeEventName(eventType) {
    const names = {
        "available":"Shipment registered",
        "processing":"Exporting data to files",
        "exported":"Export complete",
        "transferring":"Transferring files to client",
        "transferred":"Files transferred",
        "completing":"Generating reports",
        "complete":"Shipment complete",

    }
    return names[eventType.toLowerCase()] || eventType;
}
