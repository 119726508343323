import React, {Component} from 'react';

import './ViewSummary.css';

class ViewSummaryCompact extends Component {
    render() {
        const view = this.props.view

        const cssClasses = [
            "viewSummary compact panel",
            (!!this.props.onClick ? "clickable" : ""),
            (view.archived ? "archived" : "")
        ].join(" ").trim();

        return (
            <div className="viewSummaryAndActions">
                <div className={cssClasses} onClick={this.props.onClick}>
                    <span>
                    <strong>{view.name}</strong><br/>
                    <span className="viewListDescription">
                        {view.description}
                    </span>
                        {view.client && <div className="clientSpecific">Client specific</div>}
                        {view.archived && <div className="archivedDetail">Archived</div>}
                    </span>
                </div>
                <div className="actions">
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default ViewSummaryCompact;
