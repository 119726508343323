import {useLocation} from "react-router-dom";

const DisplayIf = ({condition, children}) => {
    const query = useQuery()
    const displayAll = query.get("displayAll")

    if (!condition && !displayAll) {
        return null
    }

    return children
};

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default DisplayIf;
