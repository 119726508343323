import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import './ViewSummary.css';

class ViewSummary extends Component {
    render() {
        const view = this.props.view;
        return <div
            className={[
                "panel",
                (view.filtered ? "filtered" : ""),
                (view.archived ? "archived" : "")
            ].join(" ").trim()}
        >
           <NavLink
                to={`/views/${this.props.viewId}`}>
               <h4>{view.name}</h4>
           </NavLink>
            <h5>Product: {view.product}</h5>
            <div className="viewListDescription">{view.description}</div>
            {view.client && <div className="clientSpecific">Client Specific: <NavLink to={`clients/${view.clientId}`}>{view.clientName}</NavLink></div>}
            {view.archived && <div className="archivedDetail">Archived</div>}
        </div>
    }

}

export default ViewSummary;
