import React, {Component} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {abortShipment} from './shipment-methods';

class AbortButton extends Component {
    constructor() {
        super();
        this.state = {working: false, failed: false}
    }

    abort(shipmentId) {
        this.setState({working: true});
        const comment = prompt("Why is this shipment being aborted?");
        abortShipment(shipmentId, comment)
            .then(() => {
                this.setState({working: false, failed: false})
            })
            .catch(err => {
                console.log(`Abort err: ${err}`);
                this.setState({working: false, failed: true})
            });
    }

    icon() {
        return this.state.failed ? "exclamation-triangle" : "times"
    }

    render() {
        return <button type={"button"} className={"callToAction tooltip-multiline tooltip-bottom-left"}
                       data-tooltip="Abort the order, will enable the Replace order button"
                       onClick={() => this.abort(this.props.shipment.id)}>
            <FontAwesomeIcon icon={this.icon()} spin={this.state.working}/> Abort
        </button>
    }

}

export default AbortButton;
