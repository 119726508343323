import React, {Component} from 'react';
import cronstrue from "cronstrue/dist/cronstrue";
import moment from 'moment'

function humanizeSchedule(schedule) {
    if (!schedule.cronExpression) {
        return schedule.firstShipment;
    }
    let description = cronstrue.toString(schedule.cronExpression);

    let first;
    if (moment(schedule.firstShipment).isAfter(moment.now())) {
        first = <span
            className="firstShipmentLater"><br/>first time at {moment(schedule.firstShipment).format("YYYY-MM-DD HH:mm")}</span>;
    }

    return <span>{description}{first}</span>;
}

class Schedule extends Component {
    render() {
        const schedule = this.props.schedule;
        let scheduleElm = ""
        if (!!schedule && (!!schedule.cronExpression || !!schedule.firstShipment)) {
            scheduleElm = <span>
                <dt>Schedule</dt>
                <dd>{humanizeSchedule(schedule)}</dd>
            </span>
        }

        return scheduleElm
    }

}

export default Schedule;
