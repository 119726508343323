import React, {Component} from "react";
import "./Targets.css";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import Collapsible from "../collapsible/Collapsible";
import examplePolicyFile from './s3-example-policy.json';

class TargetPolicyList extends Component {
    constructor() {
        super();
        this.state = {
            openPolicyList: false,
            copiedToClipboard: false,
        };
    }

    copyPoliciesToClipboard() {
        let clipText = JSON.stringify(examplePolicyFile);
        navigator.clipboard.writeText(clipText).then(
            () => this.setState({copiedToClipboard: true})
        )
    }

    render() {
        const togglePolicyList = this.props.togglePolicyList;

        return (
                <span>
                    <div className="policy-button-container">
                        {this.state.openPolicyList &&
                        <button type="button" onClick={togglePolicyList.bind(this)}>
                            Hide Policy List  <FontAwesomeIcon icon="chevron-up"/>
                        </button>}
                        {!this.state.openPolicyList &&
                        <button type="button" onClick={togglePolicyList.bind(this)}>
                            Show Policy List  <FontAwesomeIcon icon="chevron-down"/>
                        </button>}

                    </div>
                    <Collapsible open={this.state.openPolicyList}>
                        <ul className="policy-list">
                            <li><b>s3:GetBucketLocation</b></li>
                            <li><b>s3:ListBucket</b> - Required for the folders we are uploading data to to verify that we are not overwriting an existing object.</li>
                            <li><b>s3:GetObject</b> - Required together with s3:ListBucket to verify that a file does not exist in the target destination.</li>
                            <li><b>s3:PutObject</b> - Upload an object</li>
                            <li><b>s3:DeleteObject</b> - Delete objects from destination</li>
                        </ul>
                        <div className="policy-button-container">
                            <button onClick={this.copyPoliciesToClipboard.bind(this)}>
                                <FontAwesomeIcon icon="copy"/> {this.state.copiedToClipboard ? "Copied!" : "Copy policies to clipboard (as json)"}
                            </button>
                        </div>
                    </Collapsible>
                </span>
        );
    }
}

export default TargetPolicyList;
