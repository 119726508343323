import React, {Component} from 'react';
import DisplayIf from "../../displayif/DisplayIf";
import FileConfigFormPartial from "./FileConfigFormPartial";

class SampleFormPartial extends Component {
    render() {
        const {newOrder, setField, onChange} = this.props;
        return <DisplayIf condition={!!newOrder.target && !!newOrder.view}>
            <FileConfigFormPartial newOrder={newOrder} onChange={onChange} setField={setField}/>
        </DisplayIf>
    }

}

export default SampleFormPartial;
