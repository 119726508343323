import React, {Component} from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {rerunShipmentFromStatus} from './shipment-methods'

class ResumeButton extends Component {
    constructor(props) {
        super(props);
        this.state = {working: false, failed: false}
    }

    resume(shipmentId, fromStatus) {
        this.setState({working: true});
        rerunShipmentFromStatus(shipmentId, fromStatus)
            .then(() => {
                this.setState({working: false, failed: false})
            })
            .catch(err => {
                console.log(['Resume err',err]);
                this.setState({working: false, failed: true})
            });
    }

    icon() {
        return this.state.failed ? "exclamation-triangle":"redo-alt"
    }

    render() {

        return (
            <button type={"button"} className={"callToAction tooltip-multiline tooltip-bottom-left"}
                    data-tooltip="Resume means re-running the order from the step that failed"
                    onClick={() => this.resume(this.props.shipmentId, this.props.fromStatus)}>
                <FontAwesomeIcon icon={this.icon()} spin={this.state.working}/> Resume
            </button>
        );
    }
}

export default ResumeButton;
