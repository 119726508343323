import React, {Component} from 'react';

import {DropdownList} from "react-widgets";
import {loading} from "../../FirestoreContainer";

class Dropdown extends Component {
    render() {
        const {
            values, data, disabled, placeholder, textField, valueField, value, onChange,
        } = this.props;

        let choices = data;
        let isLoading = false;
        if (!!values) {
            choices = loading(values) ? [] : values.data;
            isLoading = loading(values);
        }
        return (
            <DropdownList
                busy={isLoading}
                data={choices}
                placeholder={placeholder}
                textField={textField}
                valueField={valueField}
                onChange={onChange}
                disabled={disabled}
                value={value}
            />
        );
    }

}

export default Dropdown;
