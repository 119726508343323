import React, {Component} from 'react'

import {first} from "lodash";
import './OrderPage.css'
import DocumentTitle from 'react-document-title'

import Firestore, {loading} from "../components/FirestoreContainer";
import Spinner from "../components/spinner/Spinner";
import OrderDetails from "../components/orderflow/OrderDetails";
import Activity from "../components/activity/Activity";
import ShipmentsList from "../components/shipmentslist/ShipmentsList";
import {useParams} from "react-router-dom";


const OrderPage = () => {
    const {orderId} = useParams();
    return (
        <Firestore
            key = {orderId}
            queryMapFn={db => {
                const orderRef = db.collection("ordercache").doc(orderId);
                return {
                    orderdesigns: db
                        .collection("orderdesign")
                        .where("orderRef", "==", orderRef),
                    activity: db
                        .collection("activity")
                        .where("orderRef", "==", orderRef),
                    orders: orderRef,
                };
            }}>
            <OrderPageWithoutFireBaseContext/>
        </Firestore>
    );
};

class OrderPageWithoutFireBaseContext extends Component {

    render() {
        if (loading(this.props.orders) || loading(this.props.orderdesigns) || loading(this.props.activity)) {
            return <DocumentTitle title="Fetching order..."><Spinner>Fetching order...</Spinner></DocumentTitle>
        }
        const order = first(this.props.orders.data);
        let title = `${order.name} (${order.clientName})`
        let lastShipmentNotComplete = !!order.shipments && order.shipments.length > 0 && !["complete", "aborted"].includes(order.shipments[0].status);
        if (lastShipmentNotComplete) {
            title = `[${order.shipments[0].status.toUpperCase()}] ${title}`;
        }
        return (<DocumentTitle title={title}>
            <section className="orderPage">
                <Activity activity={first(this.props.activity.data)}/>
                <OrderDetails designs={this.props.orderdesigns} order={order} lastShipmentNotComplete={lastShipmentNotComplete}/>
                <ShipmentsList shipments={order.shipments} designs={this.props.orderdesigns}/>
            </section>
        </DocumentTitle>);
    }
}

export default OrderPage;

