import React, {Component} from 'react';
import {dryRun, validateQuery} from "./view-methods";
import {uniq} from 'lodash'
import moment from 'moment';

import "./QueryValidator.css"

import Panel from "../panel/Panel";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {checkForComplexFields} from "./queryValidation"

const defaultVariableValues = {
    maxLoadTable: moment().subtract(2, "day").format("YYYYMMDDHHmm"),
    maxLoadTimestamp: moment().subtract(1, "day").format("YYYY-MM-DD 00:00:00"),
    shipmentDueTime: moment().subtract(2, "day").format("YYYY-MM-DD 00:00:00"),
    previousShipmentDueTime: moment().subtract(1, "day").format("YYYY-MM-DD 00:00:00"),
    minReleaseTimestamp: moment().subtract(2, "day").format("YYYYMMDDHHmmss"),
    maxReleaseTimestamp: moment().subtract(1, "day").format("YYYYMMDDHHmmss"),
    clientName: "Unacast",
};

class QueryValidator extends Component {

    constructor(props) {
        super(props);
        this.state = {
            working: false,
            valid: false,
            validated: false,
            cost: 0,
            validationError: "",
            blackilistValidationError: false,
        }
    }

    dryRun() {
        this.setState({working: true});
        const {query, progressType} = this.props;
        const [valid, progressTypeError] = validProgressType(query, progressType)
        if (!valid) {
            this.setState({
                working: false,
                valid: false,
                validated: true,
                validationError: progressTypeError
            })
            return
        }
        this.validateAndDryRunQuery(query, this.fail.bind(this), this.success.bind(this))
    }

    fail(msg) {
        this.setState({
            working: false,
            valid: false,
            validated: true,
            cost: undefined,
            validationError: msg
        });
        !!this.props.onFail && this.props.onFail(msg)
    }

    success(results) {
        const hasComplexFields = checkForComplexFields(results.query.schema);
        let cost;
        if (results.totalBytesProcessed) {
            const processed = results.totalBytesProcessed;
            const TBi = (1024 * 1024 * 1024 * 1024);
            const costPerTBi = 6.2;
            cost = costPerTBi * processed / TBi;
        } else {
            cost = 0;
        }

        this.setState({
            working: false,
            valid: true,
            validated: true,
            cost: cost
        });
        !!this.props.onSuccess && this.props.onSuccess({...results, hasComplexFields: hasComplexFields});
    }

    renderReplacements() {
        const replacements = variableReplacements(this.props.query);

        const replacedVars = Object.keys(replacements);
        if (replacedVars.length === 0) {
            return null;
        }
        const elms = replacedVars.map(variableName => {
            const replacement = replacements[variableName];
            if (variableName === "maxLoadTable") {
                variableName += " [DEPRECATED]"
            }
            return [<dt key={variableName}>{variableName}</dt>,
                <dd key={variableName + "-val"}>{replacement}</dd>];
        });
        return <div>
            <strong>Variable values used for cost calculation</strong><br/>
            <dl>{elms}</dl>
        </div>
    }

    renderValid() {
        return (
            <Panel className="ok">
                {this.renderReplacements.bind(this)()}
                <strong><FontAwesomeIcon icon="check-circle"/> Query valid!</strong>
                <dl>
                    <dt>Estimated Cost</dt>
                    <dd>${this.state.cost.toFixed(2)}</dd>
                </dl>
                <br/>
            </Panel>
        );
    }

    renderInvalid() {
        return (
            <Panel className="error">
                {this.renderReplacements.bind(this)()}
                <strong><FontAwesomeIcon icon="times"/> Query invalid</strong>
                <p>{this.state.validationError}</p>
            </Panel>
        );
    }

    validateQueryText(query){
        if (query.match('FROM[ ]*\n?.*\..*\..*(pure|activities).*') && !query.includes("uc-prox-bumpmap.pure_config.pure_blacklisted_clients_materialized")){
            this.setState({
                blackilistValidationError: true
            })
        }
        else
            this.setState({blackilistValidationError: false})
    }

    TIMEOUT_TRYING_TO_VALIDATE_VIEW = "network timeout";
    validateAndDryRunQuery(q, onFail, onSuccess) {
        try {
            const rendered = renderQueryTemplate(q);
            if(this.props.validateBlacklistWhereClause)
                this.validateQueryText(rendered);
            dryRun(rendered)
                .then(result => {
                    !!onSuccess && onSuccess(result)
                })
                .catch(err => {
                    if (err.response) {
                        if (err.response.body.message.includes(this.TIMEOUT_TRYING_TO_VALIDATE_VIEW)) {
                            console.log(`network timeout`,)
                            // Retrying to validate the query
                            validateQuery(rendered)
                                .then(result => {
                                    console.log(`got back from validate`,result)
                                    !!onSuccess && onSuccess(result)
                                })
                        }else {
                            !!onFail && onFail(err.response.body.message)
                        }
                        
                    } else {
                        !!onFail && onFail(err)
                    }
                });
        } catch (e) {
            onFail(e.message);
        }
    }

    render() {
        const {query} = this.props;
        const {valid, validated, working} = this.state;

        return <div className="queryValidatorOuter">
            {this.state.blackilistValidationError &&
                <Panel className={"error"}>
                    <FontAwesomeIcon icon="fa-solid fa-exclamation"/>
                    <span>
                            Make sure to add the following to the WHERE clause to filter blacklisted clients:
                            <code>WHERE/AND internal_partner_name NOT IN (SELECT partner_name from `uc-prox-bumpmap.pure_config.pure_blacklisted_clients_materialized` WHERE client_name = '&#123;&#123;.clientName&#123;&#123;')</code>
                        </span>
                </Panel>

            }
            <div className="queryValidator">
                <button
                    disabled={!query}
                    type="button"
                    onClick={this.dryRun.bind(this)}>
                    Validate &amp; Check cost
                </button>
                {!working && validated && valid && this.renderValid.bind(this)()}
                {!working && validated && !valid && this.renderInvalid.bind(this)()}
                {!validated && !working && <Panel>
                    {this.renderReplacements.bind(this)()}
                    <FontAwesomeIcon icon="hand-point-left"/>Click to run validation
                </Panel>}
                {working && <Panel>
                    {this.renderReplacements.bind(this)()}
                    <FontAwesomeIcon icon="cog" spin={true}/>Running validation and cost check...
                </Panel>}
            </div>
        </div>
    }
}

export function validProgressType(query, progressType) {
    let valid
    let errorMsg = ""
    const hasTimestamp = query.includes("maxLoadTimestamp")
    const hasTable = query.includes("maxLoadTable")
    switch (progressType) {
        case "timestamp":
            valid = hasTimestamp && !hasTable
            errorMsg = hasTimestamp ? "" : `The query didn't contain maxLoadTimestamp even though the ProgressType is ${progressType}`
            errorMsg += !hasTable ? "" : ` The query did contain maxLoadTable even though the ProgressType is ${progressType}`
            break
        case "table":
            valid = !hasTimestamp && hasTable
            errorMsg = hasTable ? "" : ` The query didn't contain maxLoadTable even though the ProgressType is ${progressType}`
            errorMsg += !hasTimestamp ? "" : `The query did contain maxLoadTimestamp even though the ProgressType is ${progressType}`
            break
        case "none":
            valid = !hasTable && !hasTimestamp
            errorMsg = valid ? "" : `The query did contain maxLoadXXX even though the ProgressType is ${progressType}`
            break
        default:
            valid = true
    }
    return [valid, errorMsg];
}

function variablesInTemplate(template) {
    // language=JSRegexp
    const rex = new RegExp(`{{\\.([^}]+)}}`, "g");
    const variables = [];
    let hits;
    while ((hits = rex.exec(template)) !== null) {
        variables.push(hits[1])
    }
    return uniq(variables)
}

function variableReplacements(template) {
    const replacements = {};
    const vars = variablesInTemplate(template);
    vars.forEach(v => {
        replacements[v] = defaultVariableValues[v] || <span className="error">Missing value</span>
    });
    return replacements;
}

function renderQueryTemplate(template) {
    const vars = variablesInTemplate(template);
    vars.forEach(v => {
        const pattern = new RegExp(`{{\\.(${v})}}`, 'ig');
        const replacement = defaultVariableValues[v];
        if (replacement === undefined) {
            throw new Error(`No default value exists for variable [${v}]`);
        }
        template = template.replace(pattern, replacement);
    });
    return template;
}

export default QueryValidator;
