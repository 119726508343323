import React from "react";
import "./ShipmentEvents.css";
import moment from "moment";
import {findLast, includes} from "lodash";
import Dialog from '@mui/material/Dialog';
import urlJoin from 'proper-url-join';

import ShipmentTimelineEvent from "./ShipmentTimelineEvent"
import Spinner from "../spinner/Spinner"
import ResumeButton from "./ResumeButton.js";
import RestartButton from "./RestartButton";
import AbortButton from "./AbortButton";
import CompleteButton from "./CompleteButton";


function findLastRerunnableStatus(events) {
    const rerunableStates = ['available', 'exported', 'transferred'];
    return findLast(events, (e) => includes(rerunableStates, e.type)).type
}

function isCompletable(events) {
    const completableStates = ['exported', 'transferred'];
    return findLast(events, (e) => includes(completableStates, e.type)) !== undefined
}

function ShipmentEvents({shipment}) {
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    let timeline = [];
    let firstEventTime = shipment.events[0].time;
    for (let eventIndex = 0; eventIndex < shipment.events.length; eventIndex++) {
        const event = shipment.events[eventIndex];

        timeline.push(
            <ShipmentTimelineEvent event={event} key={eventIndex}/>
        );
    }

    let lastEvent = shipment.events[shipment.events.length - 1];
    let timeToComplete = moment(firstEventTime).to(lastEvent.time);
    let filePlacement = "";

    if (shipment.spec) {
        if (shipment.spec.transfer.azure) {
            filePlacement = urlJoin(`https://${shipment.spec.transfer.azure.accountName}.blob.core.windows.net`, shipment.spec.transfer.azure.containerName, {trailingSlash: true});
        } else if (shipment.spec.transfer.sftp) {
            filePlacement = urlJoin(`sftp://${shipment.spec.transfer.sftp.host}`, shipment.spec.transfer.sftp.folder, {trailingSlash: true});
        } else if (shipment.spec.transfer.gcs) {
            filePlacement = urlJoin(`gs://${shipment.spec.transfer.gcs.gcsBucket}`, shipment.spec.transfer.gcs.object, {trailingSlash: true});
        } else if (shipment.spec.transfer.s3) {
            filePlacement = urlJoin(`s3://${shipment.spec.transfer.s3.bucket}`, shipment.spec.transfer.s3.folder, {trailingSlash: true});
        } else if (shipment.spec.transfer.gdrive) {
            filePlacement = urlJoin(`https://drive.google.com/drive/u/0/folders/${shipment.spec.transfer.gdrive.folderId}/${shipment.spec.transfer.gdrive.folder}`);
        }
    }

    const working = !shipment.hanging && ["complete", "failed", "aborted"].indexOf(shipment.status) === -1;

    return (
        <div className="shipmentEvents">
            <h3 className="timelineTitle">Shipment Timeline</h3>
            {shipment.spec && <button onClick={handleOpen}>Show Info</button>}
            <div className="timeline">
                <div className="startTime">{moment(shipment.events[0].time).format("YYYY MMM DD")}</div>
                <ol className="ol-timeline">
                    {timeline}
                </ol>
                {shipment.status === "complete" && (
                    <div className="endTime">Completed {timeToComplete}<br/><RestartButton shipmentId={shipment.id}/>
                    </div>
                )}
                {shipment.status === "aborted" && (
                    <div className="endTime">
                        Shipment Aborted
                    </div>
                )}
                {working && (
                    <Spinner/>
                )}
                {shipment.status === "failed" && (
                    <div className="shipmentFailed">
                        <div className="panel error">
                            Shipment has failed!
                        </div>
                        <RestartButton shipmentId={shipment.id}/>

                        <ResumeButton shipmentId={shipment.id}
                                      fromStatus={findLastRerunnableStatus(shipment.events)}/>
                        {isCompletable(shipment.events) &&
                            <CompleteButton shipmentId={shipment.id}/>
                        }
                        <AbortButton shipment={shipment}/>
                    </div>
                )}
                {!!shipment.hanging && (
                    <div className="shipmentHanging">
                        This shipment seems to be hanging
                        <ResumeButton shipmentId={shipment.id}
                                      fromStatus={findLastRerunnableStatus(shipment.events)}/>
                    </div>
                )}
            </div>
            <Dialog open={open} onClose={handleClose}>
                <div>
                    <h3 className="dialogTitle">Shipment info</h3>
                    <section className="dialogSection">
                        <dl>
                            <dt>File Placement</dt>
                            <dd>{filePlacement}</dd>
                            <dt>Query</dt>
                            <dd>{shipment.spec && <code>{shipment.spec.generate.bigQuery.query}</code>}</dd>
                            <dt>Details</dt>
                            <dd>
                                <code>{JSON.stringify(lastEvent, null, 2)}</code>
                            </dd>
                        </dl>
                    </section>
                </div>
            </Dialog>
        </div>
    );
}


export default ShipmentEvents;
