import React, {Component} from 'react';
import {NavLink, useParams} from "react-router-dom";
import {uniq} from 'lodash';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import FirestoreContainer, {loading} from "../components/FirestoreContainer";
import Spinner from "../components/spinner/Spinner";
import {decryptValue, encryptValue, validateBucket} from "../components/targets/target-methods";
import "./TargetDetailPage.css";
import Panel from "../components/panel/Panel";
import urlJoin from "proper-url-join";

function TargetDetailPage({claims}) {
    const {targetId} = useParams();

    return <FirestoreContainer key={targetId}
                               queryMapFn={db => {
                                   const targetRef = db.collection("targets").doc(targetId);

                                   return {
                                       targetRef
                                   };
                               }}
                               mutators={db => {
                                   return {
                                       archive: targetId => {
                                           if (
                                               window.confirm(`Archive Target with id ${targetId}`)
                                           )
                                               db
                                                   .collection("targets")
                                                   .doc(targetId)
                                                   .update("archived", true)
                                                   .then(() => {
                                                       console.log(targetId, "archived");
                                                   })
                                                   .catch(err =>
                                                       console.log(
                                                           targetId,
                                                           "failed to archive",
                                                           err
                                                       )
                                                   );
                                       },
                                       update: (targetId, field, value) => {
                                           db
                                               .collection("targets")
                                               .doc(targetId)
                                               .update(field, value)
                                               .then(() => {
                                                   console.log(targetId, "updated");
                                               })
                                               .catch(err =>
                                                   console.log(
                                                       targetId,
                                                       "failed to update",
                                                       err
                                                   )
                                               );
                                       }
                                   };
                               }}
    >
        <TargetDetailsContent claims={claims}/>
    </FirestoreContainer>
}

class TargetDetailsContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            validated: false,
            validationRunning: false,
            copyingRunning: false,
            showAccessKey: false,
            isEdit: false,
            updatedTarget: null
        };
    }

    validateBucket() {
        const target = this.props.targetRef.data[0];

        this.setState({validationRunning: true});
        decryptValue(target.secretKey).then(decryptedSecretKey => {
            const targetData = {
                name: target.bucket,
                region: target.region,
                secretkey: decryptedSecretKey,
                accesskey: target.accessKey,
                folder: urlJoin(target.folder, {leadingSlash: false})
            };
            validateBucket(targetData).then(res => {
                const errMsgs = [
                    res.DeleteObject !== "OK" ? "DeleteObject: " + res.DeleteObject.split("\n")[0] : "",
                    res.GetObject !== "OK" ? "GetObject: " + res.GetObject.split("\n")[0] : "",
                    res.PutObject !== "OK" ? "PutObject: " + res.PutObject.split("\n")[0] : "",
                    res.HeadObject !== "OK" ? "HeadObject: " + res.HeadObject.split("\n")[0] : "",
                ].filter(msg => msg !== "").join(" AND ");

                this.setState({
                    validated: res.Success,
                    validationErrorMsg: errMsgs,
                    validationRunning: false
                })
            })
        }).catch((err) => {
            this.setState({
                validated: false,
                validationErrorMsg: err,
                validationRunning: false
            });
        })
    }

    toggleShowAccessKeyButton() {
        this.setState({
            showAccessKey: !this.state.showAccessKey
        })
    }

    copyCredentialsToClipboard() {
        this.setState({copyingRunning: true});
        let {targetRef} = this.props;
        const target = targetRef.data[0];
        let promise;
        if (target.targetType === "S3") {
            promise = decryptValue(target.secretKey).then(decrypted => {
                return `Bucket: ${target.bucket}
AccessKey: ${target.accessKey}
SecretKey: ${decrypted}`;
            })
        } else if (target.targetType === "Azure" && target.serviceUrl) {
            promise = decryptValue(target.serviceUrl).then(decrypted => {
                return `Container: ${target.containerName}
ServiceUrl: ${decrypted}
AccountName: ${target.accountName}`;
            })
        } else if (target.targetType === "Azure" && target.accountName) {
            promise = decryptValue(target.accountKey).then(decrypted => {
                return `Container: ${target.containerName}
AccountName: ${target.accountName}
AccountKey: ${decrypted}`;
            })
        } else if (target.targetType === "SFTP") {
            promise = decryptValue(target.password).then(decrypted => {
                return `Username: ${target.username}
Password: ${decrypted}`;
            })
        }

        promise.then(clipText => {
            navigator.clipboard.writeText(clipText).then(
                () => this.setState({copiedToClipboard: true, copyingRunning: false})
            )
        })
    }


    render() {
        let {targetRef, archive, update} = this.props;
        if (loading(targetRef)) {
            return <Spinner>Loading target information...</Spinner>
        }

        const target = targetRef.data[0];
        return (
            <Panel>
                <h3>{target.name}</h3>
                <NavLink to={`/clients/${target.clientId.id}`}>Back to Client Dashboard</NavLink>

                <div className="targetDetailPage">
                    <span className="targetInfo">
                        {target.targetType === "S3" && this.s3TargetEdit(target)}
                        {target.targetType === "SFTP" && this.sftpTargetInfo(target)}
                        {target.targetType === "Azure" && this.azureTargetEdit(target, update)}
                        {target.targetType === "GCS" && this.gcsTargetEdit(target)}
                        {target.targetType === "Google Drive" && this.gDriveTargetInfo(target)}
                    </span>
                    {target.targetType === "S3" &&
                        <div>
                            <span className="buttons left">
                                {this.state.showAccessKey &&
                                    <button type="button" onClick={this.toggleShowAccessKeyButton.bind(this)}>
                                        Hide Access Key <FontAwesomeIcon icon="chevron-up"/>
                                    </button>}
                                {!this.state.showAccessKey &&
                                    <button type="button" onClick={this.toggleShowAccessKeyButton.bind(this)}>
                                        Show Access Key <FontAwesomeIcon icon="chevron-down"/>
                                    </button>}
                                <button onClick={this.copyCredentialsToClipboard.bind(this)}>
                                    {this.state.copyingRunning && <FontAwesomeIcon icon="spinner" pulse={true}/>}
                                    <FontAwesomeIcon
                                        icon="copy"/> {this.state.copiedToClipboard ? "Copied!" : "Copy info to clipboard"}
                                </button>
                            </span>
                            {this.state.showAccessKey &&
                                <div>
                                    <dl>
                                        <dt>Access Key ID</dt>
                                        <dd>
                                            <input
                                                name={"accessKey"}
                                                disabled={!this.state.isEdit}
                                                onChange={this.handleChangeTarget}
                                                onKeyDown={(e) => this.handleOnBlur(e, target)}
                                                value={this.state.updatedTarget ? this.state.updatedTarget.accessKey : target?.accessKey}
                                                required={true}
                                                size="150"
                                            />
                                        </dd>
                                        <dt>Secret Access Key (encrypted!)</dt>
                                        <dd>
                                            <input
                                                name={"secretKey"}
                                                disabled={!this.state.isEdit}
                                                onChange={this.handleChangeTarget}
                                                onKeyDown={(e) => this.handleOnBlur(e, target, true)}
                                                value={this.state.updatedTarget ? this.state.updatedTarget.secretKey : target?.secretKey}
                                                required={true}
                                                size="150"
                                            />
                                        </dd>
                                    </dl>
                                </div>}
                            {this.state.validated ?
                                <div className="panel"><FontAwesomeIcon icon="hand-point-left"/> This target is
                                    valid</div> :
                                <span className="buttons left">
                                    <button type="button"
                                            onClick={(e) => this.validateBucket(e)}>Validate bucket</button>
                                    <div>
                                        {this.state.validationRunning &&
                                            <div className="panel">
                                                <FontAwesomeIcon icon="spinner" pulse={true}/>
                                                <p>Validating bucket...</p>
                                            </div>}
                                        {this.state.validationErrorMsg && !this.state.validationRunning &&
                                            <div className="panel error"><FontAwesomeIcon icon="thumbs-down"/> <p>This
                                                bucket
                                                can't be used
                                                because: {this.state.validationErrorMsg}</p></div>}

                                    </div>
                                </span>}
                        </div>}
                    {target.targetType === "Azure" &&
                        <div>
                        <span className="buttons left">
                            {this.state.showAccessKey &&
                                <button type="button" onClick={this.toggleShowAccessKeyButton.bind(this)}>
                                    Hide Account Details <FontAwesomeIcon icon="chevron-up"/>
                                </button>}
                            {!this.state.showAccessKey &&
                                <button type="button" onClick={this.toggleShowAccessKeyButton.bind(this)}>
                                    Show Account Details <FontAwesomeIcon icon="chevron-down"/>
                                </button>}
                            <button onClick={this.copyCredentialsToClipboard.bind(this)}>
                                {this.state.copyingRunning && <FontAwesomeIcon icon="spinner" pulse={true}/>}
                                <FontAwesomeIcon
                                    icon="copy"/> {this.state.copiedToClipboard ? "Copied!" : "Copy info to clipboard"}
                            </button>
                        </span>
                            {this.state.showAccessKey &&
                                <div>
                                    <dl>
                                        <dt>Account Name</dt>
                                        <dd>
                                            <input
                                                name={"accountName"}
                                                disabled={!this.state.isEdit}
                                                onChange={this.handleChangeTarget}
                                                onKeyDown={(e) => this.handleOnBlur(e, target)}
                                                value={this.state.updatedTarget ? this.state.updatedTarget.accountName : target?.accountName}
                                                required={true}
                                                size="150"
                                            />
                                        </dd>
                                        <dt>Account Key (encrypted!)</dt>
                                        <dd>
                                            <input
                                                name={"accountKey"}
                                                disabled={!this.state.isEdit}
                                                onChange={this.handleChangeTarget}
                                                onKeyDown={(e) => this.handleOnBlur(e, target, true)}
                                                value={this.state.updatedTarget ? this.state.updatedTarget.accountKey : target?.accountKey}
                                                required={true}
                                                size="150"
                                            />
                                        </dd>
                                    </dl>
                                </div>}
                        </div>}
                </div>
                {target.archived && <div className="archivedDetail">Archived</div>}
                {!target.archived && (
                    <div className="buttons">
                        <button
                            disabled={target.archived}
                            className="danger callToAction"
                            onClick={() => {
                                archive(target._id)
                            }}><FontAwesomeIcon icon="eye-slash"/> Archive
                        </button>
                        <button
                            className="callToAction"
                            disabled={!["Azure", "S3", "GCS"].includes(target.targetType)}
                            onClick={() => {
                                this.setState(prevState => ({
                                    ...this.state,
                                    isEdit: !prevState.isEdit,
                                    updatedTarget: {...target}
                                }))
                            }}><FontAwesomeIcon
                            icon={this.state.isEdit ? "save" : "edit"}/> {this.state.isEdit ? "Save" : "Edit"}
                        </button>
                    </div>
                )}
            </Panel>
        )
    }

    handleChangeTarget = (e) => {
        const t = this.state.updatedTarget;
        t[e.target.name] = e.target.value;
        this.setState({...this.state, updatedTarget: t});
    }

    handleOnBlur = (e, target, encrypt = false) => {
        if (e.target.value !== target[e.target.name]) {
            if (encrypt) {
                //want to encrypt single values here cause encrypting whole object could re encrypt other values
                encryptValue(e.target.value)
                    .then(encryptedValue => {
                        this.props.update(target._id, e.target.name, encryptedValue);
                        const t = this.state.updatedTarget;
                        t[e.target.name] = encryptedValue;
                        this.setState({...this.state, updatedTarget: t})
                    })
            } else
                this.props.update(target._id, e.target.name, e.target.value);
        }
    }

    s3TargetEdit = (target) => (
        <dl>
            <dt>Type</dt>
            <dd>{target.targetType}</dd>
            <dt>Bucket</dt>
            <dd>
                <input
                    name={"bucket"}
                    disabled={!this.state.isEdit}
                    onChange={this.handleChangeTarget}
                    onBlur={(e) => this.handleOnBlur(e, target)}
                    value={this.state.updatedTarget ? this.state.updatedTarget.bucket : target?.bucket}
                    required={true}
                />
            </dd>
            <div>
                <dt>Folder</dt>
                <dd>
                    <input
                        name={"folder"}
                        disabled={!this.state.isEdit}
                        onChange={this.handleChangeTarget}
                        onBlur={(e) => this.handleOnBlur(e, target)}
                        value={this.state.updatedTarget ? this.state.updatedTarget.folder : target?.folder}
                        required={true}
                    />
                </dd>
            </div>
            <dt>Region</dt>
            <dd>
                <input
                    name={"region"}
                    disabled={!this.state.isEdit}
                    onChange={this.handleChangeTarget}
                    onBlur={(e) => this.handleOnBlur(e, target)}
                    value={this.state.updatedTarget ? this.state.updatedTarget.region : target?.region}
                    required={true}
                />
            </dd>
            <dt>Placement</dt>
            <dd>{`s3://${target.bucket}/${target.folder}`}</dd>
        </dl>);

    sftpTargetInfo = (target) => (
        <dl>
            <dt>Type</dt>
            <dd>{target.targetType}</dd>
            <dt>Host</dt>
            <dd>{target.host}</dd>
            {target.folder && target.folder.length >= 1 &&
                <div>
                    <dt>Folder</dt>
                    <dd>{target.folder}</dd>
                </div>
            }
            <dt>Placement</dt>
            <dd>{`sftp://${target.host}${target.port ? ":" + target.port : ""}/${target.folder}`}</dd>
        </dl>);

    azureTargetEdit = (target) => (
        <dl>
            <dt>Type</dt>
            <dd>{target.targetType}</dd>
            <dt>Container</dt>
            <dd>
                <input
                    name={"containerName"}
                    disabled={!this.state.isEdit}
                    onChange={this.handleChangeTarget}
                    onBlur={(e) => this.handleOnBlur(e, target)}
                    value={this.state.updatedTarget ? this.state.updatedTarget.containerName : target?.containerName}
                    required={true}
                />
            </dd>
            <div>
                <dt>Service URL (encrypted!)</dt>
                <dd>
                    <input
                        name={"serviceUrl"}
                        disabled={!this.state.isEdit}
                        onChange={this.handleChangeTarget}
                        onBlur={(e) => this.handleOnBlur(e, target, true)}
                        value={this.state.updatedTarget ? this.state.updatedTarget.serviceUrl : target?.serviceUrl}
                        required={true}
                        size="150"
                    />
                </dd>
            </div>
            <div>
                <dt>Folder</dt>
                <dd>
                    <input
                        name={"folder"}
                        disabled={!this.state.isEdit}
                        onChange={this.handleChangeTarget}
                        onBlur={(e) => this.handleOnBlur(e, target)}
                        value={this.state.updatedTarget ? this.state.updatedTarget.folder : target?.folder}
                        required={true}
                    />
                </dd>
            </div>
        </dl>);

    gcsTargetEdit = (target) => (
        <dl>
            <dt>Type</dt>
            <dd>{target.targetType}</dd>
            <dt>Bucket</dt>
            <dd>
                <input
                    name={"gcsBucket"}
                    disabled={!this.state.isEdit}
                    onChange={this.handleChangeTarget}
                    onBlur={(e) => this.handleOnBlur(e, target)}
                    value={this.state.updatedTarget ? this.state.updatedTarget.gcsBucket : target?.gcsBucket}
                    required={true}
                />
            </dd>
            <div>
                <dt>Folder</dt>
                <dd>
                    <input
                        name={"folder"}
                        disabled={!this.state.isEdit}
                        onChange={this.handleChangeTarget}
                        onBlur={(e) => this.handleOnBlur(e, target)}
                        value={this.state.updatedTarget ? this.state.updatedTarget.folder : target?.folder}
                        required={true}
                    />
                </dd>
            </div>
            <dt>Placement</dt>
            <dd>{`gs://${target.gcsBucket}/${target.folder}`}</dd>
        </dl>);

    gDriveTargetInfo = (target) => (
        <dl>
            <dt>Type</dt>
            <dd>{target.targetType}</dd>
            <dt>Folder id</dt>
            <dd>{target.gdriveFolderId}</dd>
            {target.folder && target.folder.length >= 1 &&
                <div>
                    <dt>Folder</dt>
                    <dd>{target.folder}</dd>
                </div>
            }
            <dt>Placement</dt>
            <dd>{`https://drive.google.com/drive/u/0/folders/${target.gdriveFolderId}/${target.folder}`}</dd>
        </dl>);
}


export default TargetDetailPage;
