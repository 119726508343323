import React from "react";
import "./View.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ViewSummaryCompact from "./ViewSummaryCompact";
import {NavLink} from "react-router-dom";

const ViewPicker = ({views, onPick}) => {
    return (
        <div>
            <h4>Pick view</h4>
            {views.map(view => (
                <ViewSummaryCompact key={view._id} view={view} onClick={() => onPick(view)}>
                    <NavLink to={"/views/"+view._id} target="_blank"><FontAwesomeIcon icon="external-link-alt"/></NavLink>
                </ViewSummaryCompact>
            ))}
            <button onClick={()=>onPick(null)}>Cancel</button>
        </div>);
};

export default ViewPicker;
