import React, {Component} from "react";
import Firestore, {loading} from "../FirestoreContainer";
import Spinner from "../spinner/Spinner";

import "./ActivityTimeLine.css";
import Activity from "./Activity";

class ActivityTimeLineWithFirestoreWrapper extends Component {
    constructor(props) {
        super(props);
        this.state = {numToShow: 5};
    }

    showOlder() {
        this.setState({numToShow: this.state.numToShow + 5})
    }

    showNewer() {
        if (this.state.numToShow > 5) {
            this.setState({numToShow: this.state.numToShow - 5})
        }
    }

    render() {
        return (
            <Firestore
                key={this.state.numToShow}
                queryMapFn={db => {
                    return {
                        activities: db
                            .collection("activity")
                            .orderBy("timestamp", "desc")
                            .limit(this.state.numToShow),
                    };
                }}>
                <ActivityTimeLine
                    showOlder={this.showOlder.bind(this)}
                    showNewer={this.showNewer.bind(this)}/>
            </Firestore>
        );
    }
}

class ActivityTimeLine extends Component {
    render() {
        const activities = this.props.activities;
        return (
            <section className="activityTimeline">
                <h3>Latest activity</h3>
                <p/>
                <div>
                    {loading(activities) && <Spinner/>}
                    <ul className="activityTimeLineEvents">
                        {!loading(activities) && activities.data.slice(-5).map(act => <Activity activity={act}
                                                                                                key={act._id}
                                                                                                showLinks={true}/>)}
                    </ul>
                    <button className="historyButton" onClick={this.props.showOlder}><i
                        className="fa fa-lg fa-history"/> Show older
                    </button>
                    {!loading(activities) && activities.data.length > 5 &&
                    <button className="historyButton" onClick={this.props.showNewer}><i
                        className="fa fa-lg fa-history"/> Show newer
                    </button>
                    }
                </div>
            </section>
        );
    }
}

export default ActivityTimeLineWithFirestoreWrapper;
